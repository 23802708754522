import { Button, Stack, Typography } from '@mui/material';
import { PlusIcon } from 'src/mui/_icons';
import { createFilterOptions } from '@mui/base';
import type { FormFieldProps } from 'src/mui/_scss';
import { BankIcon, FormField, Select } from 'src/mui/_scss';

import { generateCompanyPath } from 'src/router/routes';
import { RouteKey } from 'src/router/types';

import { useAccounts } from 'src/libs/finbits/Bank/Accounts';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import type { Account } from 'src/libs/finbits/Bank/Accounts/types';

import styles from './AccountsField.module.scss';

export const accountFilterOptions = createFilterOptions({
  ignoreCase: true,
  ignoreAccents: true,
  matchFrom: 'any',
  stringify: (account: Account) => `${account.name}${account.accountNumber}`,
});

export default function AccountsField({
  defaultValue = '',
  ...rest
}: FormFieldProps) {
  const { companyId, organizationId } = useCompanyParams();

  const { accounts, isLoading } = useAccounts(
    {
      companyId,
      organizationId,
    },
    {
      staleTime: FIVE_MINUTES_IN_MS,
    }
  );

  return (
    <FormField defaultValue={defaultValue} label="Conta" {...rest}>
      {(field) => {
        const account =
          accounts.find((account) => account.id === field.value) ?? null;

        return (
          <Select
            {...field}
            placeholder="Digite a conta"
            value={account}
            options={accounts}
            loading={isLoading}
            filterOptions={accountFilterOptions}
            getOptionLabel={(option) => option.name || ''}
            onChange={(_e, value) => field.onChange(value?.id)}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderOption={(account) => (
              <Stack gap={3} direction="row">
                <BankIcon
                  width={24}
                  height={24}
                  routingNumber={account.routingNumber}
                />
                <Stack>
                  <Typography>{account.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {account.accountNumber}
                  </Typography>
                </Stack>
              </Stack>
            )}
          >
            <WithAllowedPermission
              permissions={{ action: 'create', resource: 'accounts' }}
            >
              <Button
                href={generateCompanyPath(RouteKey.ACCOUNTS, {
                  companyId,
                  organizationId,
                  action: 'createAccount',
                })}
                target="_blank"
                startIcon={<PlusIcon />}
                className={styles.createAccountButton}
              >
                Criar conta
              </Button>
            </WithAllowedPermission>
          </Select>
        );
      }}
    </FormField>
  );
}
