import { Chip } from '@mui/material';

import styles from './AutomatichLabel.module.scss';

export default function AutomatichLabel() {
  return (
    <>
      Automático
      <Chip
        variant="filled"
        color="success"
        label="Recomendado"
        size="small"
        className={styles.chip}
      />
    </>
  );
}
