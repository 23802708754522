import type { GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium';
import { singleSelectColumn } from 'src/mui/_scss';

import type { ColumnProps } from 'src/features/entries/EntriesDataGrid/columns/types';

import RequesterSelect from './RequesterSelect/RequesterSelect';

export function requesterColumn<T extends GridValidRowModel>(
  columnProps?: ColumnProps<T>
): GridColDef<T> {
  return singleSelectColumn({
    columnProps: {
      aggregable: false,
      editable: false,
      headerName: 'Solicitado por',
      field: 'requester',
      valueGetter: (params) => params.row.lastApproval?.requester,
      valueFormatter: (params) => params.value?.name,
      groupingValueGetter: (params) => {
        return params.row.lastApproval?.requester?.name ?? 'Sem solicitante';
      },
      sortComparator: (requesterA, requesterB) => {
        const nameA = requesterA?.name ?? '';
        const nameB = requesterB?.name ?? '';

        return nameA.localeCompare(nameB);
      },
      minWidth: 100,
      ...columnProps,
    },
    SelectComponent: RequesterSelect,
  });
}
