import { Alert, AlertTitle, Stack, Typography } from '@mui/material';

import { format } from 'src/libs/finbits/Date';
import type { NewInboxItem } from 'src/libs/finbits/Management/NewInboxItems/types';

import { alert, alertTitle } from './RejectionReasonAlert.sx';

type Props = {
  inboxItem: NewInboxItem;
};

export default function RejectionReasonAlert({ inboxItem }: Props) {
  const isRejected = inboxItem?.status === 'rejected';

  if (!isRejected) return null;

  return (
    <Alert sx={alert} icon={false}>
      <AlertTitle sx={alertTitle}>
        Rejeitado por {inboxItem.assignee?.name}
      </AlertTitle>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Typography sx={{ wordBreak: 'break-word', flex: 1 }}>
          {inboxItem.rejectionReason
            ? `Motivo: ${inboxItem.rejectionReason}`
            : 'Nenhum motivo de rejeição'}
        </Typography>

        {inboxItem.rejectedAt && (
          <Typography component="time" sx={{ ml: 2 }}>
            {format(inboxItem.rejectedAt, "dd/MM/yy 'às' HH:mm")}
          </Typography>
        )}
      </Stack>
    </Alert>
  );
}
