import { closeSnackbar } from 'notistack';

import { Typography, snackbar } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import { canPerformAction } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import type { BillPayable } from 'src/libs/finbits/Bills/types';
import {
  useCreateApproved,
  useUndoApproved,
} from 'src/libs/finbits/PaymentsFlow';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { pluralize } from 'src/libs/finbits/Pluralize';
import { AvailableActions } from 'src/libs/finbits/Management/Entries/types';

import ReviewModal from 'src/features/bills-to-pay/ReviewModal';

type Props = {
  selecteds: BillPayable[];
  onSuccess?: () => void;
};

const SNACKBAR_KEY = 'undo';

export default function useApprovedAction({ selecteds, onSuccess }: Props) {
  const openPortal = useOpenPortal();

  const { billsAvailable, billsNotAvailable } = selecteds.reduce<{
    billsAvailable: BillPayable[];
    billsNotAvailable: BillPayable[];
  }>(
    (acc, bill) => {
      if (canPerformAction(bill, AvailableActions.SEND_TO_APPROVED)) {
        acc.billsAvailable.push(bill);
      } else {
        acc.billsNotAvailable.push(bill);
      }

      return acc;
    },
    { billsAvailable: [], billsNotAvailable: [] }
  );

  const { companyId, organizationId } = useCompanyParams();
  const { createApproved, isLoading: isApproving } = useCreateApproved();
  const { undoApproved } = useUndoApproved();

  function handleApprovedSuccess(selectedIds: string[]) {
    snackbar({
      variant: 'success',
      key: SNACKBAR_KEY,
      message: pluralize(
        selectedIds.length,
        'O lançamento foi aprovado',
        'Os lançamentos foram aprovados'
      ),
    });

    onSuccess && onSuccess();
  }

  function sendToApproved(newSelectedIds?: string[]) {
    const selectedIds = newSelectedIds ?? billsAvailable.map((bill) => bill.id);

    if (selectedIds.length === 0) {
      handleApprovedSuccess(selectedIds);
      return;
    }

    createApproved(
      {
        organizationId,
        companyId,
        billsIds: selectedIds,
      },
      {
        onSuccess: () => {
          handleApprovedSuccess(selectedIds);
        },
        onError: (e) => {
          snackbar({ variant: 'error', message: e.response?.data.message });
        },
      }
    );
  }

  function handleUndo(newSelectedIds?: string[]) {
    closeSnackbar(SNACKBAR_KEY);
    const selectedIds = newSelectedIds ?? selecteds.map((bill) => bill.id);

    undoApproved(
      {
        organizationId,
        companyId,
        billsIds: selectedIds,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: pluralize(
              selectedIds.length,
              'Reenviado para Em Aprovação',
              'Reenviados para Em Aprovação'
            ),
          });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Erro ao reenviar para Em Aprovação',
          });
        },
      }
    );
  }

  function openReviewModal() {
    openPortal(ReviewModal, {
      title: 'Aprovar lançamentos',
      modalDescription: (
        <Typography variant="body1">
          Retiramos da sua seleção itens que não podem ser aprovados por você.
          <br />
          Confira abaixo os lançamentos que você pode aprovar em lote e confirme
          a sua aprovação:
        </Typography>
      ),
      tabs: {
        availables: {
          title: 'Podem ser aprovados por você',
          bills: billsAvailable,
        },
        notAvailables: {
          title: 'Permanecem em aprovação',
          description:
            'Você não consta como aprovador dos itens abaixo e por isso eles não podem ser aprovados.',
          bills: billsNotAvailable,
        },
      },
      onConfirm: {
        action: sendToApproved,
        confirmText: 'Aprovar selecionados',
      },
      modalClose: {
        text: 'Confirmar cancelamento da aprovação?',
        subtext: 'Ao cancelar, os itens selecionados não serão aprovados.',
      },
    });
  }

  return {
    sendToApproved: billsNotAvailable.length ? openReviewModal : sendToApproved,
    isApproving,
    undosApproved: handleUndo,
  };
}
