import type {
  GridApi,
  GridColDef,
  GridColType,
  GridRowModel,
  GridValidRowModel,
} from '@mui/x-data-grid-premium';
import { EqualIcon, FilterLinesIcon, NotEqualIcon } from 'src/mui/_icons';
import { Divider, MenuList } from '@mui/material';

import type { PortalProps } from 'src/ui';

import { format } from 'src/libs/finbits/Date';

import { ContextItem } from '../ContextItem';

type MousePosition = { top: number; left: number };

export type ContextMenuInfo<T extends GridValidRowModel> = {
  colDef: GridColDef;
  row: GridRowModel<T>;
  field: string;
  mousePosition: MousePosition;
};

type ContextMenuProps<T extends GridValidRowModel> = {
  info: ContextMenuInfo<T>;
  api: GridApi;
  onClose: PortalProps['onClose'];
};

const EQUAL_OPERATOR_BY_COLUMN_TYPE: Record<GridColType, string> = {
  string: 'equals',
  enum: 'is',
  singleSelect: 'is',
  date: 'is',
  dateTime: 'is',
  boolean: 'is',
  number: '=',
  actions: '',
};

const NOT_EQUAL_OPERATOR_BY_COLUMN_TYPE: Record<GridColType, string> = {
  string: 'notEquals',
  enum: 'not',
  singleSelect: 'not',
  date: 'not',
  dateTime: 'not',
  boolean: 'is',
  number: '!=',
  actions: '',
};

function parseValue(
  cellValue: any,
  formatedCellValue: any,
  colType?: GridColType
) {
  if (colType === 'string') {
    return formatedCellValue;
  }

  if (colType === 'date' || colType === 'dateTime') {
    return format(cellValue, 'yyyy-MM-dd');
  }

  return cellValue;
}

function buildFilter<T extends GridValidRowModel>(
  info: ContextMenuInfo<T>,
  api: GridApi,
  operatorMap: Record<GridColType, string>
) {
  const cellValue = api.getCellValue(info.row.id, info.field);
  const formatedCellValue = api.getRowFormattedValue(info.row, info.colDef);

  const value = parseValue(cellValue, formatedCellValue, info.colDef.type);

  return [
    {
      field: info.field,
      value,
      operator: operatorMap[info.colDef.type!],
    },
  ];
}

export function FilterCellItems<T extends GridValidRowModel>({
  api,
  info,
  onClose,
}: ContextMenuProps<T>) {
  const formatedCellValue = api.getRowFormattedValue(info.row, info.colDef);

  function filterByCellNotEqualValue() {
    api.upsertFilterItems(
      buildFilter(info, api, NOT_EQUAL_OPERATOR_BY_COLUMN_TYPE)
    );
  }

  function filterByCellEqualValue() {
    api.upsertFilterItems(
      buildFilter(info, api, EQUAL_OPERATOR_BY_COLUMN_TYPE)
    );
  }

  function openFilterPainel() {
    api.showFilterPanel(info.field);
  }

  if (!info.colDef.filterable || formatedCellValue === null) {
    return null;
  }

  return (
    <>
      <MenuList>
        <ContextItem
          onClose={onClose}
          label={`Filtrar ${info.colDef.headerName}`}
          icon={<FilterLinesIcon />}
          onClick={openFilterPainel}
        />
        <ContextItem
          onClose={onClose}
          label={`Filtrar ${info.colDef.headerName} igual a ${formatedCellValue}`}
          icon={<EqualIcon />}
          onClick={filterByCellEqualValue}
        />
        <ContextItem
          onClose={onClose}
          label={`Filtrar ${info.colDef.headerName} diferente de ${formatedCellValue}`}
          icon={<NotEqualIcon />}
          onClick={filterByCellNotEqualValue}
        />
      </MenuList>
      <Divider variant="fullWidth" />
    </>
  );
}
