import { Alert, Box, Stack } from '@mui/material';
import classNames from 'classnames';
import { InfoCircleIcon } from 'src/mui/_icons';

import { Typography } from 'src/mui';

import NotFoundPage from 'src/pages/NotFoundPage';

import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import InsightForm from 'src/features/finbot/InsightForm/InsightForm';

import styles from './FinBot.module.scss';

type Props = {
  isPage?: boolean;
};

export default function FinBot({ isPage = true }: Props) {
  const { isEnabled: isFinbotEnabled } = useExternalFeatureFlag(Feature.FINBOT);

  if (!isFinbotEnabled) {
    return <NotFoundPage />;
  }

  const containerClass = classNames(styles.container, {
    [styles.page]: isPage,
  });

  return (
    <Box className={containerClass}>
      <Stack flexDirection="row" gap={4} alignItems="baseline">
        <Typography variant="subtitle1" fontWeight="bold">
          FinBot <sup className={styles.beta}>Beta</sup>
        </Typography>
        <Typography color="grey.500" fontStyle="italic">
          Os dados utilizados na análise são referentes ao periodo e filtros
          aplicados na tela de Lançamentos
        </Typography>
      </Stack>
      <Alert
        icon={<InfoCircleIcon className={styles.alertIcon} />}
        severity="info"
        className={styles.alert}
        variant="outlined"
      >
        <Typography variant="body1" color="info.900">
          Lembre-se: Confira os dados reportados pela FinBot. Ela é um robô em
          treinamento!
        </Typography>
      </Alert>
      <InsightForm />
    </Box>
  );
}
