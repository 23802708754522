import type { SxProps, Theme } from '@mui/material';

import type { FocusIconColor, FocusIconSize } from './FocusIcon';

export const baseFocusIcon: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  borderRadius: '50%',
  border: '8px solid transparent',
};

export const colors: Record<FocusIconColor, SxProps<Theme>> = {
  primary: {
    background: (theme) =>
      theme.palette.mode === 'light'
        ? theme.palette.grey[100]
        : theme.palette.grey[600],
    borderColor: (theme) =>
      theme.palette.mode === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[700],
    color: (theme) => theme.palette.text.primary,
  },
  error: {
    background: (theme) =>
      theme.palette.mode === 'light'
        ? theme.palette.error[100]
        : theme.palette.error[400],
    borderColor: (theme) =>
      theme.palette.mode === 'light'
        ? theme.palette.error[50]
        : theme.palette.error[500],
    color: (theme) =>
      theme.palette.mode === 'light'
        ? theme.palette.error.main
        : theme.palette.common.white,
  },
  success: {
    background: (theme) =>
      theme.palette.mode === 'light'
        ? theme.palette.success[100]
        : theme.palette.success[400],
    borderColor: (theme) =>
      theme.palette.mode === 'light'
        ? theme.palette.success[50]
        : theme.palette.success[500],
    color: (theme) =>
      theme.palette.mode === 'light'
        ? theme.palette.success.main
        : theme.palette.common.white,
  },
  warning: {
    background: (theme) =>
      theme.palette.mode === 'light'
        ? theme.palette.warning[100]
        : theme.palette.warning[400],
    borderColor: (theme) =>
      theme.palette.mode === 'light'
        ? theme.palette.warning[50]
        : theme.palette.warning[500],
    color: (theme) =>
      theme.palette.mode === 'light'
        ? theme.palette.warning.main
        : theme.palette.common.white,
  },
};

export const sizes: Record<FocusIconSize, SxProps<Theme>> = {
  xs: {
    width: '24px',
    height: '24px',
  },
  sm: {
    width: '32px',
    height: '32px',
  },
  md: {
    width: '40px',
    height: '40px',
  },
  lg: {
    width: '48px',
    height: '48px',
  },
  xl: {
    width: '56px',
    height: '56px',
  },
};
