import type {
  GridColDef,
  GridValidRowModel,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import { amountColumn as defaultAmountColumn } from 'src/mui/_scss';

import { convertToSignedNumber } from 'src/libs/finbits/Money';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import type { ColumnProps } from 'src/features/entries/EntriesDataGrid/columns/types';

import styles from './amountColumn.module.scss';

function valueGetter<T extends GridValidRowModel>({
  value,
  api,
  id,
}: GridValueGetterParams<T>) {
  const row = api.getRow(id);

  if (row.amountType === BalanceType.DEBIT && value < 0) {
    return value;
  }

  return convertToSignedNumber(value, row.amountType);
}

export function amountColumn<T extends GridValidRowModel>(
  columnProps?: ColumnProps<T>
): GridColDef<T> {
  return defaultAmountColumn({
    columnProps: {
      headerName: 'Valor',
      field: 'amount',
      align: 'right',
      valueGetter: valueGetter,
      minWidth: 110,
      aggregable: true,
      cellClassName: (params) => {
        if (params.row.amountType === BalanceType.CREDIT) {
          return styles.creditCell;
        }
        return '';
      },
      editable: true,
      ...columnProps,
    },
  });
}
