import type { ButtonProps } from '@mui/material';
import { ThumbsUpIcon } from 'src/mui/_icons';
import { LoadingButton } from '@mui/lab';

import { Tooltip, Typography } from 'src/mui';

import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import { canPerformAction } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { AvailableActions } from 'src/libs/finbits/Management/Entries/types';

import useApprovedAction from 'src/features/bills-to-pay/PaymentsFlow/useApprovedAction';

type Props = {
  selectedBills: BillPayable[];
  onSuccess?: () => void;
} & ButtonProps;

export default function SendToApprovedButton({
  selectedBills,
  onSuccess,
  children,
}: Props) {
  const { sendToApproved, isApproving } = useApprovedAction({
    selecteds: selectedBills,
    onSuccess,
  });

  const isApprovalAvailable = selectedBills.some((bill) =>
    canPerformAction(bill, AvailableActions.SEND_TO_APPROVED)
  );

  return (
    <WithAllowedPermission
      permissions={{ action: 'create', resource: 'approvedPayments' }}
    >
      <Tooltip
        title={
          <Typography>
            Você não consta como aprovador dos lançamentos selecionados.
          </Typography>
        }
        placement="bottom"
        arrow
        disableHoverListener={isApprovalAvailable}
      >
        <LoadingButton
          variant="text"
          loading={isApproving}
          startIcon={<ThumbsUpIcon />}
          disabled={!isApprovalAvailable}
          onClick={() => sendToApproved()}
        >
          {children}
        </LoadingButton>
      </Tooltip>
    </WithAllowedPermission>
  );
}
