import type { QueryClient } from 'react-query';
import { useQuery } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import {
  authenticatedAPI,
  decodeResponse,
  withEmptyArrayDefault,
} from 'src/libs/finbits/client';

import type {
  AccountBalance,
  CompanyBalance,
  GetAccountsBalancesParams,
  GetCompaniesBalancesParams,
} from './types';
import { AccountBalanceDecoder, CompanyBalanceDecoder } from './types';

export function invalidateCompaniesBalancesQueries(queryClient: QueryClient) {
  queryClient.invalidateQueries(['companiesBalances']);
}

async function getCompaniesBalances({
  organizationId,
  ...params
}: GetCompaniesBalancesParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies_balances`,
    {
      params,
    }
  );

  return decodeResponse<CompanyBalance[]>(
    response,
    CompanyBalanceDecoder.array()
  );
}

async function getAccountsBalances({
  organizationId,
  companyId,
}: GetAccountsBalancesParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/accounts_balances`
  );

  return decodeResponse<AccountBalance[]>(
    response,
    AccountBalanceDecoder.array()
  );
}

export function useCompaniesBalances(params: GetCompaniesBalancesParams) {
  const { data: companiesBalances, ...rest } = useQuery<
    CompanyBalance[],
    ApiError
  >({
    enabled: !!params.organizationId,
    queryKey: ['companiesBalances', params],
    queryFn: () => getCompaniesBalances(params),
  });

  return {
    companiesBalances: withEmptyArrayDefault(companiesBalances),
    ...rest,
  };
}

export function useAccountsBalances(params: GetAccountsBalancesParams) {
  const { data: accountsBalances, ...rest } = useQuery<
    AccountBalance[],
    ApiError
  >({
    enabled: !!params.organizationId && !!params.companyId,
    queryKey: ['accountsBalances', params],
    queryFn: () => getAccountsBalances(params),
  });

  return {
    accountsBalances: withEmptyArrayDefault(accountsBalances),
    ...rest,
  };
}
