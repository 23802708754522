import type { SxProps, Theme } from '@mui/material';

export const iconButton: SxProps<Theme> = {
  position: 'absolute',
  right: 16,
  top: 16,
  color: 'grey.500',
};

export const dialog: SxProps<Theme> = {
  backgroundImage: 'none',
  backgroundColor: (theme) =>
    theme.palette.mode === 'light'
      ? theme.palette.common.white
      : theme.palette.grey[800],
};

export const dialogTitle: SxProps<Theme> = {
  p: 0,
  pt: 1,
  color: (theme) =>
    theme.palette.mode === 'light'
      ? theme.palette.grey[900]
      : theme.palette.common.white,
};

export const chip: SxProps<Theme> = {
  px: 0,
  height: '24px',
  color: 'grey.700',
  backgroundColor: 'grey.100',
};

export const cancel: SxProps<Theme> = {
  px: 4,
  backgroundColor: 'common.white',
  color: 'grey.700',
  '&:hover': {
    backgroundColor: (theme) =>
      theme.palette.mode === 'light'
        ? theme.palette.grey[100]
        : theme.palette.grey[300],
  },
};
