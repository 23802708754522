import { Switch, Typography } from 'antd';

import { useTheme } from './ThemeProvider';
import styles from './ThemeSwitcher.module.less';

export default function ThemeSwitcher() {
  const [theme, setTheme] = useTheme();

  function toggleTheme() {
    if (theme === 'dark') {
      setTheme('light');
    } else {
      setTheme('dark');
    }
  }

  return (
    <div className={styles.container}>
      <Typography.Text>Tema escuro</Typography.Text>

      <Switch checked={theme === 'dark'} onChange={toggleTheme} />
    </div>
  );
}
