import { UndoOutlined } from '@ant-design/icons';

import { snackbar } from 'src/mui';

import { DrawerHeaderButton } from 'src/ui';

import { AvailableActions } from 'src/libs/finbits/Management/Entries/types';
import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { canPerformAction } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import { useRestoreEntry } from 'src/libs/finbits/Management/Entries/Restore';

import { useOpenConfirmRestore } from 'src/features/entries/Dialogs';

type Props = {
  bill?: BillPayable;
  onSuccess: () => void;
};

export default function RestoreHeaderButton({ bill, onSuccess }: Props) {
  const { companyId, organizationId } = useCompanyParams();

  const { restoreEntry } = useRestoreEntry();

  function restoreBill() {
    if (!bill) return;

    restoreEntry(
      { organizationId, companyId, billsIds: [bill.id] },
      {
        onSuccess: () => {
          onSuccess();

          snackbar({
            variant: 'success',
            message: 'O lançamento foi restaurado com sucesso',
          });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Falha ao restaurar os lançamentos',
          });
        },
      }
    );
  }

  const confirmRestoreEntry = useOpenConfirmRestore();

  function handleRestore() {
    confirmRestoreEntry({
      onConfirm: restoreBill,
    });
  }

  const canRestore =
    useAllowedPermission({ action: 'restore_many', resource: 'entries' }) &&
    canPerformAction(bill!, AvailableActions.RESTORE);

  if (!canRestore) {
    return null;
  }

  return (
    <DrawerHeaderButton
      icon={<UndoOutlined />}
      title="Restaurar"
      onClick={handleRestore}
      aria-label="restore"
    />
  );
}
