import { useState } from 'react';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormField, Input } from 'src/mui/_scss';
import { InfoCircleIcon } from 'src/mui/_icons';

import { Tooltip } from 'src/mui';

import { useGetInsight } from 'src/libs/finbits/Ai';
import { format } from 'src/libs/finbits/Date';
import { InsightResolver } from 'src/libs/finbits/Ai/validations';
import type { InsightFormParams } from 'src/libs/finbits/Ai/type';

import useEntriesParams from 'src/features/entries/useEntriesParams';

import EmptyState from './texts/EmptyState';
import UserText from './texts/UserText';
import BotText from './texts/BotText';
import styles from './InsightForm.module.scss';

export default function InsightForm() {
  const { control, handleSubmit, reset } = useForm<InsightFormParams>({
    defaultValues: {
      userPrompt: '',
    },
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
    resolver: zodResolver(InsightResolver),
  });

  const [userPrompt, setUserPrompt] = useState('');

  const { organizationId, companyId, selectedAccountIds, selectedDateRange } =
    useEntriesParams();

  const { data, isFetching, isError } = useGetInsight({
    organizationId,
    companyId,
    accountsIds: selectedAccountIds,
    startDate: format(selectedDateRange[0], 'yyyy-MM-dd'),
    endDate: format(selectedDateRange[1], 'yyyy-MM-dd'),
    userPrompt,
  });

  function onFinish(values: InsightFormParams) {
    setUserPrompt(values.userPrompt);
    reset();
  }

  const isEmpty = !Boolean(userPrompt) && !Boolean(data?.insight);

  return (
    <>
      <Stack
        justifyContent={isEmpty ? 'center' : 'start'}
        className={styles.texts}
      >
        <UserText userPrompt={userPrompt} />

        <BotText
          insight={data?.insight}
          isFetching={isFetching}
          isError={isError}
        />

        {isEmpty && <EmptyState />}
      </Stack>
      <Box
        component="form"
        onSubmit={handleSubmit(onFinish)}
        className={styles.form}
      >
        <Stack alignItems="center" direction="row" gap={2}>
          <div className={styles.input}>
            <FormField name="userPrompt" control={control as any}>
              {(field) => {
                return (
                  <Input
                    {...field}
                    type="text"
                    placeholder="Digite aqui..."
                    autoComplete="off"
                  />
                );
              }}
            </FormField>
          </div>

          <LoadingButton
            size="medium"
            type="submit"
            variant="outlined"
            color="secondary"
            loading={isFetching}
          >
            Enviar
          </LoadingButton>
          <Tooltip title="O histórico da pergunta e resposta não são salvos e portanto não poderão ser consultados em histórico">
            <InfoCircleIcon color="action" />
          </Tooltip>
        </Stack>
      </Box>
    </>
  );
}
