import type { MouseEvent } from 'react';
import { useMemo, useState } from 'react';

import type { ListItemProps } from '@mui/material';
import { Chip, ListItem, ListItemButton, Stack } from '@mui/material';
import compact from 'lodash/compact';
import { AttachmentIcon } from 'src/mui/_icons';

import { Typography } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import type { NewInboxItem } from 'src/libs/finbits/Management/NewInboxItems/types';
import { format } from 'src/libs/finbits/Date';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import { inboxItemSourceDictionary } from 'src/libs/finbits/Management/NewInboxItems/translations';

import InboxItemCheckbox from 'src/features/inbox-items/InboxItemsCheckbox/InboxItemCheckbox';
import { useInboxItemsParams } from 'src/features/inbox-items/useInboxItemsParams';
import InboxItemMenu from 'src/features/inbox-items/InboxItemMenu';

import QuickActions from './QuickActions';
import {
  active,
  baseListItemButton,
  secondaryAction,
} from './InboxItemCard.sx';

type Props = {
  isActive?: boolean;
  activeIndex?: number;
  inboxItem: NewInboxItem;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
} & Omit<ListItemProps, 'onClick'>;

export default function InboxItemCard({
  inboxItem,
  activeIndex,
  isActive,
  onClick,
  ...rest
}: Props) {
  const openPortal = useOpenPortal();
  const { inboxItemId, status, navigateToInboxItem } = useInboxItemsParams();

  const isAllowedRejectManyInboxItems = useAllowedPermission({
    action: 'reject_many',
    resource: 'inboxItems',
  });

  const isCheckboxEnabled =
    isAllowedRejectManyInboxItems && status === 'pending';

  const [isActionsVisible, setIsActionsVisible] = useState(false);

  const isSelected = useMemo(() => {
    return inboxItem.id === inboxItemId && isActive;
  }, [inboxItem, isActive, inboxItemId]);

  const formattedInsertedAt = format(
    inboxItem.insertedAt,
    "dd/MM/yyyy 'às' HH:mm"
  );

  const sentBy =
    inboxItem.fromDetail === ''
      ? inboxItem.fromName
      : `${inboxItem.fromName} (${inboxItem.fromDetail})`;

  function handleClick(event: MouseEvent<HTMLDivElement>) {
    navigateToInboxItem(inboxItem.id);

    onClick && onClick(event);
  }

  function handleFocus() {
    setIsActionsVisible(true);
  }

  function handleBlur() {
    setIsActionsVisible(false);
  }

  function handleContextMenu(event: MouseEvent) {
    event.preventDefault();

    openPortal(InboxItemMenu, {
      inboxItem,
      anchorReference: 'anchorPosition',
      anchorPosition: { top: event.clientY - 4, left: event.clientX - 2 },
    });
  }

  return (
    <ListItem
      disablePadding
      aria-label="inbox-item"
      onMouseEnter={handleFocus}
      onMouseLeave={handleBlur}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onContextMenu={handleContextMenu}
      sx={secondaryAction}
      secondaryAction={
        isCheckboxEnabled && <InboxItemCheckbox inboxItemId={inboxItem.id} />
      }
      {...rest}
    >
      <ListItemButton
        divider
        selected={isSelected}
        onClick={handleClick}
        sx={{
          ...baseListItemButton,
          ...(isActive ? active : {}),
        }}
        aria-label="Abrir item"
      >
        <Stack direction="row" width="100%">
          <Stack
            sx={
              isCheckboxEnabled
                ? { ml: 7, width: 'calc(100% - 28px)' }
                : { width: '100%' }
            }
            gap={4}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="column" width="100%">
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography ellipsis fontWeight={600}>
                    {inboxItem.title}
                  </Typography>

                  <QuickActions
                    visible={isActionsVisible}
                    inboxItem={inboxItem}
                  />
                </Stack>
                <Typography
                  ellipsis
                  forceTooltip
                  tooltip={{ title: sentBy, placement: 'bottom-start' }}
                  color="text.secondary"
                >
                  {compact([inboxItem.fromName, formattedInsertedAt]).join(
                    ' - '
                  )}
                </Typography>
              </Stack>
            </Stack>
            <Typography ellipsis>{inboxItem.description}</Typography>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" alignItems="center" gap={1}>
                <AttachmentIcon sx={{ fontSize: '16px' }} />
                <Typography variant="body1" fontWeight={500}>
                  {inboxItem.filesCount}
                </Typography>
              </Stack>
              <Chip
                size="small"
                label={inboxItemSourceDictionary[inboxItem.source]}
              />
            </Stack>
          </Stack>
        </Stack>
      </ListItemButton>
    </ListItem>
  );
}
