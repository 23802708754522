import { Card, Chip, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { KeyIcon, TrashIcon } from 'src/mui/_icons';

import { FocusIcon, Typography, snackbar } from 'src/mui';

import { useOpenConfirmDialog } from 'src/ui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useDeletePublicApiToken } from 'src/libs/finbits/PublicApiTokens/PublicApiTokens';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import { format } from 'src/libs/finbits/Date';
import type { PublicApiToken } from 'src/libs/finbits/PublicApiTokens/types';

import styles from './TokenItem.module.scss';

type Props = {
  token: PublicApiToken;
  showDeleteButton?: boolean;
};

export default function TokenItem({ token, showDeleteButton = true }: Props) {
  const { deletePublicApiToken, isLoading: isDeletionLoading } =
    useDeletePublicApiToken();

  const { organizationId, companyId } = useCompanyParams();

  const openConfirmDialog = useOpenConfirmDialog();

  function onSubmitTokenDeletion() {
    deletePublicApiToken(
      { organizationId, companyId, id: token.id },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'Token excluído com sucesso',
          });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao excluir token!',
          });
        },
      }
    );
  }

  function handleDeleteToken() {
    openConfirmDialog({
      variant: 'error',
      icon: <TrashIcon />,
      title: 'Tem certeza de que deseja excluir?',
      content: (
        <>
          <Typography color="error" fontWeight="bold">
            Muito cuidado! Esta ação não pode ser desfeita.
          </Typography>
          <Typography color="text.secondary">
            Após a exclusão, aplicações que estiverem utilizando nossa API por
            meio deste token terão sua conexão interrompida e deixarão de
            funcionar.
          </Typography>

          <Stack marginTop={4}>
            <TokenItem token={token} showDeleteButton={false} />
          </Stack>
        </>
      ),
      confirmText: 'Excluir',
      confirmButtonProps: {
        variant: 'contained',
        color: 'error',
      },
      checkConfirm: {
        text: 'Estou ciente dos riscos e quero excluir este token.',
      },
      cancelText: 'Voltar',
      onConfirm: onSubmitTokenDeletion,
    });
  }

  return (
    <Card variant="outlined" className={styles.card}>
      <Stack direction="row" gap={4}>
        <FocusIcon size="lg" className={styles.icon}>
          <KeyIcon />
        </FocusIcon>
        <Stack>
          <Typography variant="subtitle2" ellipsis>
            {token.description}
          </Typography>
          <Chip
            size="small"
            label={'...' + token.token}
            className={styles.token}
          />
        </Stack>
      </Stack>

      <Stack direction="row" justifyContent="end" gap={4} alignItems="center">
        <Typography variant="body2" color="grey.500">
          Criado em {format(token.insertedAt, 'dd/MM/yyyy')}
        </Typography>
        {showDeleteButton && (
          <WithAllowedPermission
            permissions={{ resource: 'publicApiTokens', action: 'delete' }}
          >
            <LoadingButton
              variant="outlined"
              color="error"
              aria-label="Excluir token"
              onClick={handleDeleteToken}
              className={styles.deleteIcon}
              loading={isDeletionLoading}
            >
              <TrashIcon />
            </LoadingButton>
          </WithAllowedPermission>
        )}
      </Stack>
    </Card>
  );
}
