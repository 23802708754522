import { forwardRef, useRef, useState } from 'react';
import type { ElementType } from 'react';

import type { SxProps, Theme } from '@mui/material';
import { Typography as MUITypography } from '@mui/material';
import type { TypographyProps } from '@mui/material/Typography';
import type { TooltipProps } from '@mui/material/Tooltip';
import Tooltip from '@mui/material/Tooltip';

import * as styles from './Typography.sx';
import type { PartialBy } from './types';

type Props = {
  ellipsis?: boolean;
  forceTooltip?: boolean;
  tooltip?: PartialBy<TooltipProps, 'title' | 'children'>;
  sx?: SxProps<Theme>;
  component?: ElementType;
} & TypographyProps;

const Typography = forwardRef<HTMLDivElement, Props>(function Typography(
  {
    ellipsis = false,
    forceTooltip = false,
    tooltip,
    children,
    sx,
    ...rest
  }: Props,
  innerRef
) {
  const ref = useRef<HTMLElement>(null);
  const [isEllipsis, setIsEllipsis] = useState(false);

  function handleMouseEnter() {
    if (ref.current) {
      const isOverflowing = ref.current.scrollWidth > ref.current.clientWidth;
      setIsEllipsis(isOverflowing || forceTooltip);
    }
  }

  function handleMouseLeave() {
    setIsEllipsis(false);
  }

  if (!ellipsis) {
    return (
      <MUITypography {...rest} sx={sx} ref={innerRef}>
        {children}
      </MUITypography>
    );
  }

  return (
    <Tooltip
      title={children}
      open={isEllipsis}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      followCursor
      {...tooltip}
    >
      <MUITypography
        ref={ref}
        sx={(styles.ellipsis, { ...sx })}
        noWrap
        {...rest}
      >
        {children}
      </MUITypography>
    </Tooltip>
  );
});

export default Typography;
