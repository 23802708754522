import { Box, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export default function LoginLink() {
  return (
    <Box sx={{ mt: 8 }}>
      <Typography variant="body1">
        Já tem uma conta?{' '}
        <Link component={RouterLink} to="/login">
          Entre aqui
        </Link>
      </Typography>
    </Box>
  );
}
