import type {
  GridCellParams,
  GridColDef,
  GridGroupingValueGetterParams,
  GridValidRowModel,
  GridValueFormatterParams,
  GridValueGetterParams,
  GridValueSetterParams,
} from '@mui/x-data-grid-premium';

import { convertToSignedNumber, toDecimal } from 'src/libs/finbits/Money';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import {
  emptyFilter,
  equalFilter,
  greaterThanFilter,
  greaterThanOrEqualFilter,
  lessThanFilter,
  lessThanOrEqualFilter,
  notEmptyFilter,
  notEqualFilter,
} from './filters';
import AmountInput from './AmountInput';

type Props<T extends GridValidRowModel> = {
  columnProps: GridColDef<T>;
  type?: BalanceType;
};

export function amountColumn<T extends GridValidRowModel>({
  columnProps,
  type = BalanceType.DEBIT,
}: Props<T>): GridColDef<T> {
  return {
    filterOperators: [
      equalFilter,
      notEqualFilter,
      greaterThanFilter,
      greaterThanOrEqualFilter,
      lessThanFilter,
      lessThanOrEqualFilter,
      emptyFilter,
      notEmptyFilter,
    ],
    type: 'number',
    valueGetter: ({ value }: GridValueGetterParams<T>) => {
      if (type === BalanceType.DEBIT && value < 0) {
        return value;
      }

      return convertToSignedNumber(value, type);
    },
    valueFormatter: (params: GridValueFormatterParams<number | undefined>) => {
      return params.value && toDecimal(params.value);
    },
    groupingValueGetter: (params: GridGroupingValueGetterParams<T>) => {
      return toDecimal(params.value, type);
    },
    getApplyQuickFilterFn: (value: string) => {
      if (!value) return null;

      return (params: GridCellParams<T, string>): boolean => {
        if (!params) return false;

        return value.split(' ').some((splitFilter) => {
          const formatedValue = params.formattedValue;

          return formatedValue?.includes(splitFilter);
        });
      };
    },
    valueSetter: (params: GridValueSetterParams) => {
      return {
        ...params.row,
        [columnProps.field]: Math.abs(params.value),
        updated: { [columnProps.field]: Math.abs(params.value) },
      };
    },
    renderEditCell: (props) => <AmountInput {...props} />,
    ...columnProps,
  };
}
