import isNumber from 'lodash/isNumber';
import { Alert, AlertTitle, Chip, Stack, Typography } from '@mui/material';
import {
  AlertCircleIcon,
  AlertHexagonIcon,
  BuildingIcon,
  HourGlassIcon,
} from 'src/mui/_icons';
import { Popover } from 'antd';

import type { Boleto } from 'src/libs/finbits/Boletos/types';
import { BoletoStatus } from 'src/libs/finbits/Boletos/types';
import { format } from 'src/libs/finbits/Date';
import { toCurrency } from 'src/libs/finbits/Money';
import { formatDocument, typeFromDocument } from 'src/libs/finbits/Documents';
import {
  boletoTypeDictionary,
  payeeDocumentTypeDictionary,
  payeeNameDictionary,
} from 'src/libs/finbits/Boletos/translations';

import styles from './BoletoAlert.module.scss';

type Props = {
  boleto?: Boleto | null;
};

export default function BoletoAlert({ boleto }: Props) {
  if (!boleto) return null;

  if (boleto.status === BoletoStatus.PENDING) {
    return (
      <Alert
        severity="info"
        variant="filled"
        icon={<HourGlassIcon fontSize="small" />}
      >
        <Typography>Buscando dados do boleto</Typography>
      </Alert>
    );
  }

  if (boleto.status === BoletoStatus.NOT_FOUND) {
    return (
      <Alert
        severity="warning"
        variant="filled"
        icon={<AlertHexagonIcon fontSize="small" />}
      >
        <Typography>Boleto não encontrado</Typography>
      </Alert>
    );
  }

  if (boleto.status === BoletoStatus.PAID) {
    return (
      <Alert
        severity="warning"
        variant="filled"
        icon={<AlertHexagonIcon fontSize="small" />}
      >
        <Typography>
          Boleto já foi pago ou passou da data de vencimento
        </Typography>
      </Alert>
    );
  }

  const title = boleto.payeeTradeName
    ? `Para ${boleto.payeeTradeName}`
    : 'Dados do boleto';

  const showAmountComplements =
    Boolean(boleto.fine) ||
    Boolean(boleto.interest) ||
    Boolean(boleto.discount);

  const payeeDocumentType = typeFromDocument(boleto?.payeeDocument) ?? 'legal';

  return (
    <Alert
      className={styles.alert}
      severity="info"
      variant="filled"
      iconMapping={{
        info: <BuildingIcon fontSize="inherit" />,
        warning: <AlertHexagonIcon fontSize="inherit" />,
      }}
    >
      <AlertTitle className={styles.alertTitle}>
        <Stack direction="row" justifyContent="space-between">
          <span>{title}</span>
          <Chip label={boletoTypeDictionary[boleto.type]} size="small" />
        </Stack>
      </AlertTitle>
      <Stack className={styles.stack}>
        {boleto.payeeName && (
          <Typography component="p">
            {payeeNameDictionary[payeeDocumentType]}: <b>{boleto.payeeName}</b>
          </Typography>
        )}
        {boleto.payeeDocument && (
          <Typography component="p">
            {payeeDocumentTypeDictionary[payeeDocumentType]}:{' '}
            <b>{formatDocument(payeeDocumentType, boleto.payeeDocument)}</b>
          </Typography>
        )}
      </Stack>

      <Stack className={styles.stack}>
        {isNumber(boleto.amount) && (
          <Typography component="p">
            Valor total: <b>{toCurrency(boleto.amount)}</b>
          </Typography>
        )}
        {showAmountComplements && (
          <Typography component="p">
            Multa: <b>{toCurrency(boleto.fine ?? 0)}</b>
          </Typography>
        )}
        {showAmountComplements && (
          <Typography component="p">
            Juros: <b>{toCurrency(boleto.interest ?? 0)}</b>
          </Typography>
        )}
        {showAmountComplements && (
          <Typography component="p">
            Desconto: <b>{toCurrency(boleto.discount ?? 0)}</b>
          </Typography>
        )}
      </Stack>

      {boleto.segment && (
        <Typography component="p" className={styles.smallMaring}>
          Segmento: <b>{boleto.segment}</b>
        </Typography>
      )}
      {boleto.dueDate && (
        <Typography component="p" className={styles.smallMaring}>
          Vencimento: <b>{format(boleto.dueDate, 'dd/MM/yyyy')}</b>
        </Typography>
      )}
      {boleto.bankName && (
        <Typography component="p" className={styles.smallMaring}>
          Banco: <b>{boleto.bankName}</b>
        </Typography>
      )}
      {boleto.completedAt && (
        <Stack direction="row" spacing="8px" className={styles.smallMaring}>
          <Typography component="p">
            Consulta feita em: <b>{format(boleto.completedAt, 'dd/MM/yyyy')}</b>
          </Typography>
          {showAmountComplements && (
            <Popover
              placement="bottomLeft"
              content="Valor total, Multa e juros podem ter alterações dependendo da data de consulta."
              trigger="hover"
              arrowPointAtCenter
            >
              <AlertCircleIcon />
            </Popover>
          )}
        </Stack>
      )}
    </Alert>
  );
}
