import t from 'zod';

import { CategoryDecoder } from 'src/libs/finbits/Management/FinancialEntries/Categories/types';
import { NewInboxItemDecoder } from 'src/libs/finbits/Management/NewInboxItems/types';
import { ContactDecoder } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import { PaymentMethod } from 'src/libs/finbits/PaymentMethods/types';
import { PixType } from 'src/libs/finbits/Bank/Pix/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { BoletoDecoder } from 'src/libs/finbits/Boletos/types';
import { AttachmentDecoder } from 'src/libs/finbits/Management/Attachments/types';

import { ContactType } from '../../Organization/Companies/Contacts/enum';

export type EntrySuggestionsPostParams = {
  organizationId?: string;
  companyId?: string;
  contactId: string;
  type: string;
};

export type NewEntrySuggestionsPostParams = {
  organizationId: string;
  companyId: string;
  params: {
    contactId?: string;
    type?: string;
    inboxItemsIds?: string[];
    attachmentsIds?: string[];
  };
};

export enum SuggestionSource {
  INBOX_ITEM = 'inbox_item',
  CONTACT = 'contact',
  DDA = 'dda',
  EMAIL = 'email',
  NOTA_FISCAL = 'nota_fiscal',
  PRODUCT_NOTA_FISCAL = 'product_nota_fiscal',
  PURCHASE_ORDER = 'purchase_order',
  UPLOAD = 'upload',
  WHATSAPP = 'whatsapp',
  ATTACHMENT = 'attachment',
}

export const EntrySuggestionsDecoder = t.object({
  category: CategoryDecoder.nullish(),
});

const CategorySuggestionDecoder = t.object({
  id: t.string().nullish(),
  name: t.string(),
  type: t.nativeEnum(BalanceType),
});

const ContactSuggestionDecoder = t.object({
  id: t.string().nullish(),
  name: t.string().nullish(),
  nickname: t.string().nullish(),
  document: t.string(),
  type: t
    .union([t.literal('international'), t.nativeEnum(ContactType)])
    .nullable(),
});

const PaymentDetailsSuggestionDecoder = t.object({
  paymentMethod: t.nativeEnum(PaymentMethod).nullish(),
  pixKey: t.string().nullish(),
  pixType: t.nativeEnum(PixType).nullish(),
  accountDocument: t.string().nullish(),
  accountNumber: t.string().nullish(),
  accountType: t.string().nullish(),
  branchNumber: t.string().nullish(),
  paymentDetails: t.string().nullish(),
  routingNumber: t.string().nullish(),
  payeeName: t.string().nullish(),
  boleto: BoletoDecoder.nullish(),
  bankName: t.string().nullish(),
});

export type PaymentSuggestion = t.TypeOf<
  typeof PaymentDetailsSuggestionDecoder
>;

const SuggestionFieldsDecoder = t.object({
  amount: t.number().nullish(),
  comments: t.string().nullish(),
  date: t.string().nullish(),
  relevantDate: t.string().nullish(),
  description: t.string().nullish(),
  category: CategorySuggestionDecoder.nullish(),
  contact: ContactSuggestionDecoder.nullish(),
  paymentDetails: PaymentDetailsSuggestionDecoder.nullish(),
});

export type SuggestionFields = t.TypeOf<typeof SuggestionFieldsDecoder>;

export const NewEntrySuggestionDecoder = t.object({
  sourceType: t.nativeEnum(SuggestionSource),
  source: t.union([NewInboxItemDecoder, ContactDecoder, AttachmentDecoder]),
  fields: SuggestionFieldsDecoder,
  total: t.number(),
});

export const NewEntrySuggestionsDecoder = t.array(NewEntrySuggestionDecoder);

export type EntrySuggestions = t.TypeOf<typeof EntrySuggestionsDecoder>;
export type NewEntrySuggestion = t.TypeOf<typeof NewEntrySuggestionDecoder>;
export type ContactSuggestion = t.TypeOf<typeof ContactSuggestionDecoder>;
export type CategorySuggestion = t.TypeOf<typeof CategorySuggestionDecoder>;

export enum EntrySituation {
  SETTLED = 'settled',
  SCHEDULED = 'scheduled',
  PENDING_AUTHORIZATION = 'pending_authorization',
  PAID = 'paid',
  PROCESSING = 'processing',
  CANCELED = 'canceled',
  UNAUTHORIZED = 'unauthorized',
  FAILED = 'failed',
  REVERTED = 'reverted',
  PENDING = 'pending',
  WAITING_APPROVAL = 'waiting_approval',
  APPROVED = 'approved',
  REPROVED = 'reproved',
  WAITING_PAYMENT = 'waiting_payment',
  PAYMENT_SCHEDULED = 'payment_scheduled',
  DELETED = 'deleted',
}

export enum AvailableActions {
  CONCILIATE = 'conciliate',
  DELETE = 'delete',
  SEND_TO_WAITING_APPROVAL = 'send_to_waiting_approval',
  SEND_TO_APPROVED = 'send_to_approved',
  SEND_TO_REPROVED = 'send_to_reproved',
  SEND_TO_MANUAL_PAYMENT = 'send_to_manual_payment',
  SEND_TO_AUTOMATIC_PAYMENT = 'send_to_automatic_payment',
  UNDO_SEND_TO_APPROVED = 'undo_send_to_approved',
  UNDO_SEND_TO_WAITING_APPROVAL = 'undo_send_to_waiting_approval',
  UNDO_SEND_TO_WAITING_PAYMENT = 'undo_send_to_waiting_payment',
  MARK_AS_FINANCIAL_ENTRY = 'mark_as_financial_entry',
  SPLIT = 'split',
  ISSUE_NF = 'issue_nf',
  UNLINK_NF = 'unlink_nf',
  RESTORE = 'restore',
}

export type RestoreEntryPostParams = {
  organizationId: string;
  companyId: string;
  billsIds?: string[];
  receivableIds?: string[];
};
