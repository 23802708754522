import { FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Controller, useFormContext } from 'react-hook-form';

import SpecialTaxRuleField from './SpecialTaxRuleField';
import style from './AdditionalDataFields.module.scss';

export default function AdditionalDataFields() {
  const { control } = useFormContext();

  return (
    <Grid container alignContent="flex-start" rowGap={6} columnSpacing={6}>
      <Grid xs={12}>
        <Typography>
          Precisamos destas informações sobre a natureza da empresa. É possível
          encontrá-las na área logada da sua empresa no site da prefeitura.
        </Typography>
      </Grid>
      <Grid xs={6}>
        <Controller
          name="municipalRegistration"
          render={({ field, fieldState: { error } }) => (
            <TextField
              required
              {...field}
              label="Inscrição Municipal"
              helperText={error?.message}
              error={!!error}
            />
          )}
        />
      </Grid>
      <Grid xs={6}>
        <SpecialTaxRuleField name="specialTaxRuleId" control={control} />
      </Grid>
      <Grid xs={12}>
        <Controller
          name="simpleNational"
          render={({ field: { value, onChange } }) => (
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={value}
                  onChange={onChange}
                  name="simpleNational"
                  color="primary"
                />
              }
              label="Empresa optante pelo Simples Nacional"
              className={style.switch}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
