import { useCallback, useState } from 'react';

import { Typography } from 'antd';

import { snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';
import { Modal, Stepper, useStepper } from 'src/ui';

import {
  AccountType,
  SynchronizeType,
} from 'src/libs/finbits/Bank/Accounts/types';
import { useOpenBelvoWidget } from 'src/libs/belvo/BelvoWidget';
import { useOpenPluggyWidget } from 'src/libs/pluggy/PluggyWidget/PluggyWidget';
import {
  BTG_ROUTING_NUMBER,
  useSupportedBankProviderCodes,
} from 'src/libs/finbits/Bank/SupportedBank';
import { Provider } from 'src/libs/finbits/Bank/OpenBanking/types';

import CreateAccountForm from 'src/features/DEPRECATED_accounts/CreateAccountForm';

import AccountIllustration from './AccountIllustration';
import type { SubmitProps as BankSelectSubmitProps } from './BankSelectForm';
import BankSelectForm from './BankSelectForm';
import ChooseSynchronizeType from './ChooseSynchronizeType';

type Props = {
  organizationId: string;
  companyId: string;
  provider: Provider;
  onConnectWidgetSuccess?: (
    providerConnectionId: string,
    selectedInstitutionCode: string
  ) => void;
  onConnectWidgetCancel: () => void;
} & PortalProps;

function ChooseBank({
  organizationId,
  companyId,
  open = true,
  provider,
  onClose,
  onConnectWidgetSuccess,
  onConnectWidgetCancel,
  onExit,
}: Props) {
  const [syncLoading, setSyncLoading] = useState(false);

  const [synchronizeType, setSynchronizeType] = useState<SynchronizeType>();
  const [accountType, setAccountType] = useState<AccountType>(
    AccountType.CHECKING_ACCOUNT
  );
  const [isCashAccount, setIsCashAccount] = useState(false);
  const [routingNumber, setRoutingNumber] = useState<string | null>(null);

  const { providerCodes } = useSupportedBankProviderCodes(routingNumber);

  const bankAllowAutomatic = providerCodes.length > 0;

  const openConnectWidget = useOpenBelvoWidget();
  const openPluggyWidget = useOpenPluggyWidget();

  enum StepType {
    AccountIllustration = 1,
    InstitutionSelect = 2,
    ChooseSynchronizeType = 3,
    CreateAccountForm = 4,
  }

  const store = useStepper();
  const { goToStep } = store;

  const onSuccess = useCallback(
    (providerConnectionId: string, selectedInstitutionCode: string) => {
      onConnectWidgetSuccess?.(providerConnectionId, selectedInstitutionCode);
    },
    [onConnectWidgetSuccess]
  );

  function onSubmitBankForm({
    routingNumber,
    isCashAccount: isCashAccountSelected,
  }: BankSelectSubmitProps) {
    setIsCashAccount(isCashAccountSelected);

    if (isCashAccountSelected) {
      setAccountType(AccountType.CASH_ACCOUNT);
      setSynchronizeType(SynchronizeType.MANUAL);
      goToStep(StepType.CreateAccountForm);
      return;
    }

    setRoutingNumber(routingNumber);
    goToStep(StepType.ChooseSynchronizeType);
  }

  function getSelectedConnectorIds(): number[] {
    return providerCodes.map((providerCode) => parseInt(providerCode));
  }

  function onClickSyncTypeStep(synchronizeType: SynchronizeType) {
    setSyncLoading(true);
    setSynchronizeType(synchronizeType);

    if (
      synchronizeType === SynchronizeType.AUTOMATIC &&
      provider === Provider.PLUGGY
    ) {
      openPluggyWidget({
        companyId,
        organizationId,
        connectorIds: getSelectedConnectorIds(),
        errorModalHeader: 'Adicionar conta',
        onCancel: onConnectWidgetCancel,
        onSuccess,
      });

      onClose();
      return;
    } else if (synchronizeType === SynchronizeType.MANUAL) {
      setSyncLoading(false);
      return goToStep(StepType.CreateAccountForm);
    }

    openConnectWidget({
      companyId,
      organizationId,
      errorModalHeader: 'Adicionar conta',
      institutionCodes: filterInstitutionCodes(providerCodes),
      onCancel: onConnectWidgetCancel,
      onSuccess,
    });

    onClose();
    return;
  }

  function filterInstitutionCodes(providerCodes: string[]): string[] {
    return providerCodes.filter((providerCode) => !providerCode.includes('of'));
  }

  function onClickEditBank() {
    goToStep(StepType.InstitutionSelect);
  }

  function onCreateSuccess() {
    onClose();
    snackbar({
      variant: 'success',
      message: 'A nova conta foi adicionada com sucesso!',
    });
  }

  function onCreateError() {
    snackbar({
      variant: 'error',
      message: 'Ocorreu um erro ao criar a conta!',
    });
  }

  function handleOnGoBackAccountForm() {
    if (isCashAccount) {
      goToStep(StepType.InstitutionSelect);

      return;
    }

    goToStep(StepType.ChooseSynchronizeType);
  }

  return (
    <Modal footer={null} visible={open} onCancel={onClose} afterClose={onExit}>
      <Stepper store={store} title="Adicionar conta">
        <Stepper.Step step={StepType.AccountIllustration}>
          <AccountIllustration />
          <Typography.Paragraph>
            Cadastrar todas as suas contas bancárias no Finbits é o primeiro
            passo para a gestão financeira. Através delas serão importadas as
            transações financeiras que acontecem no banco.
          </Typography.Paragraph>
        </Stepper.Step>
        <Stepper.Step showContinue={false} step={StepType.InstitutionSelect}>
          <Typography.Paragraph>
            Vamos lá! Informe o banco da conta que deseja adicionar:
          </Typography.Paragraph>
          <BankSelectForm
            onSubmit={onSubmitBankForm}
            initialValues={{
              routingNumber,
              isCashAccount,
            }}
          />
        </Stepper.Step>
        <Stepper.Step
          showContinue={false}
          step={StepType.ChooseSynchronizeType}
        >
          <ChooseSynchronizeType
            onClickNext={onClickSyncTypeStep}
            allowAutomatic={bankAllowAutomatic}
            loading={syncLoading}
            isBTG={BTG_ROUTING_NUMBER === routingNumber}
          />
        </Stepper.Step>
        <Stepper.Step
          onGoBack={handleOnGoBackAccountForm}
          step={StepType.CreateAccountForm}
        >
          <CreateAccountForm
            submitButtonTitle="Adicionar"
            companyId={companyId}
            organizationId={organizationId}
            routingNumber={routingNumber}
            onError={onCreateError}
            onSuccess={onCreateSuccess}
            onClickEditBank={onClickEditBank}
            initialValues={{
              synchronizeType: synchronizeType,
              type: accountType,
            }}
          />
        </Stepper.Step>
      </Stepper>
    </Modal>
  );
}

export default ChooseBank;
