import compact from 'lodash/compact';
import { Button, Chip, Divider, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { PlusIcon } from 'src/mui/_icons';

import { Tooltip, Typography } from 'src/mui';

import { TreeSelect } from 'src/ui';

import {
  filterAvailable,
  filterLabelsIdsByClassification,
} from 'src/libs/finbits/Classifications';
import type { Classification } from 'src/libs/finbits/Classifications/types';
import type { Label } from 'src/libs/finbits/Labels/types';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';

import styles from './LabelSelect.module.scss';

export type Props = {
  disabled?: boolean;
  selectedLabels?: Label[];
  classifications: Classification[];
  onOpenCreateLabel: (classification: Classification) => void;
};

export default function LabelSelect({
  disabled = false,
  selectedLabels = [],
  classifications,
  onOpenCreateLabel,
}: Props) {
  const { watch, setValue } = useFormContext();

  const hasPermission = useAllowedPermission({
    action: 'create',
    resource: 'labels',
  });

  const isAllowedCreateLabels = hasPermission && !!onOpenCreateLabel;

  const classificationsForm = watch('classifications');

  function handleChange(classificationId: string, labelsIds: string[]) {
    setValue(`classifications.${classificationId}`, {
      classificationId,
      labelsIds,
    });
  }

  return (
    <Stack className={styles.stack}>
      {filterAvailable(classifications, selectedLabels).map(
        (classification) => {
          const isActive = classification.active;

          const selectedLabelsIds = filterLabelsIdsByClassification(
            selectedLabels,
            classification
          );

          const treeData = buildLabelOption(
            classification.labels,
            selectedLabelsIds
          );

          return (
            <Stack key={classification.id}>
              <label htmlFor={classification.id} className={styles.label}>
                {classification.name}
              </label>
              <TreeSelect
                value={
                  (classificationsForm &&
                    classificationsForm[classification.id]?.labelsIds) ??
                  []
                }
                id={classification.id}
                className={styles.treeSelect}
                dropdownClassName={styles.dropdown}
                getPopupContainer={(trigger) => trigger.parentElement}
                aria-label={classification.name}
                dropdownRender={(menu) => {
                  if (!isActive) {
                    return <></>;
                  }

                  return (
                    <>
                      {menu}
                      <>
                        <Divider />
                        <Tooltip
                          title={
                            <Stack>
                              <Typography gutterBottom>
                                Você não tem permissão para criar uma nova
                                etiqueta.
                              </Typography>
                              <Typography>
                                Caso precise, entre em contato com o
                                administrador.
                              </Typography>
                            </Stack>
                          }
                          placement="top"
                          arrow
                          disableHoverListener={isAllowedCreateLabels}
                        >
                          <Button
                            startIcon={<PlusIcon />}
                            onClick={() => onOpenCreateLabel(classification)}
                            className={styles.createLabelButton}
                            disabled={!isAllowedCreateLabels}
                          >
                            Criar Etiqueta
                          </Button>
                        </Tooltip>
                      </>
                    </>
                  );
                }}
                multiple
                showCheckedStrategy="SHOW_ALL"
                treeDefaultExpandAll
                placeholder="Selecione uma etiqueta"
                treeNodeFilterProp="name"
                treeData={treeData}
                notFoundContent={
                  <li className={styles.notFoundContent}>Sem opções</li>
                }
                tagRender={({ label, onClose }) => (
                  <Chip
                    label={label}
                    onDelete={onClose}
                    className={styles.chip}
                    variant="outlined"
                    size="small"
                  />
                )}
                onChange={(labelsIds) => {
                  handleChange(classification.id, labelsIds);
                }}
                fieldNames={{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }}
                disabled={disabled}
              />
            </Stack>
          );
        }
      )}
    </Stack>
  );
}

function isRelated(parent: Label, childrenIds?: string[]): boolean {
  const parentIsChild = childrenIds?.some((id) => id === parent.id) ?? false;

  return (
    parent?.children?.some((parentChildLabel) => {
      return (
        childrenIds?.some((id) => id === parentChildLabel.id) ||
        isRelated(parentChildLabel, childrenIds)
      );
    }) || parentIsChild
  );
}

function buildLabelOption(
  labels: Label[] = [],
  selectedLabelIds: string[] = []
): Label[] {
  return compact(
    labels?.map((label) => {
      const inactiveLabel = !label.active;

      const selected = selectedLabelIds?.some((id) => id === label.id) ?? false;

      if (!isRelated(label, selectedLabelIds) && inactiveLabel)
        return undefined;

      return {
        ...label,
        children: buildLabelOption(label.children, selectedLabelIds),
        disabled: !selected && inactiveLabel,
      };
    })
  );
}
