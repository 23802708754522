import { Chip } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-premium';

import type { QuickFilter } from '../types';

type QuickFilterChipProps = {
  filter: QuickFilter;
};

export default function QuickFilterChip({ filter }: QuickFilterChipProps) {
  const { filterItems, label } = filter;

  const { current: apiRef } = useGridApiContext();
  const { items: activeFilterItems } = apiRef.state.filter.filterModel;

  const chipIds = filterItems.map((filter) => filter.id);

  const isActive = activeFilterItems.some((filter) =>
    chipIds.includes(filter.id)
  );

  function handleClick() {
    if (isActive) {
      handleDelete();
      return;
    }

    handleInsertFilter();
  }

  function handleInsertFilter() {
    apiRef.upsertFilterItems(filterItems);
  }

  function handleDelete() {
    filterItems.forEach((filterItem) => apiRef.deleteFilterItem(filterItem));
  }

  return (
    <Chip
      label={label}
      size="small"
      variant={isActive ? 'filled' : 'outlined'}
      onClick={handleClick}
      onDelete={isActive ? handleDelete : undefined}
      color="secondary"
    />
  );
}
