import type { SxProps, Theme } from '@mui/material';
import { listItemButtonClasses } from '@mui/material';

export const listSubheader: SxProps<Theme> = {
  lineHeight: '20px',
  padding: '16px 18px 4px',
};

export const listItem: SxProps<Theme> = { padding: '0 8px', height: '64px' };

export const listItemButton: SxProps<Theme> = {
  padding: '10px 14px',
  borderRadius: '8px',
  [`&.${listItemButtonClasses.selected}`]: {
    backgroundColor: (theme) =>
      theme.palette.mode === 'light' ? 'grey.50' : 'grey.800',
  },
  [`&.${listItemButtonClasses.selected}:hover`]: {
    backgroundColor: (theme) =>
      theme.palette.mode === 'light' ? 'grey.50' : 'grey.800',
  },
};

export const avatar: SxProps<Theme> = {
  backgroundColor: (theme) =>
    theme.palette.mode === 'light' ? 'grey.100' : 'grey.700',
  color: (theme) => (theme.palette.mode === 'light' ? 'grey.600' : 'grey.100'),
};

export const listItemText: SxProps<Theme> = {
  margin: 0,
};

export const primaryTypography = {
  color: 'text.primary',
  fontWeight: '500',
};

export const secondaryTypography = {
  color: 'text.secondary',
  fontSize: '14px',
};
