import type { RefObject } from 'react';
import { useEffect, useMemo } from 'react';

type ObserverParams = {
  target?: RefObject<Element> | Element | Node | null;
  options?: MutationObserverInit;
  callback?: MutationCallback;
};

function getRefElement(
  element?: RefObject<Element> | Element | Node | null
): Element | undefined | null | Node {
  if (element && 'current' in element) {
    return element.current;
  }

  return element;
}

function useMutationObserver({
  target,
  options = {},
  callback,
}: ObserverParams): void {
  const observer = useMemo(() => {
    return new MutationObserver((mutationRecord, mutationObserver) => {
      callback?.(mutationRecord, mutationObserver);
    });
  }, [callback]);

  useEffect(() => {
    const element = getRefElement(target);

    if (observer && element) {
      observer.observe(element, options);
      return () => observer.disconnect();
    }
  }, [target, observer, options]);
}

export default useMutationObserver;
