import type { JSXElementConstructor } from 'react';

import type {
  GridCellParams,
  GridColDef,
  GridFilterItem,
  GridFilterOperator,
  GridSingleSelectColDef,
  GridValidRowModel,
  GridValueSetterParams,
} from '@mui/x-data-grid-premium';

import type { ComponentProps } from './EditInlineWrapper';
import EditInlineWrapper from './EditInlineWrapper';
import FilterWrapper from './FilterWrapper';
import SingleSelect from './SingleSelect';

type Props<T extends GridValidRowModel> = {
  columnProps: Omit<GridSingleSelectColDef<T>, 'type'>;
  SelectComponent?: JSXElementConstructor<any>;
  selectProps?: Partial<ComponentProps<T>>;
};

export function singleSelectColumn<T extends GridValidRowModel>({
  columnProps,
  SelectComponent = SingleSelect,
  selectProps,
}: Props<T>): GridColDef<T> {
  const filterOperators: GridFilterOperator[] = [
    {
      label: 'é',
      value: 'is',
      getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }

        return (params: GridCellParams<T, { id: string }>): boolean => {
          return params.value?.id === filterItem.value?.id;
        };
      },
      InputComponentProps: {
        Component: SelectComponent,
        options: columnProps.valueOptions,
      },
      InputComponent: FilterWrapper,
    },
    {
      label: 'não é',
      value: 'not',
      getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }

        return (params: GridCellParams<T, { id: string }>): boolean => {
          return params.value?.id !== filterItem.value?.id;
        };
      },
      InputComponentProps: {
        Component: SelectComponent,
        options: columnProps.valueOptions,
      },
      InputComponent: FilterWrapper,
    },
  ];

  return {
    filterOperators: filterOperators,
    type: 'singleSelect',
    getOptionValue: (value: any) => value?.id,
    getOptionLabel: (value: any) => value?.name,
    renderEditCell: (props) => (
      <EditInlineWrapper
        {...props}
        Component={SelectComponent}
        componentProps={selectProps}
        options={columnProps.valueOptions}
      />
    ),
    valueSetter: (params: GridValueSetterParams) => {
      const columnFieldId = `${columnProps.field}Id`;

      return {
        ...params.row,
        [columnProps.field]: params.value,
        [columnFieldId]: params.value?.id,
        updated: { [columnFieldId]: params.value?.id ?? null },
      };
    },
    ...columnProps,
  };
}
