import type { GridApi } from '@mui/x-data-grid-premium';
import { Divider } from '@mui/material';
import { DotsVerticalIcon } from 'src/mui/_icons';

import { Dropdown } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import { useAccounts } from 'src/libs/finbits/Bank/Accounts';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';

import ImportScheduledEntryModal from 'src/features/entries/Modals/ImportScheduledEntryModal';
import DeletedBillModal from 'src/features/bills-to-pay/DeletedBillModal';
import CreateBillDrawer from 'src/features/bills-to-pay/CreateBillDrawer';

import styles from './TableRootActions.module.scss';

type Props = {
  apiRef: GridApi;
};

export default function TableRootActions({ apiRef }: Props) {
  const openPortal = useOpenPortal();
  const { organizationId, companyId } = useCompanyParams();

  const { accounts, isLoading: isLoadingAccounts } = useAccounts(
    {
      organizationId,
      companyId,
    },
    { staleTime: FIVE_MINUTES_IN_MS }
  );

  function handleExportCsv() {
    apiRef.exportDataAsCsv();
  }

  function handleOpenImportScheduledEntryModal() {
    openPortal(ImportScheduledEntryModal, {
      accounts,
      isLoadingAccounts,
    });
  }

  function handleOpenCreateBillDrawer() {
    openPortal(CreateBillDrawer, {
      hideExtraActions: true,
      overlay: true,
      originValues: { type: BalanceType.DEBIT },
    });
  }

  function handleOpenDeleted() {
    openPortal(DeletedBillModal);
  }

  return (
    <Dropdown
      aria-label="ações"
      variant="text"
      options={[
        <WithAllowedPermission
          permissions={[
            {
              resource: 'bills',
              action: 'create',
            },
          ]}
          key="createScheduled"
        >
          <Dropdown.Option onClick={handleOpenCreateBillDrawer}>
            Criar lançamento programado
          </Dropdown.Option>
        </WithAllowedPermission>,
        <WithAllowedPermission
          permissions={{
            resource: 'entries',
            action: 'import_spreadsheet',
          }}
          key="uploadScheduled"
        >
          <Dropdown.Option onClick={handleOpenImportScheduledEntryModal}>
            Upload de lançamentos programados (XLSX)
          </Dropdown.Option>
        </WithAllowedPermission>,

        <Dropdown.Option onClick={handleExportCsv} key="createCsv">
          Download de lançamentos (CSV)
        </Dropdown.Option>,

        <WithAllowedPermission
          permissions={{
            resource: 'bills',
            action: 'delete_many',
          }}
          key="showDeleted"
        >
          <>
            <Divider key="drawersDivider" className={styles.divider} />

            <Dropdown.Option
              onClick={handleOpenDeleted}
              key="openDeletedDrawer"
            >
              Ver excluídos
            </Dropdown.Option>
          </>
        </WithAllowedPermission>,
      ]}
    >
      <DotsVerticalIcon />
    </Dropdown>
  );
}
