import type { Components } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiStepIcon({
  typography,
  palette,
}: ThemeConfig): Components['MuiStepIcon'] {
  const color = palette.mode === 'light' ? undefined : palette?.grey?.[800];
  return {
    styleOverrides: {
      root: {
        color,
      },
    },
  };
}
