import type { ReactNode } from 'react';

import { Button, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import styles from './CopyButton.module.less';

type Props = {
  text: string | string[];
  icon: ReactNode;
  url?: string;
};

export default function CopyButton({ text, icon, url = '' }: Props) {
  const tooltipsText = Array.isArray(text) ? text : [text, 'Copiado'];

  function handleCopyLink() {
    navigator.clipboard.writeText(url);
  }

  return (
    <Button className={styles.button}>
      <Typography.Paragraph
        className={styles.content}
        copyable={{
          icon: [icon, <CheckOutlined />],
          tooltips: tooltipsText,
          onCopy: handleCopyLink,
        }}
      />
    </Button>
  );
}
