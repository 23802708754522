import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Stack, TextField } from '@mui/material';
import { FormField } from 'src/mui/_scss';
import { CloseIcon, PlusIcon, TrashIcon } from 'src/mui/_icons';

import { IconButton, Typography } from 'src/mui';

import ConditionFieldSelect from './ConditionFieldSelect';
import ConditionTypeSelect from './ConditionTypeSelect';
import ConditionValues from './ConditionValues';
import styles from './ConditionFields.module.scss';

type Props = {
  isDefaultRule: boolean;
};

export default function ConditionFields({ isDefaultRule }: Props) {
  const form = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'conditions',
    rules: { minLength: 1 },
  });

  function handleAddRule() {
    append({
      field: null,
      type: null,
    });
  }

  function handleRemove(indexToRemove?: number) {
    remove(indexToRemove);
  }

  const showSingleControlls = fields.length > 1;

  if (isDefaultRule) {
    return (
      <Stack gap={1} className={styles.condition}>
        <FormField
          label="Condições da regra"
          name="conditions"
          control={form.control}
        />
        <Typography
          variant="body1"
          fontSize={16}
          color="grey.500"
          marginBottom={3}
        >
          Se nenhuma outra regra for aplicada
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack gap={4} className={styles.condition}>
      <FormField
        label="Condições da regra"
        name="conditions"
        control={form.control}
      />

      {fields.map((item, index) => (
        <Stack direction="row" alignItems="start" gap={4} key={item.id}>
          {showSingleControlls && (
            <div className={styles.singleControlls}>
              <IconButton
                title="remover condição"
                size="large"
                onClick={() => handleRemove(index)}
              >
                <CloseIcon />
              </IconButton>
              {index !== 0 && (
                <TextField value="E" disabled className={styles.andControll} />
              )}
            </div>
          )}
          <div className={styles.first}>
            <FormField
              label="Campo"
              name={`conditions.${index}.field`}
              control={form.control}
              defaultValue={null}
            >
              {(field) => {
                return <ConditionFieldSelect {...field} index={index} />;
              }}
            </FormField>
          </div>
          <div className={styles.second}>
            <FormField
              label="Condição"
              name={`conditions.${index}.type`}
              control={form.control}
              defaultValue={null}
            >
              {(field) => {
                return <ConditionTypeSelect {...field} index={index} />;
              }}
            </FormField>
          </div>
          <div className={styles.third}>
            <ConditionValues index={index} />
          </div>
        </Stack>
      ))}

      <Stack direction="row" justifyContent="space-between">
        <Button size="small" startIcon={<PlusIcon />} onClick={handleAddRule}>
          Adicionar condição
        </Button>
        <Button
          size="small"
          startIcon={<TrashIcon />}
          onClick={() => handleRemove()}
        >
          Limpar todas
        </Button>
      </Stack>
    </Stack>
  );
}
