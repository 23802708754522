import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
} from '@mui/material';
import { PlusIcon } from 'src/mui/_icons';
import { Navigate } from 'react-router';

import { generateCompanyPath } from 'src/router/routes';
import { RouteKey } from 'src/router/types';

import { Typography } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import {
  BillsApprovalsType,
  useCompany,
  useCompanyParams,
} from 'src/libs/finbits/Organization/Companies';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import { useInfiniteApprovalRules } from 'src/libs/finbits/ApprovalRules/ApprovalRules';

import RuleDescriptionCard from 'src/features/approval/approval-rules/RuleDescriptionCard';
import ApprovalRuleCard from 'src/features/approval/approval-rules/ApprovalRuleCard/ApprovalRuleCard';
import ApprovalRuleCardSkeleton from 'src/features/approval/approval-rules/ApprovalRuleCard/Skeleton';
import CreateApprovalRuleModal from 'src/features/approval/approval-rules/CreateApprovalRuleModal';

import styles from './ApprovalRules.module.scss';

export default function ApprovalRules() {
  const { organizationId, companyId } = useCompanyParams();

  const { company } = useCompany({ organizationId, companyId });

  const openPortal = useOpenPortal();

  function handleNewClick() {
    openPortal(CreateApprovalRuleModal);
  }

  const {
    ref: scrollBottomRef,
    approvalRules,
    isLoading,
    isFetching,
  } = useInfiniteApprovalRules({ companyId, organizationId });

  if (company && company.billsApprovals !== BillsApprovalsType.AUTOMATIC) {
    return (
      <Navigate
        to={generateCompanyPath(RouteKey.CONFIG_APPROVAL_FLOW, {
          companyId,
          organizationId,
        })}
      />
    );
  }

  return (
    <Box className={styles.container}>
      <div>
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          className={styles.title}
        >
          Regras de aprovação
        </Typography>

        <Typography color="grey.600">
          Crie e gerencie regras de aprovação de pagamentos para a empresa{' '}
          <strong>{company?.name}</strong>.
          <br />
          <strong>Veja como funciona:</strong>
        </Typography>
      </div>

      <Stack
        direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
        spacing={{ xs: 4, sm: 4, md: 4, lg: 6 }}
      >
        <RuleDescriptionCard
          index={1}
          title="Ative o fluxo de aprovação"
          description="Defina condições de valor, etiquetas e categorias para atribuir aprovadores automaticamente aos lançamentos."
        />
        <RuleDescriptionCard
          index={2}
          title="Organize-as em ordem de prioridade"
          description="Com três ou mais regras, defina a ordem de prioridade entre elas na listagem. Aprovador(es) padrão será sempre a última."
        />
        <RuleDescriptionCard
          index={3}
          title="As regras são consultadas nesta ordem"
          description="Para cada lançamento, a plataforma buscará qual regra deve ser aplicada, da primeira à última da listagem abaixo."
        />
        <RuleDescriptionCard
          index={4}
          title="Só uma regra é aplicada por lançamento"
          description="Ao ser encontrada a regra com todas as condições atendidas, ela será aplicada e os aprovadores são atribuídos ao lançamento."
        />
      </Stack>

      <Card className={styles.card}>
        <CardHeader
          action={
            <WithAllowedPermission
              permissions={{ resource: 'approvalsRules', action: 'create' }}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<PlusIcon />}
                onClick={handleNewClick}
              >
                Criar nova regra no topo da lista
              </Button>
            </WithAllowedPermission>
          }
          title="Listagem de regras criadas"
          subheader="Da prioridade maior para a menor"
          titleTypographyProps={{ variant: 'subtitle2' }}
          classes={{ action: styles.cardHeaderAction }}
        />
        <CardContent className={styles.cardContent}>
          {!isLoading && approvalRules && (
            <>
              {approvalRules.map((approvalRule, index) => {
                const isLast = index === approvalRules.length - 1;

                const nextRule = approvalRules[index + 1];
                const isBeforeDefaultRule =
                  Boolean(nextRule) && nextRule.defaultRule;

                return (
                  <ApprovalRuleCard
                    position={index + 1}
                    key={approvalRule.id}
                    approvalRule={approvalRule}
                    isFirst={index === 0}
                    isBeforeDefaultRule={isBeforeDefaultRule}
                    ref={isLast ? scrollBottomRef : null}
                  />
                );
              })}
            </>
          )}
          {isFetching && (
            <>
              <ApprovalRuleCardSkeleton />
              <ApprovalRuleCardSkeleton />
              <ApprovalRuleCardSkeleton />
              <ApprovalRuleCardSkeleton />
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
