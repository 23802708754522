import type {
  GridCellParams,
  GridColDef,
  GridFilterItem,
  GridFilterOperator,
} from '@mui/x-data-grid-premium';

import { toDecimal } from 'src/libs/finbits/Money';

import AmountFilter from './amoutFilter';

export const equalFilter: GridFilterOperator = {
  value: '=',
  label: 'igual à',
  getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return null;
    }

    return (params: GridCellParams): boolean => {
      const parsedFilterValue = toDecimal(filterItem.value);

      return params.formattedValue === parsedFilterValue;
    };
  },
  InputComponent: AmountFilter,
};

export const notEqualFilter: GridFilterOperator = {
  value: '!=',
  label: 'diferente de',
  getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return null;
    }

    return (params: GridCellParams): boolean => {
      const parsedFilterValue = toDecimal(filterItem.value);

      return params.formattedValue !== parsedFilterValue;
    };
  },
  InputComponent: AmountFilter,
};

export const lessThanFilter: GridFilterOperator = {
  value: '<',
  label: 'menor que',
  getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return null;
    }

    return (params: GridCellParams): boolean => {
      if (!params.formattedValue) return false;

      const parsedFilterValue = toDecimal(filterItem.value);

      return params.formattedValue < parsedFilterValue;
    };
  },
  InputComponent: AmountFilter,
};

export const lessThanOrEqualFilter: GridFilterOperator = {
  value: '<=',
  label: 'menor igual que',
  getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return null;
    }

    return (params: GridCellParams): boolean => {
      if (!params.formattedValue) return false;

      const parsedFilterValue = toDecimal(filterItem.value);

      return params.formattedValue <= parsedFilterValue;
    };
  },
  InputComponent: AmountFilter,
};

export const greaterThanFilter: GridFilterOperator = {
  value: '>',
  label: 'maior que',
  getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return null;
    }

    return (params: GridCellParams): boolean => {
      if (!params.formattedValue) return false;

      const parsedFilterValue = toDecimal(filterItem.value);

      return params.formattedValue > parsedFilterValue;
    };
  },
  InputComponent: AmountFilter,
};

export const greaterThanOrEqualFilter: GridFilterOperator = {
  value: '>=',
  label: 'maior igual que',
  getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return null;
    }

    return (params: GridCellParams): boolean => {
      if (!params.formattedValue) return false;

      const parsedFilterValue = toDecimal(filterItem.value);

      return params.formattedValue >= parsedFilterValue;
    };
  },
  InputComponent: AmountFilter,
};

export const emptyFilter: GridFilterOperator = {
  value: 'isEmpty',
  label: 'é vazio',
  getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
    if (!filterItem.field || !filterItem.operator) {
      return null;
    }

    return (params: GridCellParams): boolean => {
      return params.value === null;
    };
  },

  requiresFilterValue: false,
};

export const notEmptyFilter: GridFilterOperator = {
  value: 'isNotEmpty',
  label: 'não é vazio',
  getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
    if (!filterItem.field || !filterItem.operator) {
      return null;
    }

    return (params: GridCellParams): boolean => {
      return Boolean(params.value);
    };
  },

  requiresFilterValue: false,
};
