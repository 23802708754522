import { Stack } from '@mui/material';

import { Typography } from 'src/mui';

import BotAvatar from 'src/features/finbot/BotAvatar';

export default function EmptyState() {
  return (
    <Stack alignItems="center">
      <BotAvatar />

      <Typography fontWeight={600} color="text.secondary" marginTop={6}>
        Utilize o FinBot para auxiliar as suas análises financeiras da empresa.
      </Typography>
      <Typography fontWeight={600} color="text.secondary">
        Comece fazendo uma pergunta.
      </Typography>
      <Typography fontWeight={600} color="text.secondary">
        Exemplo: "Quais são as três categorias que tiveram maior gasto
        financeiro?"
      </Typography>
    </Stack>
  );
}
