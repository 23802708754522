import t from 'zod';

import {
  REQUIRED_MESSAGE,
  date,
  required,
} from 'src/libs/finbits/resolverValidations';
import { AttachmentDecoder } from 'src/libs/finbits/Management/Attachments/types';
import {
  BOLETO_BANKING_SIZE,
  BOLETO_CONSUMPTION_SIZE,
} from 'src/libs/finbits/Boletos';
import { PaymentMethod } from 'src/libs/finbits/PaymentMethods/types';
import { PixType } from 'src/libs/finbits/Bank/Pix/types';

import { pixKeyValidation } from '../resolverValidations/pixKey';

import {
  BillFrequencyRecurrence,
  BillPayableApprovalType,
  ClassificationLabelsDecoder,
} from './types';

export const BillFormResolver = t
  .object({
    description: required,
    amount: t.number().min(1, 'Deve ser maior que 0'),
    accountId: required,
    date: date,
    relevantDate: date.nullable(),
    contactId: t.string().nullish(),
    categoryId: t.string().nullish(),
    attachments: AttachmentDecoder.array().nullish(),
    assigneesIds: t.array(t.string()).nullish(),
    approvalType: t.nativeEnum(BillPayableApprovalType).nullish(),
    comments: t.string().nullable(),
    classifications: t.record(t.string(), ClassificationLabelsDecoder),
    isRecurrenceEnabled: t.boolean(),
    frequencyInterval: t.nativeEnum(BillFrequencyRecurrence).nullish(),
    quantity: t.string().nullish(),
    endDate: t.date().nullish(),
    paymentDetails: t
      .object({
        paymentMethod: t.nativeEnum(PaymentMethod).nullish(),
        paymentDetails: t.string().nullish(),
        // TODO: when Epic suggestions 0.5 goes live we can remove all this validation just keeping with pipe section
        pixType: t
          .string()
          .transform((value) => (Boolean(value) ? value : undefined))
          .pipe(t.nativeEnum(PixType).nullish())
          .nullish(),
        pixKey: t.string().nullish(),
        accountDocument: t.string().nullish(),
        routingNumber: t.string().nullish(),
        accountType: t
          .union([t.literal('checking_account'), t.literal('savings_account')])
          .nullish(),
        branchNumber: t.string().nullish(),
        accountNumber: t.string().nullish(),
        boletoId: t.string().nullish(),
        digitableLine: t.string().nullish(),
        payeeName: t.string().nullish(),
      })
      .superRefine(
        (
          {
            paymentMethod,
            digitableLine,
            pixKey,
            pixType,
            accountNumber,
            branchNumber,
            accountType,
            routingNumber,
            payeeName,
            accountDocument,
          },
          ctx
        ) => {
          if (paymentMethod === PaymentMethod.PIX) {
            if (!pixKey) {
              ctx.addIssue({
                code: 'custom',
                message: REQUIRED_MESSAGE,
                path: ['pixKey'],
              });
            }

            const pixtypes = Object.values(PixType);
            if (!pixType || !pixtypes.includes(pixType)) {
              ctx.addIssue({
                code: 'custom',
                message: 'Selecione uma Chave Pix válida',
                path: ['pixType'],
              });
            }

            if (pixKey && pixType) {
              if (!pixKeyValidation({ pixKey, pixType })) {
                ctx.addIssue({
                  code: 'custom',
                  message: 'Chave inválida.',
                  path: ['pixKey'],
                });
              }
            }
          }

          if (paymentMethod === PaymentMethod.BOLETO) {
            if (!digitableLine) {
              ctx.addIssue({
                code: 'custom',
                message: REQUIRED_MESSAGE,
                path: ['digitableLine'],
              });
            }

            if (digitableLine && digitableLine?.length < 47) {
              ctx.addIssue({
                code: 'too_small',
                message: 'Deve conter pelo menos 47 caracteres',
                path: ['digitableLine'],
                minimum: BOLETO_BANKING_SIZE,
                type: 'string',
                inclusive: true,
              });
            }

            if (digitableLine && digitableLine?.length > 48) {
              ctx.addIssue({
                code: 'too_big',
                message: 'Deve conter no máximo 48 caracteres',
                path: ['digitableLine'],
                maximum: BOLETO_CONSUMPTION_SIZE,
                type: 'string',
                inclusive: true,
              });
            }
          }

          if (paymentMethod === PaymentMethod.PIX_QR_CODE) {
            if (!pixKey) {
              ctx.addIssue({
                code: 'custom',
                message: REQUIRED_MESSAGE,
                path: ['pixKey'],
              });
            }
          }

          if (paymentMethod === PaymentMethod.WIRE_TRANSFER) {
            if (!accountNumber) {
              ctx.addIssue({
                code: 'custom',
                message: REQUIRED_MESSAGE,
                path: ['accountNumber'],
              });
            }

            if (!accountDocument) {
              ctx.addIssue({
                code: 'custom',
                message: REQUIRED_MESSAGE,
                path: ['accountDocument'],
              });
            }

            if (!payeeName) {
              ctx.addIssue({
                code: 'custom',
                message: REQUIRED_MESSAGE,
                path: ['payeeName'],
              });
            }

            if (!routingNumber) {
              ctx.addIssue({
                code: 'custom',
                message: REQUIRED_MESSAGE,
                path: ['routingNumber'],
              });
            }

            if (!accountType) {
              ctx.addIssue({
                code: 'custom',
                message: REQUIRED_MESSAGE,
                path: ['accountType'],
              });
            }

            if (!branchNumber) {
              ctx.addIssue({
                code: 'custom',
                message: REQUIRED_MESSAGE,
                path: ['branchNumber'],
              });
            }
          }
        }
      )
      .nullable(),
  })
  .superRefine(({ isRecurrenceEnabled, quantity, frequencyInterval }, ctx) => {
    if (!isRecurrenceEnabled) return;

    if (!frequencyInterval) {
      ctx.addIssue({
        code: 'custom',
        message: 'Esse campo precisa ser preenchido',
        path: ['frequencyInterval'],
      });
    }

    if (!quantity) {
      ctx.addIssue({
        code: 'custom',
        message: REQUIRED_MESSAGE,
        path: ['quantity'],
      });
    }

    if (Number(quantity) < 2) {
      ctx.addIssue({
        code: 'too_small',
        message: 'Deve ser maior que 1',
        path: ['quantity'],
        minimum: 1,
        type: 'number',
        inclusive: true,
      });
    }
    if (Number(quantity) > 53) {
      ctx.addIssue({
        code: 'too_big',
        message: 'Deve ser menor que 54',
        path: ['quantity'],
        maximum: 53,
        type: 'number',
        inclusive: true,
      });
    }
  });
