import type { Ref } from 'react';
import { forwardRef } from 'react';

import { Select } from 'src/mui/_scss';
import type { Props as SelectProps } from 'src/mui/_scss/Select/Select';

import { PaymentMethod } from 'src/libs/finbits/PaymentMethods/types';
import { PAYMENT_METHOD_DICTIONARY } from 'src/libs/finbits/PaymentMethods/translations';

function PaymentMethodSelect(
  {
    ...props
  }: Omit<
    SelectProps<PaymentMethod, false, true, false>,
    'options' | 'defaultValue'
  >,
  ref: Ref<HTMLDivElement>
) {
  return (
    <Select
      aria-label="payment-method"
      placeholder="Selecione o meio de pagamento"
      {...props}
      ref={ref}
      disableClearable
      options={Object.values(PaymentMethod)}
      getOptionLabel={(option: PaymentMethod) =>
        PAYMENT_METHOD_DICTIONARY[option]
      }
      isOptionEqualToValue={(option, value) => option === value}
    />
  );
}

export default forwardRef(PaymentMethodSelect);
