import { Link as RouteLink } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Fade,
  Link,
  Stack,
} from '@mui/material';
import { SettingsIcon } from 'src/mui/_icons';

import { generateCompanyPath } from 'src/router/routes';
import { RouteKey } from 'src/router/types';

import { Typography } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import {
  BillsApprovalsType,
  useCompany,
  useCompanyParams,
} from 'src/libs/finbits/Organization/Companies';

import styles from './CreateApprovalRulesCard.module.scss';
import ApprovalTipModal from './ApprovalTipModal/ApprovalTipModal';

export default function CreateApprovalRulesCard() {
  const openPortal = useOpenPortal();

  const { companyId, organizationId } = useCompanyParams();

  const { company } = useCompany({ organizationId, companyId });

  const isDisabled = company?.billsApprovals === BillsApprovalsType.DISABLED;
  const isAutomatic = company?.billsApprovals === BillsApprovalsType.AUTOMATIC;
  const isManual = company?.billsApprovals === BillsApprovalsType.MANUAL;

  function handleOpenTipModal() {
    openPortal(ApprovalTipModal, {
      automaticTips: isAutomatic,
    });
  }

  return (
    <Card variant="outlined" className={styles.card}>
      <CardHeader
        title="3. Defina os aprovadores"
        className={styles.cardHeader}
        titleTypographyProps={{
          variant: 'subtitle2',
          color: isDisabled ? 'grey.400' : undefined,
        }}
      />
      <CardContent className={styles.cardContent}>
        <Fade
          in={isDisabled}
          timeout={{ exit: 0, enter: 500 }}
          unmountOnExit
          mountOnEnter
        >
          <Typography color="grey.400">
            Ative o fluxo de aprovação e escolha o modo de atribuição de
            aprovadores para prosseguir nesta configuração.
          </Typography>
        </Fade>

        <Fade
          in={isManual}
          timeout={{ exit: 0, enter: 500 }}
          unmountOnExit
          mountOnEnter
        >
          <Stack direction="column" alignItems="start">
            <Typography color="grey.600" fontWeight={600}>
              Quem pode ser um aprovador?
            </Typography>
            <Typography color="grey.600" fontWeight={400} marginTop={2}>
              Admin e gestores já possuem permissão de aprovação.
              <br />
              Crie e gerencie aprovadores em{' '}
              <SettingsIcon className={styles.icon} />
              {' > '}
              <RouteLink
                className={styles.link}
                to={generateCompanyPath(RouteKey.USERS, {
                  companyId,
                  organizationId,
                })}
                aria-label="usuários"
                role="link"
              >
                Usuários.
              </RouteLink>
            </Typography>

            <Typography color="grey.600" fontWeight={600} marginTop={8}>
              Como atribuir aprovadores aos lançamentos?
            </Typography>
            <Typography
              color="grey.600"
              fontWeight={400}
              marginTop={2}
              marginBottom={8}
            >
              No <strong>modo de atribuição manual</strong>, será preciso
              preencher quem são os aprovadores em cada lançamento criado.
            </Typography>

            <Button
              color="primary"
              variant="contained"
              onClick={handleOpenTipModal}
            >
              Veja como funciona
            </Button>
          </Stack>
        </Fade>

        <Fade
          in={isAutomatic}
          timeout={{ exit: 0, enter: 500 }}
          unmountOnExit
          mountOnEnter
        >
          <Stack direction="column" alignItems="start">
            <Typography color="grey.600" marginBottom={4}>
              Com a <strong>automatização por regras de aprovação</strong> você
              configura condições para atribuir aprovadores com base no valor,
              etiquetas e outros dados do lançamento a pagar.
            </Typography>

            <RouteLink
              className={styles.link}
              to={generateCompanyPath(RouteKey.APPROVAL_RULES, {
                companyId,
                organizationId,
              })}
              aria-label="Criar e gerenciar regras de aprovação"
              role="link"
            >
              <Button color="primary" variant="contained">
                Criar e gerenciar regras de aprovação
              </Button>
            </RouteLink>

            <Typography color="grey.600" fontWeight={600} marginTop={8}>
              Quem pode ser um aprovador?
            </Typography>
            <Typography color="grey.600" marginTop={2}>
              Admin e gestores já possuem permissão de aprovação. <br />
              Crie e gerencie aprovadores em{' '}
              <SettingsIcon className={styles.icon} />
              {' > '}
              <RouteLink
                className={styles.link}
                to={generateCompanyPath(RouteKey.USERS, {
                  companyId,
                  organizationId,
                })}
                aria-label="usuários"
                role="link"
              >
                Usuários.
              </RouteLink>
            </Typography>

            <Link
              onClick={handleOpenTipModal}
              className={styles.link}
              aria-label="Entenda melhor"
              role="button"
            >
              Entenda melhor
            </Link>
          </Stack>
        </Fade>
      </CardContent>
    </Card>
  );
}
