import type { Components } from '@mui/material';
import { inputBaseClasses, outlinedInputClasses } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiOutlinedInput({
  palette,
}: ThemeConfig): Components['MuiOutlinedInput'] {
  const disabledBackgroundColor =
    palette.mode === 'light' ? palette.grey?.[50] : palette.grey?.[800];

  const disabledTextColor =
    palette.mode === 'light' ? palette.grey?.[500] : palette.grey?.[400];

  return {
    defaultProps: {
      notched: false,
      size: 'medium',
    },
    styleOverrides: {
      root: {
        [`&.${outlinedInputClasses.disabled}`]: {
          backgroundColor: disabledBackgroundColor,
          [`& .${inputBaseClasses.input}`]: {
            color: `${disabledTextColor} !important`,
            WebkitTextFillColor: disabledTextColor,
          },
        },
      },
    },
  };
}
