import { forwardRef } from 'react';
import type { ReactNode, Ref } from 'react';

import { CardContent, CardHeader, Card as MuiCard } from '@mui/material';
import cn from 'classnames';

import styles from './Card.module.scss';

type Props = {
  notDraggable?: boolean;
  title: ReactNode;
  action: ReactNode;
  children: ReactNode;
};

function Card(
  { notDraggable = false, title, action, children }: Props,
  ref: Ref<HTMLDivElement>
) {
  const cardClassName = cn({
    [styles.notDraggable]: notDraggable,
  });

  return (
    <MuiCard variant="outlined" className={cardClassName} ref={ref}>
      <CardHeader
        classes={{ action: styles.cardAction, root: styles.cardHeader }}
        title={title}
        action={action}
      />
      <CardContent className={styles.cardContent}>{children}</CardContent>
    </MuiCard>
  );
}

export default forwardRef(Card);
