import type { MouseEvent } from 'react';
import { useState } from 'react';

import { Link } from 'react-router-dom';
import {
  Box,
  Divider,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from '@mui/material';
import { BellIcon } from 'src/mui/_icons';

import { generateCompanyPath } from 'src/router/routes';
import { RouteKey } from 'src/router/types';

import { Badge } from 'src/mui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useFailedOpenBankingConnections } from 'src/libs/finbits/Bank/OpenBanking';
import { ONE_HOUR_IN_MS } from 'src/libs/finbits/Time';
import { useBillsPendingMyApproval } from 'src/libs/finbits/Bills';
import { pluralize } from 'src/libs/finbits/Pluralize';
import type { OpenBankingConnectionStatus } from 'src/libs/finbits/Bank/OpenBanking/types';

import NotificationsEmptyState from './NotificationsEmptyState';
import { divider, header } from './NotificationsMenu.sx';

const FAILED_STATUS: Record<OpenBankingConnectionStatus, string | undefined> = {
  invalid_credentials: 'Credenciais desatualizadas',
  mfa_required: 'Autenticação necessária',
  activating: undefined,
  active: undefined,
  expired: undefined,
};

export default function NotificationsMenu() {
  const { companyId, organizationId } = useCompanyParams();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { failedConnections } = useFailedOpenBankingConnections(
    {
      companyId,
      organizationId,
    },
    {
      staleTime: ONE_HOUR_IN_MS,
    }
  );

  const { bills } = useBillsPendingMyApproval(
    {
      companyId,
      organizationId,
    },
    {
      staleTime: ONE_HOUR_IN_MS,
    }
  );

  const hasPendingMyApproval = bills.length > 0;
  const hasFailedConnections = failedConnections.length > 0;

  const notificationsLength = bills.length + failedConnections.length;
  const isEmpty = notificationsLength === 0;

  const isVisibility = !!anchorEl;

  function toggleVisibility(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl((state) => (state ? null : event.currentTarget));
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton
        onClick={toggleVisibility}
        size="large"
        aria-label="Notificações"
      >
        <Badge
          variant="dot"
          color="error"
          invisible={!hasPendingMyApproval && !hasFailedConnections}
        >
          <BellIcon />
        </Badge>
      </IconButton>

      <Menu
        open={isVisibility}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        sx={{ mt: 2 }}
      >
        <Stack direction="row" alignItems="center" gap={2} sx={header}>
          <Typography variant="subtitle1">Notificações</Typography>
          <Badge
            color="secondary"
            badgeContent={notificationsLength}
            showZero
          />
        </Stack>

        {isEmpty ? (
          <NotificationsEmptyState />
        ) : (
          <MenuList>
            {hasFailedConnections && (
              <Box sx={{ my: 4, px: 2 }}>
                <Typography variant="body1" fontWeight={700}>
                  Atualização automática pendente
                </Typography>
                <Typography>
                  {pluralize(
                    failedConnections.length,
                    'Autenticação necessária na seguinte conta',
                    'Autenticação necessária nas seguintes contas'
                  )}
                </Typography>
              </Box>
            )}

            {failedConnections.map((failedConnection) => (
              <MenuItem
                component={Link}
                to={generateCompanyPath(RouteKey.ACCOUNTS, {
                  companyId,
                  organizationId,
                })}
                key={failedConnection.id}
              >
                <ListItemText
                  primary={failedConnection.bankName}
                  secondary={FAILED_STATUS[failedConnection.status]}
                />
              </MenuItem>
            ))}

            {hasPendingMyApproval && hasFailedConnections && (
              <Divider sx={divider} />
            )}

            {hasPendingMyApproval && (
              <MenuItem
                component={Link}
                to={generateCompanyPath(RouteKey.APPROVAL, {
                  companyId,
                  organizationId,
                })}
              >
                <Typography>
                  Você possui <b>{bills.length}</b>{' '}
                  {pluralize(
                    bills.length,
                    'pagamento pendente de aprovação',
                    'pagamentos pendentes de aprovação'
                  )}
                </Typography>
              </MenuItem>
            )}
          </MenuList>
        )}
      </Menu>
    </>
  );
}
