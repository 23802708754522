import cn from 'classnames';
import { Alert, Box, List } from '@mui/material';
import { AlertTriangleIcon, InfoCircleIcon } from 'src/mui/_icons';
import CollapseSection from 'src/mui/_scss/CollapseSection';

import { Tooltip, Typography } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import type {
  Attachment as AttachmentType,
  ExternalFiles,
} from 'src/libs/finbits/Management/Attachments/types';

import PreviewAttachment from 'src/features/preview/PreviewAttachment/PreviewAttachment';
import AttachmentItem from 'src/features/inbox-items/InboxItemDetails/AttachmentItem';

import styles from './AttachmentsContainer.module.scss';

type Props = {
  attachments: ExternalFiles[] | AttachmentType[];
  isInvalid?: boolean;
  sender?: string | null;
};

export default function AttachmentsContainer({
  attachments,
  isInvalid = false,
  sender,
}: Props) {
  const openPortal = useOpenPortal();

  function handleOpenPreview(defaultActiveIndex: number) {
    if (isInvalid === false) {
      openPortal(PreviewAttachment, {
        attachments,
        defaultActiveIndex,
      } as { attachments: AttachmentType[] });
    }
  }

  return (
    <Box>
      <CollapseSection
        quantity={`${attachments.length}`}
        title={isInvalid ? 'Anexos não suportados' : 'Anexos suportados'}
      >
        {isInvalid && attachments.length !== 0 && (
          <Box className={styles.alert}>
            <Alert
              icon={<AlertTriangleIcon className={styles.alertIcon} />}
              severity="warning"
            >
              <Typography color="text.secondary" display="flex" gap={3}>
                Os arquivos abaixo não são suportados e não serão incluídos no
                lançamento.
                <Tooltip
                  placement="top"
                  title="São aceitos apenas os arquivos:
                JPG, PNG, GIF, PDF, XLS, XML, OFX ou TXT"
                >
                  <InfoCircleIcon className={styles.tooltipIcon} />
                </Tooltip>
              </Typography>
            </Alert>
          </Box>
        )}
        <List
          disablePadding
          className={cn(styles.list, { [styles.invalidList]: isInvalid })}
        >
          {attachments.length === 0 && (
            <Typography marginTop={3} color="text.secondary">
              Sem anexos
            </Typography>
          )}
          {attachments.map((attachment, index) => (
            <AttachmentItem
              key={attachment.id}
              isInvalid={isInvalid}
              attachment={attachment}
              index={index}
              onClick={handleOpenPreview}
              sender={sender}
            />
          ))}
        </List>
      </CollapseSection>
    </Box>
  );
}
