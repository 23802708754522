import { Box, Link, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Controller, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';

import { TextField, snackbar } from 'src/mui';

import { useSignIn } from 'src/libs/finbits/Auth';
import type { SignInParams } from 'src/libs/finbits/Auth/types';
import { SignInResolver } from 'src/libs/finbits/Auth/types';

function SignIn() {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<SignInParams>({
    defaultValues: {
      login: '',
      password: '',
    },
    resolver: zodResolver(SignInResolver),
  });

  const { signIn, isLoading } = useSignIn();

  function onSubmit(params: SignInParams) {
    signIn(params, {
      onError: (error) => {
        if (error.response?.status === 401) {
          return setError('password', {
            message: 'E-mail ou senha inválidos!',
          });
        }

        snackbar({
          variant: 'error',
          message: 'Ops! Houve uma falha na conexão. Tente novamente.',
        });
      },
    });
  }

  return (
    <Box sx={{ mt: 6, mb: 16 }}>
      <Typography
        variant="h5"
        component="h2"
        sx={{ mb: 4, textAlign: 'center', fontWeight: '600' }}
      >
        Faça login na sua conta
      </Typography>
      <Typography variant="body1" sx={{ mb: 4, textAlign: 'center' }}>
        Bem vindo de volta! Por favor, insira seus dados.
      </Typography>

      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="login"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.login}
              helperText={errors.login?.message}
              label="E-mail"
              margin="normal"
              placeholder="E-mail"
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField.Password
              {...field}
              error={!!errors.password}
              helperText={errors.password?.message}
              label="Senha"
              margin="normal"
              placeholder="••••••••"
              type="password"
            />
          )}
        />

        <Box sx={{ mt: 4 }}>
          <Link component={RouterLink} to="/recover_password">
            Esqueceu sua senha?
          </Link>
        </Box>

        <LoadingButton
          color="primary"
          fullWidth
          loading={isLoading}
          sx={{ mt: 6 }}
          type="submit"
          variant="contained"
          size="large"
        >
          Entrar
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default SignIn;
