import type { Color, Components } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiAlert({
  palette,
}: ThemeConfig): Components['MuiAlert'] {
  const isLightMode = palette.mode === 'light';

  const { 600: errorColorLight, 500: errorColorDark } =
    palette.error! as Partial<Color>;

  const { 600: warningColorLight, 500: warningColorDark } =
    palette.warning! as Partial<Color>;

  const { 600: infoColorLight, 200: infoColorDark } =
    palette.grey as Partial<Color>;

  return {
    styleOverrides: {
      filled: {
        backgroundColor: isLightMode
          ? palette?.grey?.[50]
          : palette?.grey?.[900],
      },
      filledInfo: {
        color: isLightMode ? infoColorLight : infoColorDark,
      },
      filledWarning: {
        color: isLightMode ? warningColorLight : warningColorDark,
      },
      filledError: {
        color: isLightMode ? errorColorLight : errorColorDark,
      },
      standard: {
        backgroundColor: palette.background?.paper,
      },
      standardInfo: {
        color: isLightMode ? infoColorLight : infoColorDark,
      },
      standardWarning: {
        color: isLightMode ? warningColorLight : warningColorDark,
      },
      standardError: {
        color: isLightMode ? errorColorLight : errorColorDark,
      },
    },
  };
}
