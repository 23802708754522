import { useMemo } from 'react';

import { Document, Page } from 'react-pdf/dist/esm/entry.vite';
import type { DocumentProps } from 'react-pdf';
import { Alert } from '@mui/material';
import type { RcFile } from 'antd/lib/upload';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { Loader } from 'src/ui';

import type { Attachment } from 'src/libs/finbits/Management/Attachments/types';

type Props = {
  attachment?: Attachment;
  file?: RcFile;
  zoom?: number;
  rotate?: number;
  width?: number;
  pageNumber?: number;
  onDocumentLoadSuccess: DocumentProps['onLoadSuccess'];
  onClose?: () => void;
};

export default function PreviewPDF({
  attachment,
  file,
  zoom = 1,
  rotate = 0,
  width,
  pageNumber = 1,
  onDocumentLoadSuccess,
  onClose,
}: Props) {
  const attachmentFile = useMemo(
    () => ({ url: attachment?.url }),
    [attachment]
  );

  function handleOnPassword(callback: (password: string) => void) {
    const password = prompt('Digite a senha para abrir este arquivo PDF.');

    if (password === null) {
      return onClose?.();
    }

    return callback(password);
  }

  return (
    <Document
      file={file || attachmentFile}
      onLoadSuccess={onDocumentLoadSuccess}
      loading={<Loader />}
      onPassword={handleOnPassword}
      error={
        <Alert severity="error">
          Falha ao carregar arquivo, tente novamente.
        </Alert>
      }
    >
      <Page
        pageNumber={pageNumber}
        scale={zoom}
        rotate={rotate}
        width={width}
      />
    </Document>
  );
}
