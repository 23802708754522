import type { ButtonProps } from 'antd';
import { Button, Tooltip } from 'antd';
import { LikeOutlined } from '@ant-design/icons';

import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import useApprovedAction from 'src/features/bills-to-pay/PaymentsFlow/useApprovedAction';

type Props = {
  selectedBills: BillPayable[];
  onSuccess?: () => void;
} & ButtonProps;

export default function ApprovedButton({
  selectedBills,
  onSuccess,
  ...rest
}: Props) {
  const { sendToApproved, isApproving } = useApprovedAction({
    selecteds: selectedBills,
    onSuccess,
  });

  const isEmpty = selectedBills.length === 0;

  return (
    <WithAllowedPermission
      permissions={{ resource: 'approvedPayments', action: 'create' }}
    >
      <Tooltip title="Aprovar" placement="bottom">
        <Button
          icon={<LikeOutlined />}
          aria-label="Aprovar"
          disabled={isEmpty}
          loading={isApproving}
          onClick={() => sendToApproved()}
          {...rest}
        />
      </Tooltip>
    </WithAllowedPermission>
  );
}
