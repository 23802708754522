import type { JSXElementConstructor } from 'react';

import type {
  GridCellParams,
  GridColDef,
  GridFilterItem,
  GridFilterOperator,
  GridGroupingValueGetterParams,
  GridRenderEditCellParams,
  GridValidRowModel,
  GridValueFormatterParams,
  GridValueSetterParams,
} from '@mui/x-data-grid-premium';

import FilterWrapper from '../singleSelectColumn/FilterWrapper';
import EditInlineWrapper from '../singleSelectColumn/EditInlineWrapper';

type Props<T extends GridValidRowModel, EnumValue extends string> = {
  columnProps: GridColDef<T>;
  enumTranslation: Record<EnumValue, string>;
  SelectComponent: JSXElementConstructor<any>;
};

export function enumColumn<
  T extends GridValidRowModel,
  EnumValue extends string
>({
  columnProps,
  enumTranslation,
  SelectComponent,
}: Props<T, EnumValue>): GridColDef<T> {
  const filterOperators: GridFilterOperator[] = [
    {
      label: 'é',
      value: 'is',
      getApplyFilterFn: (filterItem: GridFilterItem, _column: GridColDef) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }

        return (params: GridCellParams<T, EnumValue>): boolean => {
          return params.value === filterItem.value;
        };
      },
      getValueAsString: (enumType: EnumValue) => enumTranslation[enumType],

      InputComponentProps: {
        Component: SelectComponent,
      },
      InputComponent: FilterWrapper,
    },
    {
      label: 'não é',
      value: 'not',
      getApplyFilterFn: (filterItem: GridFilterItem, _column: GridColDef) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }

        return (params: GridCellParams<T, EnumValue>): boolean => {
          return params.value !== filterItem.value;
        };
      },
      getValueAsString: (enumType: EnumValue) => enumTranslation[enumType],

      InputComponentProps: {
        Component: SelectComponent,
      },
      InputComponent: FilterWrapper,
    },
  ];

  return {
    type: 'enum',
    filterOperators,
    valueFormatter: (params: GridValueFormatterParams<EnumValue>) =>
      enumTranslation[params?.value],
    valueSetter: (params: GridValueSetterParams) => {
      return {
        ...params.row,
        [columnProps.field]: params?.value,
        updated: { [columnProps.field]: params?.value ?? null },
      };
    },
    renderEditCell: (params: GridRenderEditCellParams) => (
      <EditInlineWrapper {...params} Component={SelectComponent} />
    ),
    groupingValueGetter: (
      params: GridGroupingValueGetterParams<T, EnumValue>
    ) => {
      return enumTranslation[params.value] ?? `Sem ${columnProps.headerName}`;
    },
    ...columnProps,
  };
}
