import t from 'zod';

import { AttachmentDecoder } from 'src/libs/finbits/Management/Attachments/types';
import { AccountDecoder } from 'src/libs/finbits/Bank/Accounts/types';
import { CategoryDecoder } from 'src/libs/finbits/Management/FinancialEntries/Categories/types';
import { ContactDecoder } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import { UserDecoder } from 'src/libs/finbits/Organization/Users/types';
import { LabelDecoder } from 'src/libs/finbits/Labels/types';
import { ScheduledRecurrenceBaseDecoder } from 'src/libs/finbits/Management/ScheduledRecurrences/types';
import type { PaymentDetails } from 'src/libs/finbits/Payments/types';
import { PaymentDetailsDecoder } from 'src/libs/finbits/Payments/types';
import {
  AvailableActions,
  EntrySituation,
} from 'src/libs/finbits/Management/Entries/types';
import { EntryType } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { EntryClassificationsDecoder } from 'src/libs/finbits/Classifications/types';

import type { ManualConciliateFormProps } from 'src/features/entries/ManualConciliateHeaderButton/ManualConciliateModal';
import { EditableFields } from 'src/features/EntryForm/types';

import type { BillFormResolver } from './validations';

export enum BillFrequencyRecurrence {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  BIWEEKLY = 'biweekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  YEARLY = 'yearly',
}

export enum BillStatus {
  PENDING = 'pending',
  WAITING_APPROVAL = 'waiting_approval',
  APPROVED = 'approved',
  REPROVED = 'reproved',
  WAITING_PAYMENT = 'waiting_payment',
}

export enum BillApprovalStatus {
  PENDING = 'pending',
  WAITING_APPROVAL = 'waiting_approval',
  APPROVED = 'approved',
  REPROVED = 'reproved',
}

export enum BillPayableApprovalType {
  ALL = 'all',
  ANY = 'any',
}

const BillApprovalStatusDecoder = t.nativeEnum(BillApprovalStatus);
const BillPayableStatusDecoder = t.nativeEnum(BillStatus);
const BillPayableApprovalTypeDecoder = t.nativeEnum(BillPayableApprovalType);

const BillPayableAssignmentDecoder = t.object({
  approvedAt: t.string().nullable(),
  reprovedAt: t.string().nullable(),
  reprovalReason: t.string().nullable(),
  assignee: UserDecoder.nullable(),
});

const BillPayableLastApprovalDecoder = t.object({
  assignments: BillPayableAssignmentDecoder.array().optional(),
  requester: UserDecoder.nullish(),
  status: BillApprovalStatusDecoder,
  requestedAt: t.string().nullable(),
});

export const BillPayableDecoder = t.object({
  account: AccountDecoder.optional(),
  accountId: t.string(),
  amount: t.number(),
  attachments: AttachmentDecoder.array().optional(),
  category: CategoryDecoder.nullish(),
  categoryId: t.string().nullable(),
  comments: t.string().nullable(),
  companyId: t.string(),
  contact: ContactDecoder.nullish(),
  contactId: t.string().nullable(),
  date: t.string(),
  description: t.string(),
  id: t.string(),
  publicId: t.string(),
  paymentDetails: PaymentDetailsDecoder.nullish(),
  relevantDate: t.string().nullable(),
  status: BillPayableStatusDecoder,
  lastApproval: BillPayableLastApprovalDecoder.nullish(),
  unfilledContextFields: t.string().array().nullable(),
  labels: LabelDecoder.array().optional(),
  assignees: UserDecoder.array().optional(),
  approvalType: BillPayableApprovalTypeDecoder,
  currentSituation: t.nativeEnum(EntrySituation).nullish(),
  editableFields: t.nativeEnum(EditableFields).array().nullish(),
  availableActions: t.nativeEnum(AvailableActions).array().nullish(),
  scheduledRecurrence: ScheduledRecurrenceBaseDecoder.nullish(),
  scheduledRecurrenceId: t.string().nullable(),
  paymentRequestErrorDetail: t.string().nullable(),
  entryType: t.nativeEnum(EntryType),
  amountType: t.nativeEnum(BalanceType),
  classifications: EntryClassificationsDecoder.array().nullish(),
});

export const RecurringBillDecoder = t.object({
  id: t.string(),
  initialDate: t.string().nullable(),
  quantity: t.number(),
  bills: BillPayableDecoder.array(),
});

export const ClassificationLabelsDecoder = t.object({
  classificationId: t.string(),
  labelsIds: t.string().array(),
});

export type ClassificationLabels = t.TypeOf<typeof ClassificationLabelsDecoder>;

export type BillFormParams = t.TypeOf<typeof BillFormResolver>;
export type BillParams = Omit<
  t.TypeOf<typeof BillFormResolver>,
  'classifications' | 'attachments'
> & {
  classifications: ClassificationLabels[];
  attachmentsIds: string[];
  inboxItemsIds: string[];
};

type Entry = Omit<BillParams, 'isRecurrenceEnabled'>;
export type BillRecurrence = {
  entries: Entry[];
};
export type BillRecurrencePostParam = BillRecurrence & {
  companyId: string;
  organizationId: string;
};

type EditableParams = Pick<
  Partial<BillPayable>,
  | 'amount'
  | 'description'
  | 'accountId'
  | 'categoryId'
  | 'contactId'
  | 'comments'
> & {
  date?: Date;
  relevantDate?: Date | null;
  attachmentsIds?: string[];
  labelsIds?: string[];
  classification?: ClassificationLabels[];
  paymentDetails?:
    | (Omit<PaymentDetails, 'boleto'> & {
        boletoId?: string | null;
        boleto?: { digitableLine?: string | null };
      })
    | null;
};

export type BillPayable = t.TypeOf<typeof BillPayableDecoder>;
export type RecurringBill = t.TypeOf<typeof RecurringBillDecoder>;

export type BillPayableLastApproval = t.TypeOf<
  typeof BillPayableLastApprovalDecoder
>;

export type BillPayableAssignment = t.TypeOf<
  typeof BillPayableAssignmentDecoder
>;

export type BillGetParams = {
  organizationId?: string;
  companyId?: string;
  billId: string;
  includingDeleted?: boolean;
};

export type BillsGetParams = {
  organizationId?: string;
  companyId?: string;
  order?: 'asc' | 'desc';
  status?: BillStatus;
};

export type BillsPendingMyApprovalGetParams = {
  organizationId?: string;
  companyId?: string;
};

export type BillPostParams = EditableParams & {
  organizationId?: string;
  companyId?: string;
  inboxItemId?: string;
  classifications?: ClassificationLabels[];
};

export type BillPatchParams = EditableParams & {
  billId: string;
  organizationId?: string;
  companyId?: string;
  inboxItemId?: string;
};

export type BillsDeleteManyParams = {
  organizationId?: string;
  companyId?: string;
  billIds: string[];
};

export type RecurringBillPostParams = EditableParams & {
  organizationId?: string;
  companyId?: string;
  inboxItemsIds?: string[];
  quantity?: number | null;
  frequencyInterval?: number | null;
  frequencyUnit?: string | null;
};

export type CreateFinancialFromBillParams = {
  organizationId?: string;
  companyId?: string;
  billId?: string;
} & ManualConciliateFormProps;

export type BillRecurrencesDeleteParams = {
  organizationId?: string;
  companyId?: string;
  billId?: string;
};
