import { useMemo } from 'react';

import {
  ArrowRightIcon,
  BankIcon,
  FileAttachmentIcon,
  LinkIcon,
  MailIcon,
  MessageChatCircleIcon,
  UploadCloudIcon,
} from 'src/mui/_icons';
import { Box, Button, Link } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { CopyButton, Tooltip, Typography } from 'src/mui';

import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import { e164Format } from 'src/libs/finbits/Organization/PhoneNumber';
import {
  useCompany,
  useCompanyParams,
} from 'src/libs/finbits/Organization/Companies';
import { useAccounts } from 'src/libs/finbits/Bank/Accounts';
import { ONE_HOUR_IN_MS } from 'src/libs/finbits/Time';
import { BTG_ROUTING_NUMBER, BTG_SIGNUP_URL } from 'src/libs/finbits/Bank';

import BTGConnectionRedirectButton from 'src/features/open-banking/BTGConnectionRedirectButton';

import DotIcon from './DotIcon';
import WhatsAppInfo from './WhatsAppInfo';
import CreateUploadButton from './CreateUploadButton';
import InformationCard from './InformationCard';
import style from './Informations.module.scss';

const phoneNumber = import.meta.env.VITE_WHATSAPP_NUMBER ?? '';

export default function Informations() {
  const { organizationId, companyId } = useCompanyParams();
  const { company } = useCompany(
    { organizationId, companyId },
    { staleTime: ONE_HOUR_IN_MS }
  );
  const email = company?.inboxEmailAddress ?? '';
  const url = `${window.location.origin}/p/${company?.publicId}`;

  const { accounts } = useAccounts({ companyId, organizationId });

  const hasBTGTransferConnection = useMemo(
    () => accounts.some((acc) => acc.btgConnection?.scopeDda),
    [accounts]
  );

  const hasBTGAccount = useMemo(
    () => accounts.some((acc) => acc.routingNumber === BTG_ROUTING_NUMBER),
    [accounts]
  );

  const canAuthenticateBTG = useAllowedPermission({
    resource: 'btgAuthentication',
    action: 'show',
    scope: 'organization',
  });

  function openWhatsApp() {
    window.open(`https://wa.me/${e164Format(phoneNumber)}`, '_blank');
  }

  return (
    <Box className={style.informations}>
      <Typography variant="subtitle2" component="h1" fontWeight={600}>
        Nenhuma entrada selecionada
      </Typography>
      <Typography className={style.textSecondary}>
        Selecione uma entrada ou verifique as formas de receber entradas.
      </Typography>

      <Grid container spacing={6}>
        <InformationCard
          icon={<MessageChatCircleIcon color="inherit" />}
          title="Envie por WhatsApp"
          content={
            <>
              <Typography className={style.contentCard}>
                O número <b>(11) 4040-7770</b> é o WhatsApp do Finbits. Siga os
                passos abaixo para enviar seus recibos, notas, comprovantes para
                a plataforma diretamente por lá.
              </Typography>

              <WhatsAppInfo />
            </>
          }
          actions={
            <>
              <CopyButton
                clipboard={e164Format(phoneNumber) ?? ''}
                title="Número copiado"
                className={style.secondaryButton}
              >
                Copiar Número
              </CopyButton>

              <Button
                size="small"
                className={style.whatsAppButton}
                onClick={openWhatsApp}
              >
                Abrir no WhatsApp
              </Button>
            </>
          }
        />

        <InformationCard
          icon={<MailIcon color="inherit" />}
          title="E-mail Exclusivo"
          content={
            <>
              <Typography
                className={style.contentCard}
                color="text.secondary"
                ellipsis
              >
                Você pode receber suas entradas através do e-mail exclusivo{' '}
                <Link href={`mailto:${email}`}>{email}</Link>
              </Typography>
              <Typography
                className={style.contentCard}
                color="text.secondary"
                ellipsis
              >
                Os anexos enviados para este email precisa ter o tamanho igual
                ou menor a
                <Tooltip
                  title="Se precisar enviar um conjunto de anexos com tamanho maior, sugerimos fazer mais de um envio de email com um conjunto de anexos menor a 20 MB."
                  className={style.tooltipHover}
                >
                  <b>20 MB</b>
                </Tooltip>
                no total.
              </Typography>
            </>
          }
          actions={
            <CopyButton
              clipboard={email}
              title="E-mail copiado"
              className={style.secondaryButton}
            >
              Copiar E-mail
            </CopyButton>
          }
        />

        <InformationCard
          icon={<FileAttachmentIcon color="inherit" />}
          title="Formulário Externo"
          content={
            <Typography className={style.contentCard}>
              Mande este link{' '}
              <Link color="text.secondary" href={url} target="_blank">
                {url}
              </Link>{' '}
              para quem você deseja que envie entradas totalmente
              contextualizadas.
            </Typography>
          }
          actions={
            <CopyButton
              clipboard={url}
              title="Link copiado"
              className={style.secondaryButton}
            >
              Copiar Link
            </CopyButton>
          }
        />

        <InformationCard
          icon={<BankIcon color="inherit" />}
          title="DDA via Banco BTG"
          content={
            <>
              <Typography className={style.contentCard}>
                Gerencie pagamentos e visualize seus boletos conectando uma
                conta BTG Empresas na Finbits e habilitando o DDA.
              </Typography>

              {!hasBTGAccount && canAuthenticateBTG && (
                <Button
                  size="small"
                  href={BTG_SIGNUP_URL}
                  target="_blank"
                  rel="noreferrer"
                  className={style.link}
                  endIcon={<ArrowRightIcon />}
                >
                  Abra sua conta no BTG Empresas
                </Button>
              )}
            </>
          }
          actions={
            hasBTGAccount ? (
              <Typography
                gutterBottom
                variant="subtitle1"
                className={style.connection}
              >
                {hasBTGTransferConnection ? (
                  <>
                    <DotIcon fontSize="large" color="success" />
                    DDA Ativado
                  </>
                ) : (
                  <>
                    <DotIcon fontSize="large" color="warning" />
                    DDA Desativado
                  </>
                )}
              </Typography>
            ) : (
              <BTGConnectionRedirectButton
                size="small"
                startIcon={<LinkIcon />}
                className={style.connection}
              >
                Conectar Conta
              </BTGConnectionRedirectButton>
            )
          }
        />

        <InformationCard
          icon={<UploadCloudIcon color="inherit" />}
          title="Upload Manual"
          content={
            <Typography className={style.contentCard}>
              Você pode subir o seu anexo manualmente clicando no link abaixo.
            </Typography>
          }
          actions={<CreateUploadButton />}
        />
      </Grid>
    </Box>
  );
}
