import type { Components } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiInput({
  typography,
}: ThemeConfig): Components['MuiInput'] {
  return {
    defaultProps: { disableUnderline: true },
    styleOverrides: {
      input: typography.body1,
    },
  };
}
