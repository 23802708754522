export enum SuggestionField {
  CATEGORY_ID = 'categoryId',
  AMOUNT = 'amount',
  COMMENTS = 'comments',
  DATE = 'date',
  RELEVANT_DATE = 'relevantDate',
  CONTACT_ID = 'contactId',
  DESCRIPTION = 'description',
  PAYMENTS_DETAILS = 'paymentDetails.paymentDetails',
  PAYMENT_DETAILS_PAYMENT_METHOD = 'paymentDetails.paymentMethod',
  PAYMENT_DETAILS_DIGITABLE_LINE = 'paymentDetails.digitableLine',
  PAYMENT_DETAILS_PIX_KEY = 'paymentDetails.pixKey',
  PAYMENT_DETAILS_PIX_TYPE = 'paymentDetails.pixType',
  PAYMENT_DETAILS_ACCOUNT_DOCUMENT = 'paymentDetails.accountDocument',
  PAYMENT_DETAILS_ACCOUNT_NUMBER = 'paymentDetails.accountNumber',
  PAYMENT_DETAILS_ACCOUNT_TYPE = 'paymentDetails.accountType',
  PAYMENT_DETAILS_BRANCH_NUMBER = 'paymentDetails.branchNumber',
  PAYMENT_DETAILS_ROUTING_NUMBER = 'paymentDetails.routingNumber',
  PAYMENT_DETAILS_BANK_NAME = 'paymentDetails.bankName',
  PAYMENT_DETAILS_PAYEE_NAME = 'paymentDetails.payeeName',
}
