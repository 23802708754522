import type { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { ClockIcon } from 'src/mui/_icons';

import { Tooltip, Typography } from 'src/mui';

import { EntryType } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { isOverDue } from 'src/libs/finbits/Management/FinancialStatements/Entries';

import styles from './DateWithOverdueIcon.module.scss';

export default function DateWithOverdueIcon({
  row,
  rowNode,
  formattedValue,
}: Partial<GridRenderCellParams>) {
  if (rowNode?.type === 'group') {
    return <Typography>{formattedValue}</Typography>;
  }

  const isBill = row.entryType === EntryType.BILL;
  const isOverdue = isOverDue(row?.date);

  return (
    <>
      <Typography ellipsis className={styles.date}>
        {formattedValue}
      </Typography>
      {isOverdue && isBill && (
        <Tooltip title="Atrasado" enterDelay={100}>
          <ClockIcon className={styles.icon} />
        </Tooltip>
      )}
    </>
  );
}
