import t from 'zod';

import { BillPayableApprovalType } from 'src/libs/finbits/Bills/types';
import { UserDecoder } from 'src/libs/finbits/Organization/Users/types';
import { CategoryDecoder } from 'src/libs/finbits/Management/FinancialEntries/Categories/types';
import { LabelDecoder } from 'src/libs/finbits/Labels/types';

import type { ApprovalRulesFormResolver } from './validations';

export enum ApprovalRuleConditionType {
  ONE_OF = 'one_of',
  GREATER_THAN_OR_EQUAL_TO = 'greater_than_or_equal_to',
  LESS_THAN_OR_EQUAL_TO = 'less_than_or_equal_to',
}

export enum ApprovalRuleConditionField {
  AMOUNT = 'amount',
  LABEL = 'label',
  CATEGORY = 'category',
}

const ConditionDecoder = t.object({
  id: t.string(),
  field: t.nativeEnum(ApprovalRuleConditionField),
  type: t.nativeEnum(ApprovalRuleConditionType),
  amountValue: t
    .object({
      amount: t.number(),
      id: t.string(),
    })
    .nullish(),
  labelsValues: t
    .object({
      id: t.string(),
      label: LabelDecoder,
    })
    .array()
    .nullish(),
  categoriesValues: t
    .object({
      category: CategoryDecoder,
      id: t.string(),
    })
    .array()
    .nullish(),
});

export type ApprovalRuleCondition = t.TypeOf<typeof ConditionDecoder>;

export const ApprovalRuleDecoder = t.object({
  id: t.string(),
  approvalType: t.nativeEnum(BillPayableApprovalType),
  description: t.string(),
  position: t.number(),
  defaultRule: t.boolean(),
  assignees: UserDecoder.array(),
  conditions: ConditionDecoder.array(),
});

export type ApprovalRule = t.TypeOf<typeof ApprovalRuleDecoder>;

export type GetApprovalRulesParams = {
  organizationId?: string;
  companyId?: string;
  pageParam?: string;
};

export type ApprovalRuleFormParams = t.TypeOf<typeof ApprovalRulesFormResolver>;

export type ApprovalRulesPostParams = {
  organizationId?: string;
  companyId?: string;
  description: string;
  conditions: {
    id?: string;
    field: ApprovalRuleConditionField | string;
    type: ApprovalRuleConditionType;
    amountValue?: { amount: number } | null;
    labelsValues?: { labelId: string }[];
    categoriesValues?: { categoryId: string }[];
  }[];
  assigneesIds: string[];
  approvalType: BillPayableApprovalType;
};

export type ApprovalRulePatchParams = {
  approvalRuleId?: string;
} & ApprovalRulesPostParams;

export type ApprovalRulesDeleteParams = {
  organizationId?: string;
  companyId?: string;
  approvalRuleId?: string;
};
