import type { Components } from '@mui/material';
import { checkboxClasses } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiCheckbox({
  palette,
}: ThemeConfig): Components['MuiCheckbox'] {
  const checkboxColor =
    palette.mode === 'light' ? palette?.grey?.[300] : palette?.grey?.[400];

  const checkboxCheckedColor =
    palette.mode === 'light' ? palette?.grey?.[900] : palette?.grey?.[200];

  return {
    styleOverrides: {
      root: {
        color: checkboxColor,
        [`&.${checkboxClasses.checked}`]: {
          color: checkboxCheckedColor,
        },
        [`&.${checkboxClasses.indeterminate}`]: {
          color: checkboxCheckedColor,
        },
      },
    },
  };
}
