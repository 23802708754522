import type { ButtonProps } from 'antd';
import { Button, Tooltip } from 'antd';
import { DislikeOutlined } from '@ant-design/icons';

import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import type { BillPayable } from 'src/libs/finbits/Bills/types';

import useReprovedAction from 'src/features/bills-to-pay/PaymentsFlow/useReprovedAction';

type Props = {
  selectedBills: BillPayable[];
  onSuccess?: () => void;
} & ButtonProps;

export default function ReprovedButton({
  selectedBills,
  onSuccess,
  ...rest
}: Props) {
  const { sendToReproved } = useReprovedAction({
    selecteds: selectedBills,
    onSuccess,
  });

  const isEmpty = selectedBills.length === 0;

  return (
    <WithAllowedPermission
      permissions={{ resource: 'reprovedPayments', action: 'create' }}
    >
      <Tooltip title="Reprovar" placement="bottom">
        <Button
          icon={<DislikeOutlined />}
          aria-label="Reprovar"
          disabled={isEmpty}
          onClick={() => sendToReproved()}
          {...rest}
        />
      </Tooltip>
    </WithAllowedPermission>
  );
}
