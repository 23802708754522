import { Typography } from '@mui/material';

import type { PaymentDetails } from 'src/libs/finbits/Payments/types';
import type { PaymentMethod } from 'src/libs/finbits/PaymentMethods/types';
import { PAYMENT_METHOD_DICTIONARY } from 'src/libs/finbits/PaymentMethods/translations';

import BoletoDetails from 'src/features/inbox-items/InboxItemDetails/BoletoDetails';

import PaymentDetailsInfo from './PaymentDetailsInfo';
import PixDetails from './PixDetails';
import WireTransferDetails from './WireTransferDetails';
import PixQrCodeDetailsInfo from './PixQrCodeDetailsInfo';

type Props = {
  paymentDetails?: PaymentDetails | null;
};

function getPaymentDetailsComponent(
  paymentDetails: PaymentDetails
): Record<PaymentMethod, JSX.Element> {
  return {
    boleto: <BoletoDetails boleto={paymentDetails.boleto} />,
    credit_card: <PaymentDetailsInfo paymentDetails={paymentDetails} />,
    direct_debit: <PaymentDetailsInfo paymentDetails={paymentDetails} />,
    pix: <PixDetails paymentDetails={paymentDetails} />,
    wire_transfer: <WireTransferDetails paymentDetails={paymentDetails} />,
    pix_qr_code: <PixQrCodeDetailsInfo paymentDetails={paymentDetails} />,
  };
}

export default function PaymentMethodDetails({ paymentDetails }: Props) {
  const paymentMethod = paymentDetails?.paymentMethod;

  if (!paymentMethod) {
    return (
      <>
        <Typography fontWeight={600} color="text.primary" sx={{ mb: 2 }}>
          Meio de pagamento - Não informado
        </Typography>
        <Typography>Sem detalhes de pagamento</Typography>
      </>
    );
  }

  return (
    <>
      <Typography fontWeight={600} color="text.primary" sx={{ mb: 2 }}>
        Meio de pagamento - {PAYMENT_METHOD_DICTIONARY[paymentMethod]}
      </Typography>
      {getPaymentDetailsComponent(paymentDetails)[paymentMethod]}
    </>
  );
}
