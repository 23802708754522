import type {
  GridCellParams,
  GridColDef,
  GridFilterItem,
  GridFilterOperator,
  GridGroupingValueGetterParams,
  GridValidRowModel,
  GridValueFormatterParams,
  GridValueGetterParams,
  GridValueSetterParams,
} from '@mui/x-data-grid-premium';
import { singleSelectColumn } from 'src/mui/_scss';
import FilterWrapper from 'src/mui/_scss/Table/Columns/singleSelectColumn/FilterWrapper';

import type { Label } from 'src/libs/finbits/Labels/types';
import type { Classification } from 'src/libs/finbits/Classifications/types';

import LabelsAutocomplete from 'src/features/entries/EntriesDataGrid/columns/classificationColumn/LabelsAutocomplete/LabelsAutocomplete';
import type { ColumnProps } from 'src/features/entries/EntriesDataGrid/columns/types';

import ClassificationList from './ClassificationList';

export type RowModelClassification = {
  id: string;
  labels: Label[];
};

type ClassificationLabels = {
  classifications: RowModelClassification[];
};

export type ClassificationsUpdatedParams = {
  classificationId: string;
  labelsIds: string[];
};

type Props<T extends GridValidRowModel> = {
  classifications: Classification[];
  columnProps?: ColumnProps<T>;
};

export function hasChangeValidator(
  newValue: ClassificationsUpdatedParams[],
  oldValues: RowModelClassification[]
) {
  try {
    const newLabelIds = newValue[0].labelsIds;
    const classificationId = newValue[0].classificationId;

    const oldLabelIds = oldValues.flatMap((oldValue) => {
      if (oldValue.id === classificationId) {
        return oldValue.labels.map(({ id }: Label) => id);
      }

      return [];
    });

    const hasChange =
      JSON.stringify(newLabelIds.sort()) !== JSON.stringify(oldLabelIds.sort());

    return hasChange;
  } catch {
    return false;
  }
}

function valueSetterClassification<T extends ClassificationLabels>(
  classificationId: string,
  { value, row }: GridValueSetterParams<T>
) {
  const labels = Array.isArray(value) ? value : [];

  const updatedClassification: ClassificationsUpdatedParams[] = [
    { classificationId, labelsIds: labels.map(({ id }: Label) => id) },
  ];

  const classification = row.classifications.filter(
    (classification) => classification.id === classificationId
  );
  return {
    ...row,
    [`classifications:${classificationId}`]: { ...classification, labels },
    hasChangeValidator,
    updated: { classifications: updatedClassification },
  };
}

function getClassificationLabels<T extends ClassificationLabels>(
  classificationId: string,
  row: T
) {
  return (
    row.classifications?.find(({ id }) => id === classificationId)?.labels || []
  );
}

function parserLabelsToSort(labels: Label[] | string) {
  if (typeof labels === 'string') return labels;

  const labelsNames =
    labels
      ?.map(({ name }: Label) => name)
      .sort()
      .join() ?? '';

  return labelsNames;
}

export function classificationsColumn<
  T extends GridValidRowModel & ClassificationLabels
>({ classifications, columnProps }: Props<T>): GridColDef<T>[] {
  return classifications.map((classification) => {
    const filterOperators: GridFilterOperator[] = [
      {
        label: 'é',
        value: 'is',
        getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
          if (!filterItem.field || !filterItem.value || !filterItem.operator) {
            return null;
          }

          return (params: GridCellParams<T, Label[]>): boolean => {
            return (
              params.value?.some((label) => label.id === filterItem.value) ??
              false
            );
          };
        },
        InputComponentProps: {
          Component: LabelsAutocomplete,
          classification: classification,
          multiple: false,
        },
        InputComponent: FilterWrapper,
      },
      {
        label: 'não é',
        value: 'not',
        getApplyFilterFn: (filterItem: GridFilterItem, _column: GridColDef) => {
          if (!filterItem.field || !filterItem.value || !filterItem.operator) {
            return null;
          }

          return (params: GridCellParams<T, Label[]>): boolean => {
            return (
              params.value?.every((label) => label.id !== filterItem.value) ??
              false
            );
          };
        },
        InputComponentProps: {
          Component: LabelsAutocomplete,
          classification: classification,
          multiple: false,
        },
        InputComponent: FilterWrapper,
      },
    ];

    return singleSelectColumn({
      columnProps: {
        filterOperators,
        minWidth: 100,
        flex: 2,
        editable: true,
        aggregable: false,
        headerName: classification.name,
        renderCell: (props) => <ClassificationList {...props} />,
        renderEditCell: (params) => (
          <LabelsAutocomplete
            {...params}
            classification={classification}
            bordered={false}
          />
        ),
        valueFormatter: (params: GridValueFormatterParams<Label[]>) => {
          return params?.value.map((label) => label.name);
        },
        valueGetter: (params: GridValueGetterParams) =>
          getClassificationLabels<T>(classification.id, params.row),
        valueSetter: (params) =>
          valueSetterClassification<T>(classification.id, params),
        getOptionValue: (value: any) => value?.id,
        getOptionLabel: (value: any) => value?.name,
        groupingValueGetter: (params: GridGroupingValueGetterParams<T>) => {
          const labels = getClassificationLabels<T>(
            classification.id,
            params.row
          );

          if (labels.length === 0) return 'sem classificação';

          return labels.map((label: Label) => label.name).join(' ,');
        },
        sortComparator: (
          labelsA: Label[] | string,
          labelsB: Label[] | string
        ) => {
          const labelsANames = parserLabelsToSort(labelsA);
          const labelsBNames = parserLabelsToSort(labelsB);

          return labelsANames.localeCompare(labelsBNames);
        },
        ...columnProps,
        field: `classifications:${classification.id}`,
      },
    });
  });
}
