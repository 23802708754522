import { MaterialDesignContent } from 'notistack';
import { styled } from '@mui/material';
import { AlertCircleIcon, AlertTriangleIcon, CheckIcon } from 'src/mui/_icons';

import { info } from './snackbar.sx';

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => {
    const styled = {
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor:
        theme.palette.mode === 'light'
          ? theme.palette.common.white
          : theme.palette.grey[900],
      width: '400px',
      padding: '0 8px',
      borderRadius: '12px',
      boxShadow: theme.shadows[4],
    };

    return {
      '&.notistack-MuiContent-success': styled,
      '&.notistack-MuiContent-info': styled,
      '&.notistack-MuiContent-warning': styled,
      '&.notistack-MuiContent-error': styled,
    };
  }
);

export const globalStyledComponentsSnackbar = {
  success: StyledMaterialDesignContent,
  info: StyledMaterialDesignContent,
  warning: StyledMaterialDesignContent,
  error: StyledMaterialDesignContent,
};

export const globalIconVariant = {
  success: <CheckIcon sx={{ fontSize: '16px' }} />,
  info: <AlertCircleIcon sx={{ fontSize: '16px', ...info }} />,
  warning: <AlertTriangleIcon sx={{ fontSize: '16px' }} />,
  error: <AlertCircleIcon sx={{ fontSize: '16px' }} />,
};
