import { useState } from 'react';

import {
  Box,
  Button,
  Step,
  StepButton,
  StepLabel,
  Stepper as StepperMui,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';

import { snackbar } from 'src/mui';

import { Stepper, useStepper } from 'src/ui';

import { useCreateOrganization } from 'src/libs/finbits/Organization';
import type { ErrorsObject } from 'src/libs/finbits/Form';
import { isStructuredFormError } from 'src/libs/finbits/Form';
import type { UserInfo } from 'src/libs/finbits/Organization/types';
import type { CompanyCreateParams } from 'src/libs/finbits/Organization/Companies/types';

import CompaniesList from 'src/features/sign-up/CompaniesList';
import CompanyForm from 'src/features/sign-up/CompanyForm';
import UserForm from 'src/features/sign-up/UserForm';
import LoginLink from 'src/features/sign-up/LoginLink';

import { title } from './Organization.sx';

type ErrorState = {
  company?: { name: string; errors: string[] }[];
  companies?: ErrorsObject;
  users?: ErrorsObject;
};

type SignUpErrors = {
  users?: [
    {
      name: string[];
      email: string[];
      phone: { number: string[] };
      authentication: { password: string[] };
    }
  ];
};

function mapUserErrors(errors: SignUpErrors) {
  const errorsObj: ErrorsObject = {};
  const name = errors?.users?.[0]?.name;
  const email = errors?.users?.[0]?.email;
  const phone = errors?.users?.[0]?.phone;
  const password = errors?.users?.[0]?.authentication;

  if (name) {
    errorsObj.name = name;
  }

  if (email) {
    errorsObj.email = email;
  }

  if (phone) {
    errorsObj.phone = phone;
  }
  if (password) {
    errorsObj.password = password?.password;
  }

  return errorsObj;
}

function Organization() {
  const [user, setUser] = useState<UserInfo | undefined>(undefined);
  const [isFormEdited, setIsFormEdited] = useState(false);
  const [companies, setCompanies] = useState<CompanyCreateParams[]>([]);
  const [errors, setErrors] = useState<ErrorState | undefined>(undefined);
  const [isAddingCompany, setIsAddingCompany] = useState(false);

  const navigate = useNavigate();

  const store = useStepper();
  const { goNextStep, goToStep, currentStep } = store;

  const { createOrganization, isLoading: isSavingOrganization } =
    useCreateOrganization();

  const hasCompanies = companies.length > 0;
  const initialCompany =
    hasCompanies && !isAddingCompany
      ? companies[companies.length - 1]
      : undefined;

  function onSubmitUser(values: UserInfo) {
    setUser(values);
    setErrors(undefined);
    setIsFormEdited(false);

    if (hasCompanies) {
      return goToStep(3);
    }

    return goNextStep();
  }

  function onSubmitCompany(values: CompanyCreateParams) {
    if (isAddingCompany) {
      const alreadyExist = companies.find(
        (company) => company.document === values.document
      );
      if (alreadyExist) {
        return setErrors({
          company: [
            {
              name: 'document',
              errors: ['Você já cadastrou esta empresa'],
            },
          ],
        });
      }

      setCompanies((prevCompanies) => {
        return [
          ...prevCompanies,
          {
            ...values,
          },
        ];
      });
    } else {
      setCompanies((prevCompanies) => {
        const companiesWithOutDuplications = prevCompanies.filter(
          (company) => !(company.document === values.document)
        );

        return [
          ...companiesWithOutDuplications,
          {
            ...values,
          },
        ];
      });
    }

    setErrors(undefined);
    setIsAddingCompany(false);
    setIsFormEdited(false);
    goNextStep();
  }

  function onClickRemove(document: string) {
    setCompanies((prevCompanies) => {
      const newCompanies = prevCompanies.filter(
        (company) => company.document !== document
      );
      if (newCompanies.length === 0) {
        goToStep(2);
      }
      return newCompanies;
    });
  }

  function onClickAdd() {
    setErrors(undefined);
    setIsAddingCompany(true);
    goToStep(2);
  }

  function onClickSkip() {
    setIsAddingCompany(false);
    goToStep(3);
  }

  function submitSignUp() {
    if (!user) return;

    const params = {
      companies,
      user,
    };

    createOrganization(params, {
      onSuccess: () => {
        snackbar({
          variant: 'success',
          message:
            'Sua conta foi criada com sucesso! Faça o login para entrar.',
        });

        navigate('/login');
      },

      onError: ({ response }) => {
        if (response?.status === 422 && isStructuredFormError(response?.data)) {
          if (response.data.errors?.companies) {
            setErrors({
              companies: companies.map((company, index) => ({
                document: company.document,
                errors: response.data.errors?.companies[index],
              })),
            });
          }

          if (response.data.errors?.users) {
            goToStep(0);
            setErrors({
              users: mapUserErrors(response.data.errors),
            });
          }
        }

        snackbar({
          variant: 'error',
          message: 'Não foi possível criar a conta.',
        });
      },
    });
  }

  function onChangeSteps(current: number) {
    goToStep(current + 1);
    setIsAddingCompany(false);
    setErrors((prevErrors) => ({
      ...prevErrors,
      company: undefined,
    }));
  }

  return (
    <div>
      <StepperMui nonLinear activeStep={currentStep - 1} sx={{ mt: 8 }}>
        <Step key="user">
          <StepButton
            color="inherit"
            onClick={() => onChangeSteps(0)}
            disabled={isFormEdited}
          >
            <StepLabel error={!!errors?.users}>Usuário</StepLabel>
          </StepButton>
        </Step>
        <Step key="company">
          <StepButton
            color="inherit"
            onClick={() => onChangeSteps(1)}
            disabled={!user || isFormEdited}
          >
            <StepLabel>Empresa</StepLabel>
          </StepButton>
        </Step>
        <Step key="resume">
          <StepButton
            color="inherit"
            onClick={() => onChangeSteps(2)}
            disabled={!hasCompanies || isFormEdited}
          >
            <StepLabel error={hasCompanies && !!errors?.companies}>
              Resumo
            </StepLabel>
          </StepButton>
        </Step>
      </StepperMui>

      <Stepper store={store} showContinue={false} showGoBack={false}>
        <Stepper.Step>
          <Box sx={{ width: '100%' }}>
            <Typography variant="h6" component="h2" sx={title}>
              Olá! Como você se chama?
            </Typography>
            <UserForm
              submitErrors={errors?.users}
              initialValues={user}
              onSubmit={onSubmitUser}
              setIsFormEdited={setIsFormEdited}
            />
            <LoginLink />
          </Box>
        </Stepper.Step>

        <Stepper.Step>
          <Box sx={{ width: '100%' }}>
            <Typography variant="h6" component="h2" sx={title}>
              Cadastre sua(s) empresa(s)
            </Typography>
            <CompanyForm
              submitErrors={errors?.company}
              initialValues={initialCompany}
              onSubmit={onSubmitCompany}
              setIsFormEdited={setIsFormEdited}
            />
            {hasCompanies && (
              <Button
                fullWidth
                variant="text"
                onClick={onClickSkip}
                sx={{ mt: 4 }}
              >
                Pular
              </Button>
            )}
            <LoginLink />
          </Box>
        </Stepper.Step>

        <Stepper.Step>
          <Typography variant="h6" component="h2" sx={title}>
            Revise seus dados para finalizar!
          </Typography>
          <CompaniesList
            companies={companies}
            onClickAdd={onClickAdd}
            onClickFinish={submitSignUp}
            onClickRemove={onClickRemove}
            isSaving={isSavingOrganization}
            submitErrors={errors?.companies}
          />
        </Stepper.Step>
      </Stepper>
    </div>
  );
}

export default Organization;
