import { AppBar, Button, Stack, Toolbar } from '@mui/material';
import { useKBar } from 'kbar';
import { Link } from 'react-router-dom';

import { KeyboardShortcut } from 'src/mui';

import { Logo } from 'src/ui';

import { useProfile } from 'src/libs/finbits/Organization/Users';
import MessageBirdChat from 'src/libs/messageBird';

import Navigation from './Navigation';
import UserMenu from './UserMenu';
import SettingsMenu from './SettingsMenu';
import NotificationsMenu from './NotificationsMenu';
import styles from './TopMenu.module.scss';

export default function TopMenu() {
  const { user } = useProfile();

  const { query } = useKBar();

  const hasCompany = Boolean(user?.companies?.length);

  return (
    <AppBar position="sticky" color="inherit" className={styles.container}>
      <Stack px={11}>
        <Toolbar disableGutters className={styles.toolbar}>
          <Link
            className={styles.logoLink}
            to="/"
            aria-label="Início"
            role="link"
          >
            <Logo />
          </Link>

          {hasCompany && <Navigation />}

          <Stack direction="row" alignItems="center" gap={4} ml="auto">
            <Button
              variant="text"
              onClick={query.toggle}
              endIcon={<KeyboardShortcut macOS="cmd + k" windows="ctrl + k" />}
              sx={{ display: { xs: 'none', lg: 'flex' } }}
            >
              Atalhos
            </Button>

            {hasCompany && (
              <Stack direction="row" gap={1}>
                <MessageBirdChat />
                <NotificationsMenu />
                <SettingsMenu />
              </Stack>
            )}
            <UserMenu />
          </Stack>
        </Toolbar>
      </Stack>
    </AppBar>
  );
}
