import { Stack } from '@mui/material';
import { ArrowNarrowDownIcon, ArrowUpIcon } from 'src/mui/_icons';

import type { ApprovalRule } from 'src/libs/finbits/ApprovalRules/types';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import ActionButton from '../ActionButton';

type Props = {
  approvalRule: ApprovalRule;
  isFirst?: boolean;
  isBeforeDefaultRule?: boolean;
};

export default function ChangePositionActions({
  approvalRule: { defaultRule },
  isFirst = false,
  isBeforeDefaultRule = false,
}: Props) {
  const { isEnabled: isActionEnabled } = useExternalFeatureFlag(
    Feature.APPROVAL_RULES_PAGE_V3
  );

  if (!isActionEnabled) return null;

  const tooltipText = 'A prioridade desta regra não pode ser alterada.';

  return (
    <Stack direction="row" alignItems="center">
      <ActionButton
        permissions={{ resource: 'approvalsRules', action: 'update_position' }}
        icon={<ArrowUpIcon />}
        disabled={defaultRule || isFirst}
        tooltip={defaultRule ? tooltipText : 'Subir prioridade'}
        label="Subir prioridade"
      />
      <ActionButton
        permissions={{ resource: 'approvalsRules', action: 'update_position' }}
        icon={<ArrowNarrowDownIcon />}
        disabled={defaultRule || isBeforeDefaultRule}
        tooltip={defaultRule ? tooltipText : 'Descer prioridade'}
        label="Descer prioridade"
      />
    </Stack>
  );
}
