import type {
  GridCellParams,
  GridColDef,
  GridFilterItem,
  GridFilterOperator,
  GridValidRowModel,
} from '@mui/x-data-grid-premium';
import { GridFilterInputValue } from '@mui/x-data-grid-premium';

import type { Attachment as AttachmentType } from 'src/libs/finbits/Management/Attachments/types';
import { pluralize } from 'src/libs/finbits/Pluralize';

import Attachment from 'src/features/entries/EntriesDataGrid/columns/attachmentColumn/Attachment';
import type { ColumnProps } from 'src/features/entries/EntriesDataGrid/columns/types';

const filterOperators: GridFilterOperator[] = [
  {
    label: 'quantidade igual a',
    value: 'equals',
    getApplyFilterFn: (filterItem: GridFilterItem, _column: GridColDef) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }

      return (params: GridCellParams<any, AttachmentType[]>): boolean => {
        const filterNumberOnly = filterItem.value.replace(/\D/g, '');

        return params.value?.length === Number(filterNumberOnly);
      };
    },
    InputComponent: GridFilterInputValue,
  },
  {
    label: 'quantidade diferente de',
    value: 'notEquals',
    getApplyFilterFn: (filterItem: GridFilterItem, _column: GridColDef) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }

      return (params: GridCellParams<any, AttachmentType[]>): boolean => {
        const filterNumberOnly = filterItem.value.replace(/\D/g, '');

        return params.value?.length !== Number(filterNumberOnly);
      };
    },
    InputComponent: GridFilterInputValue,
  },
  {
    label: 'contem anexo',
    value: 'haveAttachments',
    getApplyFilterFn: (filterItem: GridFilterItem, _column: GridColDef) => {
      if (!filterItem.field || !filterItem.operator) {
        return null;
      }

      return (params: GridCellParams<any, AttachmentType[]>): boolean => {
        return Boolean(params.value?.length);
      };
    },
    requiresFilterValue: false,
  },
  {
    label: 'não contem anexo',
    value: 'dontHaveAttachments',
    getApplyFilterFn: (filterItem: GridFilterItem, _column: GridColDef) => {
      if (!filterItem.field || !filterItem.operator) {
        return null;
      }

      return (params: GridCellParams<any, AttachmentType[]>): boolean => {
        return !Boolean(params.value?.length);
      };
    },
    requiresFilterValue: false,
  },
  {
    label: 'contem nota fiscal',
    value: 'haveNotaFiscal',
    getApplyFilterFn: (filterItem: GridFilterItem, _column: GridColDef) => {
      if (!filterItem.field || !filterItem.operator) {
        return null;
      }

      return (
        params: GridCellParams<{ notaFiscalId?: string }, AttachmentType[]>
      ): boolean => {
        return Boolean(params.row?.notaFiscalId);
      };
    },
    requiresFilterValue: false,
  },
  {
    label: 'não contem nota fiscal',
    value: 'dontHaveNotaFiscal',
    getApplyFilterFn: (filterItem: GridFilterItem, _column: GridColDef) => {
      if (!filterItem.field || !filterItem.operator) {
        return null;
      }

      return (
        params: GridCellParams<{ notaFiscalId?: string }, AttachmentType[]>
      ): boolean => {
        return !Boolean(params.row?.notaFiscalId);
      };
    },
    requiresFilterValue: false,
  },
];

export function attachmentColumn<T extends GridValidRowModel>(
  columnProps?: ColumnProps<T>
): GridColDef<T> {
  return {
    filterOperators,
    headerName: 'Anexos',
    field: 'attachments',
    filterable: true,
    editable: false,
    renderCell: (props) => <Attachment {...props} />,
    valueFormatter: (params) => {
      const count = params?.value?.length ?? 0;
      return `${count} ${pluralize(count, 'Anexo', 'Anexos')}`;
    },
    sortComparator: (accA, accB) => {
      const lengthA = accA?.length ?? 0;
      const lengthB = accB?.length ?? 0;

      return lengthA - lengthB;
    },
    minWidth: 65,
    groupable: false,
    ...columnProps,
  };
}
