import CountUp from 'react-countup';
import { Stack } from '@mui/material';

import { Typography } from 'src/mui';

import type { BillPayable } from 'src/libs/finbits/Bills/types';

type Props = {
  bills: BillPayable[];
  description?: string;
};

export default function TotalSum({
  bills,
  description = 'Total a pagar',
}: Props) {
  const total = bills.reduce((acc, bill) => {
    return acc + bill.amount;
  }, 0);

  return (
    <Stack alignItems="end" flexGrow="1" order="2">
      <Typography fontSize={12}>{description}</Typography>
      <Typography fontSize={14} fontWeight={600}>
        <CountUp
          prefix="R$ "
          end={total / 100}
          decimals={2}
          decimal=","
          separator="."
          duration={0.5}
          preserveValue
          useEasing={false}
          delay={0}
        />
      </Typography>
    </Stack>
  );
}
