import type { Components, SimplePaletteColorOptions } from '@mui/material';
import { outlinedInputClasses } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiInputLabel({
  palette,
  typography,
}: ThemeConfig): Components['MuiInputLabel'] {
  const { main } = palette.error! as Partial<SimplePaletteColorOptions>;

  return {
    defaultProps: { shrink: false },
    styleOverrides: {
      root: {
        transform: 'none',
        color: palette.text?.primary,
        fontSize: typography.body1?.fontSize,

        '&': {
          position: 'relative',
        },

        [`&.${outlinedInputClasses.focused}`]: {
          color: palette.text?.primary,
        },

        [`&.${outlinedInputClasses.error}`]: {
          color: palette.text?.primary,
        },
      },
      asterisk: {
        color: main,
      },
    },
  };
}
