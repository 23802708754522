import { useCallback, useEffect, useState } from 'react';

import { Button, Col, Row, Space, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import { BuildingIcon } from 'src/mui/_icons';

import { PageWrapper, Title, useOpenPortal } from 'src/ui';

import { BelvoWidget } from 'src/libs/belvo/BelvoWidget';
import { useAccounts } from 'src/libs/finbits/Bank/Accounts';
import { usePendingOpenBankingConnections } from 'src/libs/finbits/Bank/OpenBanking';
import { useStatementImportFinishedListener } from 'src/libs/finbits/Listeners';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type { Account } from 'src/libs/finbits/Bank/Accounts/types';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import { PluggyWidget } from 'src/libs/pluggy/PluggyWidget/PluggyWidget';

import BTGConnectionRedirectButton from 'src/features/open-banking/DEPRECATED_BTGConnectionRedirectButton';
import AccountsList from 'src/features/DEPRECATED_accounts/AccountsList/AccountsList';
import EditAccountModal from 'src/features/DEPRECATED_accounts/EditAccountModal';
import AccountModal from 'src/features/accounts/AccountModal';

import styles from './Accounts.module.less';

function Accounts() {
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Account>();
  const { organizationId, companyId } = useCompanyParams();
  const [searchParams] = useSearchParams();
  const openPortal = useOpenPortal();

  const {
    accounts,
    isLoading: isLoadingAccounts,
    refetch: refetchAccounts,
  } = useAccounts({
    companyId,
    organizationId,
  });

  const {
    pendingConnections,
    isLoading: isLoadingConnections,
    refetch: refetchConnections,
  } = usePendingOpenBankingConnections({
    companyId,
    organizationId,
  });

  const refetch = useCallback(() => {
    refetchAccounts();
    refetchConnections();
  }, [refetchAccounts, refetchConnections]);

  useStatementImportFinishedListener(refetch);

  function openAccountModal() {
    handleCreateAccount();
  }

  function onOpenEditModal(account: Account) {
    setSelectedAccount(account);
    setIsEditModalVisible(true);
  }

  function onCloseEditModal() {
    setSelectedAccount(undefined);
    setIsEditModalVisible(false);
  }

  function handleCreateAccount() {
    openPortal(AccountModal, {
      accountsExists: !!accounts.length,
    });
  }

  useEffect(() => {
    const action = searchParams.get('action');

    if (action === 'createAccount') {
      handleCreateAccount();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <PageWrapper className={styles.container}>
      <Row justify="space-between" align="top" gutter={[10, 32]}>
        <Title icon={<BuildingIcon />}>Contas</Title>
        <BelvoWidget />
        <PluggyWidget />

        {selectedAccount && (
          <EditAccountModal
            organizationId={organizationId}
            companyId={companyId}
            visible={isEditModalVisible}
            accountId={selectedAccount.id}
            onClose={onCloseEditModal}
          />
        )}

        <Space direction="vertical" size={32} className={styles.spaceWidth}>
          <Row justify="space-between">
            <Typography.Paragraph className={styles.paragraph}>
              É importante adicionar todas as contas da empresa no Finbits e
              mantê-las atualizadas para que a gestão financeira esteja em dia.
            </Typography.Paragraph>

            <Col>
              <Space size={8}>
                <BTGConnectionRedirectButton>
                  Conexão BTG
                </BTGConnectionRedirectButton>

                <WithAllowedPermission
                  permissions={{
                    resource: 'accounts',
                    action: 'create',
                  }}
                >
                  <Button
                    type="primary"
                    onClick={handleCreateAccount}
                    icon={<PlusOutlined />}
                  >
                    Adicionar Conta
                  </Button>
                </WithAllowedPermission>
              </Space>
            </Col>
          </Row>

          <AccountsList
            loading={isLoadingAccounts || isLoadingConnections}
            accounts={accounts}
            pendingConnections={pendingConnections}
            openEdit={onOpenEditModal}
            openAccountCreation={openAccountModal}
          />
        </Space>
      </Row>
    </PageWrapper>
  );
}

export default Accounts;
