import type { ReactNode } from 'react';

import cn from 'classnames';
import type { AutocompleteGetTagProps, AutocompleteValue } from '@mui/material';
import { Chip } from '@mui/material';

import styles from './RenderTags.module.scss';

type Props = {
  value: AutocompleteValue<any, any, any, any>;
  getTagProps: AutocompleteGetTagProps;
  renderTags: (
    values: AutocompleteValue<any, any, any, any>,
    getTagProps: AutocompleteGetTagProps
  ) => ReactNode;
  limitTags: number;
};

export default function RenderTags({
  renderTags,
  value,
  getTagProps,
  limitTags,
}: Props) {
  const hasLimitTag = Boolean(limitTags);

  const extraSelectedLength = hasLimitTag ? value.length - limitTags : 0;

  const slicedValue = hasLimitTag ? value.slice(0, limitTags) : value;

  const wrapperClassName = cn(styles.tagWrapper, {
    [styles.hasLimitTag]: Boolean(extraSelectedLength),
  });

  return (
    <div className={wrapperClassName}>
      {renderTags(slicedValue, getTagProps)}
      {Boolean(extraSelectedLength > 0) && (
        <Chip
          size="small"
          label={`+${extraSelectedLength}`}
          className={styles.extraSelectedIcon}
        />
      )}
    </div>
  );
}
