import { useMutation, useQueryClient } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';
import { invalidateBillsQueries } from 'src/libs/finbits/Bills';
import { invalidateEntriesQueries } from 'src/libs/finbits/Management/FinancialStatements/Entries';

import { type BillPayable, BillPayableDecoder } from '../Bills/types';

import type { Params, ReprovedParams, WaitingPaymentParams } from './types';

async function postWaitingApproval({
  organizationId,
  companyId,
  ...params
}: Params) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/payments_flow/waiting_approvals`,
    params
  );

  return decodeResponse<BillPayable[]>(response, BillPayableDecoder.array());
}

async function undoWaitingApproval({
  companyId,
  organizationId,
  ...params
}: Params) {
  const response = await authenticatedAPI.post(
    `organizations/${organizationId}/companies/${companyId}/payments_flow/waiting_approvals/undo`,
    params
  );

  return decodeResponse<BillPayable[]>(response, BillPayableDecoder.array());
}

export function useCreateWaitingApproval() {
  const queryClient = useQueryClient();
  const { mutate: createWaitingApproval, ...rest } = useMutation<
    BillPayable[],
    ApiError,
    Params
  >(postWaitingApproval, {
    onSuccess: () => {
      invalidateBillsQueries(queryClient);
    },
  });
  return { createWaitingApproval, ...rest };
}

export function useUndoWaitingApproval() {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation<BillPayable[], ApiError, Params>(
    undoWaitingApproval,
    {
      onSuccess: () => {
        invalidateBillsQueries(queryClient);
      },
    }
  );

  return { undoWaitingApproval: mutate, ...rest };
}

async function postWaitingPayment({
  organizationId,
  companyId,
  ...params
}: WaitingPaymentParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/payments_flow/waiting_payments`,
    params
  );

  return decodeResponse<BillPayable[]>(response, BillPayableDecoder.array());
}

async function postUndoWaitingPayment({
  companyId,
  organizationId,
  ...params
}: Params) {
  const response = await authenticatedAPI.post(
    `organizations/${organizationId}/companies/${companyId}/payments_flow/waiting_payments/undo`,
    params
  );

  return decodeResponse<BillPayable[]>(response, BillPayableDecoder.array());
}

export function useCreateWaitingPayment() {
  const queryClient = useQueryClient();
  const { mutate: createWaitingPayment, ...rest } = useMutation<
    BillPayable[],
    ApiError,
    WaitingPaymentParams
  >(postWaitingPayment, {
    onSuccess: () => {
      invalidateBillsQueries(queryClient);
    },
  });
  return { createWaitingPayment, ...rest };
}

export function useUndoWaitingPayment() {
  const queryClient = useQueryClient();
  const { mutate: undoWaitingPayment, ...rest } = useMutation<
    BillPayable[],
    ApiError,
    Params
  >(postUndoWaitingPayment, {
    onSuccess: () => {
      invalidateBillsQueries(queryClient);
    },
  });

  return { undoWaitingPayment, ...rest };
}

async function postApproved({ organizationId, companyId, ...params }: Params) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/payments_flow/approved`,
    params
  );

  return decodeResponse<BillPayable[]>(response, BillPayableDecoder.array());
}

async function undoApproved({ companyId, organizationId, ...params }: Params) {
  const response = await authenticatedAPI.post(
    `organizations/${organizationId}/companies/${companyId}/payments_flow/approved/undo`,
    params
  );

  return decodeResponse<BillPayable[]>(response, BillPayableDecoder.array());
}

export function useCreateApproved() {
  const queryClient = useQueryClient();
  const { mutate: createApproved, ...rest } = useMutation<
    BillPayable[],
    ApiError,
    Params
  >(postApproved, {
    onSuccess: () => {
      invalidateBillsQueries(queryClient);
    },
  });
  return { createApproved, ...rest };
}

export function useUndoApproved() {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation<BillPayable[], ApiError, Params>(
    undoApproved,
    {
      onSuccess: () => {
        invalidateBillsQueries(queryClient);
      },
    }
  );

  return { undoApproved: mutate, ...rest };
}

async function postReproved({
  organizationId,
  companyId,
  ...params
}: ReprovedParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/payments_flow/reproved`,
    params
  );

  return decodeResponse<BillPayable[]>(response, BillPayableDecoder.array());
}

export function useCreateReproved() {
  const queryClient = useQueryClient();
  const { mutate: createReproved, ...rest } = useMutation<
    BillPayable[],
    ApiError,
    ReprovedParams
  >(postReproved, {
    onSuccess: () => {
      invalidateBillsQueries(queryClient);
      invalidateEntriesQueries(queryClient);
    },
  });
  return { createReproved, ...rest };
}
