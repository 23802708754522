import compact from 'lodash/compact';
import type { GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import { Chip } from '@mui/material';
import classNames from 'classnames';

import { TreeSelect } from 'src/ui';

import type { Classification } from 'src/libs/finbits/Classifications/types';
import type { Label } from 'src/libs/finbits/Labels/types';

import styles from './LabelSelect.module.scss';

type Props = Pick<
  GridRenderEditCellParams,
  'api' | 'value' | 'field' | 'id' | 'hasFocus'
> & {
  classification: Classification;
  onChange?: any;
  multiple?: boolean;
  bordered?: boolean;
};

export default function LabelAutocomplete({
  api,
  value,
  field,
  id,
  classification,
  onChange,
  multiple = true,
  hasFocus,
  bordered = true,
}: Props) {
  const selectedLabelsIds = Array.isArray(value)
    ? value.map(({ id }: Label) => id)
    : [value];

  const labels = classification.labels;

  function handleChange(value: string[] | null) {
    const selectedLabels = getLabels(value, labels);

    if (onChange) {
      onChange({}, value);
    } else {
      api.setEditCellValue({ id, field, value: selectedLabels ?? [] });
    }
  }

  const treeData = buildLabelOption(labels, selectedLabelsIds);

  const className = classNames(styles.treeSelect, {
    [styles.bordered]: bordered,
  });

  return (
    <TreeSelect
      bordered={bordered}
      autoFocus={hasFocus}
      open={hasFocus}
      id={classification.id}
      className={className}
      dropdownClassName={styles.dropdownClassName}
      aria-label={classification.name}
      multiple={multiple}
      showCheckedStrategy="SHOW_ALL"
      treeDefaultExpandAll
      placeholder="Selecione uma etiqueta"
      treeNodeFilterProp="name"
      treeData={treeData}
      notFoundContent={<li className={styles.notFoundContent}>Sem opções</li>}
      tagRender={({ label, onClose }) => (
        <Chip
          label={label}
          onDelete={onClose}
          className={styles.chip}
          variant="outlined"
          size="small"
        />
      )}
      defaultValue={selectedLabelsIds}
      onChange={handleChange}
      fieldNames={{
        label: 'name',
        value: 'id',
        children: 'children',
      }}
    />
  );
}

function isRelated(parent: Label, childrenIds?: string[]): boolean {
  const parentIsChild = childrenIds?.some((id) => id === parent.id) ?? false;

  return (
    parent?.children?.some((parentChildLabel) => {
      return (
        childrenIds?.some((id) => id === parentChildLabel.id) ||
        isRelated(parentChildLabel, childrenIds)
      );
    }) || parentIsChild
  );
}

function buildLabelOption(
  labels: Label[] = [],
  selectedLabelIds: string[] = []
): Label[] {
  return compact(
    labels?.map((label) => {
      const inactiveLabel = !label.active;

      const selected = selectedLabelIds?.some((id) => id === label.id) ?? false;

      if (!isRelated(label, selectedLabelIds) && inactiveLabel)
        return undefined;

      return {
        ...label,
        children: buildLabelOption(label.children, selectedLabelIds),
        disabled: !selected && inactiveLabel,
      };
    })
  );
}

function getLabels(ids: string[] | null, labels?: Label[]): Label[] {
  if (ids === null) return [];

  const selectedLabels = labels?.flatMap((lab) => {
    const selectedChilds = getLabels(ids, lab.children);

    if (ids?.includes(lab.id)) {
      return [lab, ...selectedChilds];
    }

    if (selectedChilds.length) {
      return selectedChilds;
    }

    return undefined;
  });

  return compact(selectedLabels);
}
