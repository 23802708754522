import { useEffect } from 'react';

import { ChevronSelectorVerticalIcon } from 'src/mui/_icons';
import { Box, Divider, Stack, Typography } from '@mui/material';

import type {
  InboxItemSource,
  NewInboxItem,
} from 'src/libs/finbits/Management/NewInboxItems/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useInboxItem } from 'src/libs/finbits/Management/NewInboxItems';
import type { ExternalFiles } from 'src/libs/finbits/Management/Attachments/types';

import DetailsHeader from 'src/features/inbox-items/InboxItemDetails/DetailsHeader';
import { useInboxItemsParams } from 'src/features/inbox-items/useInboxItemsParams';
import Informations from 'src/features/inbox-items/Informations';
import BoletoDetails from 'src/features/inbox-items/InboxItemDetails/BoletoDetails';
import styles from 'src/features/inbox-items/InboxItemDetails/InboxItemDetails.module.scss';

import { useInboxItemsAttachments } from '../useInboxItemsAttachments';

import RejectionReasonAlert from './RejectionReasonAlert';
import InboxItemNotFound from './InboxItemNotFound';
import NotaFiscalDetails from './NotaFiscalDetails';
import PurchaseOrderDetails from './PurchaseOrderDetails';
import Loading from './Loading';
import EmailDetails from './EmailDetails';
import UploadDetails from './UploadDetails';
import WhatsAppDetails from './WhatsAppDetails';
import Actions from './Actions';
import AttachmentsContainer from './AttachmentsContainer';

function getDetailsComponent(
  inboxItem: NewInboxItem
): Record<InboxItemSource, JSX.Element | null> {
  return {
    dda: <BoletoDetails boleto={inboxItem.boleto} />,
    email: <EmailDetails receivedEmail={inboxItem.receivedEmail} />,
    nota_fiscal: (
      <NotaFiscalDetails receivedNotaFiscal={inboxItem.receivedNotaFiscal} />
    ),
    product_nota_fiscal: (
      <NotaFiscalDetails
        receivedNotaFiscal={inboxItem.receivedProductNotaFiscal}
      />
    ),
    purchase_order: (
      <PurchaseOrderDetails purchaseOrder={inboxItem.purchaseOrder} />
    ),
    upload: <UploadDetails inboxItem={inboxItem} />,
    whatsapp: <WhatsAppDetails inboxItem={inboxItem} />,
  };
}

type Props = {
  inboxItems: NewInboxItem[];
};

export default function InboxItemDetails({ inboxItems }: Props) {
  const { inboxItemId, status, setStatus } = useInboxItemsParams();

  const { companyId, organizationId } = useCompanyParams();

  const { inboxItem, isLoading } = useInboxItem({
    companyId,
    organizationId,
    inboxItemId,
  });

  useEffect(() => {
    if (inboxItem && inboxItem.status !== status) {
      setStatus(inboxItem.status, { inboxItemId: inboxItem.id });
    }
  }, [inboxItem, inboxItemId, status, setStatus]);

  const { attachments, externalFiles } = useInboxItemsAttachments(inboxItem);

  const inboxItemIndex =
    inboxItems.findIndex((inboxItem) => inboxItem.id === inboxItemId) + 1;

  if (!isLoading && !inboxItem) {
    if (inboxItemId) {
      return <InboxItemNotFound />;
    }

    return <Informations />;
  }

  return (
    <>
      <Stack className={styles.menu}>
        <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
          <Stack direction="row" alignItems="center" gap={2}>
            <ChevronSelectorVerticalIcon />
            <Typography variant="body2">
              {inboxItemIndex} de {inboxItems.length}
            </Typography>
          </Stack>
          <Actions inboxItem={inboxItem} />
        </Stack>
      </Stack>

      <Box className={styles.details}>
        {isLoading || !inboxItem ? (
          <Loading />
        ) : (
          <>
            <RejectionReasonAlert inboxItem={inboxItem} />

            <DetailsHeader inboxItem={inboxItem} />
            {getDetailsComponent(inboxItem)[inboxItem.source]}
          </>
        )}

        <Divider />

        <Typography fontWeight={600}>Anexos</Typography>
        <AttachmentsContainer attachments={attachments} />

        <AttachmentsContainer
          isInvalid
          attachments={externalFiles as ExternalFiles[]}
          sender={inboxItem?.fromDetail}
        />
      </Box>
    </>
  );
}
