import t from 'zod';

import { UserDecoder } from 'src/libs/finbits/Organization/Users/types';
import {
  AttachmentDecoder,
  ExternalFilesDecoder,
} from 'src/libs/finbits/Management/Attachments/types';
import { PaymentDetailsDecoder } from 'src/libs/finbits/Payments/types';
import { BoletoDecoder } from 'src/libs/finbits/Boletos/types';
import { CategoryDecoder } from 'src/libs/finbits/Management/FinancialEntries/Categories/types';
import { ContactDecoder } from 'src/libs/finbits/Organization/Companies/Contacts/types';
import { ContactType } from 'src/libs/finbits/Organization/Companies/Contacts/enum';

export const InboxItemContactTypeDecoder = t.union([
  t.literal('legal'),
  t.literal('natural'),
  t.literal('international'),
]);

export const InboxItemStatusDecoder = t.union([
  t.literal('pending'),
  t.literal('accepted'),
  t.literal('rejected'),
]);

export enum InboxItemSource {
  EMAIL = 'email',
  UPLOAD = 'upload',
  WHATSAPP = 'whatsapp',
  NOTA_FISCAL = 'nota_fiscal',
  PRODUCT_NOTA_FISCAL = 'product_nota_fiscal',
  PURCHASE_ORDER = 'purchase_order',
  DDA = 'dda',
}

const ReceivedEmailDecoder = t.object({
  id: t.string(),
  from: t.string(),
  to: t.string(),
  fromName: t.string(),
  subject: t.string().nullable(),
  htmlBody: t.string().nullable(),
  textBody: t.string().nullable(),
  attachments: AttachmentDecoder.array().nullish(),
  externalFiles: ExternalFilesDecoder.array().nullish(),
});

const ReceivedNotaFiscalDecoder = t.object({
  id: t.string(),
  amount: t.number(),
  issuerLegalDocument: t.string(),
  issuerLegalDocumentType: t.nativeEnum(ContactType).nullish(),
  issuerTradeName: t.string(),
  number: t.string(),
  relevantDate: t.string().nullable(),
  serviceDescription: t.string().nullable(),
  authorizationDate: t.string().nullable(),
  attachments: AttachmentDecoder.array().nullish(),
});

const ReceivedProductNotaFiscalDecoder = t.object({
  id: t.string(),
  amount: t.number(),
  issuerLegalDocument: t.string(),
  issuerLegalDocumentType: t.nativeEnum(ContactType).nullish(),
  issuerTradeName: t.string(),
  number: t.string(),
  authorizationDate: t.string().nullable(),
  attachments: AttachmentDecoder.array().nullish(),
});

const UploadDecoder = t.object({
  id: t.string(),
  attachments: AttachmentDecoder.array().nullish(),
});

const ReceivedWhatsAppDecoder = t.object({
  id: t.string(),
  attachments: AttachmentDecoder.array().nullish(),
});

const PurchaseOrderDecoder = t.object({
  id: t.string(),
  requesterName: t.string(),
  requesterEmail: t.string(),
  description: t.string(),
  amount: t.number(),
  contactType: InboxItemContactTypeDecoder,
  contactDocument: t.string(),
  category: CategoryDecoder.nullish(),
  contact: ContactDecoder.nullable(),
  date: t.string().nullable(),
  comments: t.string().nullable(),
  paymentDetails: PaymentDetailsDecoder.nullable(),
  attachments: AttachmentDecoder.array().nullish(),
});

export const NewInboxItemDecoder = t.object({
  id: t.string(),
  title: t.string(),
  description: t.string(),
  fromName: t.string(),
  filesCount: t.number(),
  insertedAt: t.string(),
  source: t.nativeEnum(InboxItemSource),
  status: InboxItemStatusDecoder,
  fromDetail: t.string().nullable(),
  rejectionReason: t.string().nullable(),
  rejectedAt: t.string().nullable(),
  receivedEmail: ReceivedEmailDecoder.nullish(),
  receivedNotaFiscal: ReceivedNotaFiscalDecoder.nullish(),
  receivedProductNotaFiscal: ReceivedProductNotaFiscalDecoder.nullish(),
  boleto: BoletoDecoder.nullish(),
  receivedWhatsapp: ReceivedWhatsAppDecoder.nullish(),
  upload: UploadDecoder.nullish(),
  purchaseOrder: PurchaseOrderDecoder.nullish(),
  assignee: UserDecoder.nullish(),
});

export type NewInboxItem = t.TypeOf<typeof NewInboxItemDecoder>;
export type ReceivedEmail = t.TypeOf<typeof ReceivedEmailDecoder>;
export type ReceivedNotaFiscal = t.TypeOf<typeof ReceivedNotaFiscalDecoder>;
export type ReceivedProductNotaFiscal = t.TypeOf<
  typeof ReceivedProductNotaFiscalDecoder
>;
export type ReceivedWhatsApp = t.TypeOf<typeof ReceivedWhatsAppDecoder>;
export type Upload = t.TypeOf<typeof UploadDecoder>;
export type PurchaseOrder = t.TypeOf<typeof PurchaseOrderDecoder>;
export type InboxItemStatus = t.TypeOf<typeof InboxItemStatusDecoder>;
export type InboxItemContactType = t.TypeOf<typeof InboxItemContactTypeDecoder>;

export type GetParams = {
  organizationId: string;
  companyId: string;
  status: InboxItemStatus;
};

export type GetInboxItemParams = {
  organizationId: string;
  companyId: string;
  inboxItemId?: string | null;
};

export type PostRejectParams = {
  organizationId: string;
  companyId: string;
  inboxItemsIds: string[];
  rejectionReason?: string;
};

export type PostRestoreParams = {
  organizationId: string;
  companyId: string;
  inboxItemsIds: string[];
};
