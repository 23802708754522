import { Alert, AlertTitle, Stack } from '@mui/material';
import { CheckCircleIcon, KeyIcon } from 'src/mui/_icons';

import { CopyButton, FocusIcon, Typography } from 'src/mui';

import type { PublicApiToken } from 'src/libs/finbits/PublicApiTokens/types';

import PublicApiTokenSkeleton from '../PublicApiTokenSkeleton';

import styles from './PublicApiTokenAlert.module.scss';

type Props = {
  isError: Boolean;
  isCreating: Boolean;
  token?: PublicApiToken;
};

export default function PublicApiTokenAlert({
  isError,
  isCreating,
  token,
}: Props) {
  if (isCreating) {
    return <PublicApiTokenSkeleton />;
  }

  if (token) {
    return (
      <>
        <Alert
          icon={
            <Stack className={styles.checkIcon}>
              <CheckCircleIcon />
            </Stack>
          }
          severity="success"
          className={styles.alert}
        >
          <AlertTitle>Token gerado com sucesso!</AlertTitle>
          <Typography variant="body1">
            Lembre-se de copiar o código abaixo pois não será possível fazer
            isso novamente.
          </Typography>
        </Alert>

        <Stack direction="row" maxWidth="xl" className={styles.container}>
          <FocusIcon size="lg" className={styles.icon}>
            <KeyIcon />
          </FocusIcon>
          <Typography variant="subtitle2" className={styles.tokenFont}>
            {token.token}
          </Typography>
          <CopyButton
            clipboard={token.token}
            title="Token copiado"
            variant="contained"
            className={styles.copyButton}
          >
            Copiar
          </CopyButton>
        </Stack>
      </>
    );
  }

  if (isError) {
    return (
      <Alert severity="error" className={styles.alert}>
        <AlertTitle>Não foi possível gerar o token</AlertTitle>
        <Typography variant="body1">
          Por favor, tente novamente em alguns instantes. Se o problema
          persistir, entre em contato conosco.
        </Typography>
      </Alert>
    );
  }

  return <></>;
}
