import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Link,
  Stack,
} from '@mui/material';
import CopyButton from 'src/mui/CopyButton';

import { Typography } from 'src/mui';

import apiDiagramLightImg from 'src/assets/api-diagram-light.svg';
import apiDiagramDarkImg from 'src/assets/api-diagram-dark.svg';

import { useTheme } from 'src/features/theme-switcher/ThemeProvider';

import styles from './DevDocCard.module.scss';

const DEV_DOC_URL = 'https://docs.finbits.com.br';

export default function DevDocCard() {
  const [theme] = useTheme();

  return (
    <Card variant="outlined" className={styles.container}>
      <CardMedia
        component="img"
        alt="Diagrama de uma API"
        src={theme === 'dark' ? apiDiagramDarkImg : apiDiagramLightImg}
        className={styles.media}
      />
      <CardContent>
        <Stack justifyContent="center" className={styles.content}>
          <Typography variant="subtitle1">
            Documentação para desenvolvedores
          </Typography>

          <Typography variant="body1" color="text.secondary">
            Consulte ou compartilhe com a sua equipe técnica a documentação da
            API FinBits:
          </Typography>
        </Stack>
      </CardContent>
      <CardActions className={styles.actions}>
        <Link href={DEV_DOC_URL} variant="body1">
          {DEV_DOC_URL}
        </Link>

        <CopyButton clipboard={DEV_DOC_URL} title="Copiado!" variant="outlined">
          Copiar link
        </CopyButton>
      </CardActions>
    </Card>
  );
}
