import { Box, Button } from '@mui/material';
import { ConnectAccountIcon } from 'src/mui/_icons';

import { FocusIcon, Typography } from 'src/mui';

type Props = {
  onClose: () => void;
  onStart: () => void;
};

export default function Introductory({ onClose, onStart }: Props) {
  return (
    <>
      <FocusIcon size="lg">
        <ConnectAccountIcon fontSize="medium" />
      </FocusIcon>

      <Typography variant="subtitle1" mb={1}>
        Adicionar conta
      </Typography>

      <Typography variant="body1" color="grey.600" mb={8}>
        Adicione contas bancárias e não bancarizadas no FinBits para importar as
        suas movimentações e começar a sua gestão financeira.
      </Typography>

      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={3}>
        <Button onClick={onClose} color="secondary" variant="outlined">
          Cancelar
        </Button>

        <Button onClick={onStart} color="primary" variant="contained">
          Continuar
        </Button>
      </Box>
    </>
  );
}
