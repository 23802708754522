import type { DataGridPremiumComponents } from '@mui/x-data-grid-premium/themeAugmentation';
import { formLabelClasses, paperClasses } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiDataGrid({
  palette,
  typography,
  shadows,
}: ThemeConfig): DataGridPremiumComponents['MuiDataGrid'] {
  const rowBackgroundColor =
    palette.mode === 'light' ? palette.grey?.[50] : palette.grey?.[900];

  return {
    styleOverrides: {
      root: {
        borderColor: 'transparent',
      },
      row: {
        [`&:nth-of-type(odd):not(:hover):not(.Mui-selected)`]: {
          backgroundColor: rowBackgroundColor,
        },
      },
      cell: { ...typography.body1 },
      panelWrapper: {
        padding: '16px',
        boxShadow: 'none',
      },
      filterForm: { alignItems: 'flex-end', gap: '8px', padding: '8px 4px' },
      filterFormDeleteIcon: { marginBottom: '6px' },
      columnsPanelRow: { padding: '8px' },

      panelHeader: {
        [`& .${formLabelClasses.root}`]: {
          display: 'none !important',
        },
      },

      menu: {
        [`.${paperClasses.root}`]: {
          paddingInline: '8px',
          border: `1px solid ${palette.divider}`,
          boxShadow: shadows[4],

          '.MuiList-root': {
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          },
        },
      },

      menuList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
      },
    },
  };
}
