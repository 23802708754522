import t from 'zod';

export const BankDecoder = t.object({
  name: t.string(),
  routingNumber: t.string(),
  providerCodes: t.string().array(),
});

export const BanksDecoder = t.array(BankDecoder);

export type Bank = t.TypeOf<typeof BankDecoder>;
export type BankExtended = Bank & { fullName: string };
