import type { ReactNode } from 'react';

import { Chip } from '@mui/material';
import { InfoCircleIcon } from 'src/mui/_icons';

import { Tooltip, Typography } from 'src/mui';

import styles from './Label.module.scss';

type Props = {
  title: string;
  subtitle?: string;
  infoContent?: ReactNode;
  badgeValue?: number;
};

export default function Label({
  title,
  subtitle,
  badgeValue,
  infoContent,
}: Props) {
  return (
    <>
      <Typography variant="overline" className={styles.label}>
        {subtitle}
      </Typography>
      <Typography variant="body1" className={styles.title}>
        {title}
        {badgeValue !== undefined && (
          <Chip label={badgeValue} color="secondary" size="xsmall" />
        )}
        {infoContent && (
          <Tooltip title={infoContent}>
            <InfoCircleIcon className={styles.icon} />
          </Tooltip>
        )}
      </Typography>
    </>
  );
}
