import { Alert, AlertTitle, Typography } from '@mui/material';
import { AlertHexagonIcon, BuildingIcon } from 'src/mui/_icons';

import type { PixKey } from 'src/libs/finbits/Bank/Pix/types';
import { format } from 'src/libs/finbits/Date';
import { contactTypeDictionary } from 'src/libs/finbits/Organization/Companies/Contacts/translations';

import { alert, alertTitle, noMatchAlert } from './PixKeyAlert.sx';

type Props = {
  pixKey?: PixKey;
};

export default function PixKeyAlert({ pixKey }: Props) {
  if (!pixKey) return null;

  const matchesContact = Boolean(pixKey?.matchesContact);

  return (
    <Alert
      sx={alert}
      severity={matchesContact ? 'info' : 'warning'}
      variant="filled"
      iconMapping={{
        info: <BuildingIcon fontSize="inherit" />,
        warning: <AlertHexagonIcon fontSize="inherit" />,
      }}
    >
      <AlertTitle sx={alertTitle}>Para {pixKey?.pixKey.ownerName}</AlertTitle>

      <Typography component="p" sx={{ mb: 1 }}>
        {contactTypeDictionary[pixKey?.pixKey.ownerType]}:{' '}
        <b>{pixKey?.pixKey.ownerTaxIdText}</b>
      </Typography>
      <Typography component="p" sx={{ mb: 1 }}>
        Banco: <b>{pixKey.pixKey.bankName}</b>
      </Typography>
      <Typography component="p" sx={{ mb: 1 }}>
        Agência: <b>{pixKey.pixKey.branchNumber}</b> Conta:{' '}
        <b>{pixKey.pixKey.accountNumber}</b>
      </Typography>

      <Typography>
        Consulta feita em:{' '}
        <b>{format(pixKey?.pixKey.updatedAt!, 'dd/MM/yyyy')}</b>
      </Typography>

      {!matchesContact && (
        <Alert icon={false} color="warning" sx={noMatchAlert}>
          Estes dados não correspondem ao contato selecionado. Se prosseguir, a
          inclusão do pagamento será feito para a chave PIX informada.
        </Alert>
      )}
    </Alert>
  );
}
