import { DeleteOutlined } from '@ant-design/icons';

import { snackbar } from 'src/mui';

import { DrawerHeaderButton, useOpenPortal } from 'src/ui';

import { AvailableActions } from 'src/libs/finbits/Management/Entries/types';
import type { BillPayable } from 'src/libs/finbits/Bills/types';
import {
  useDeleteBill,
  useDeleteBillRecurrences,
} from 'src/libs/finbits/Bills';
import { canPerformAction } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';

import { useOpenConfirmDeletionDialog } from 'src/features/entries/Dialogs';
import DeleteScheduledRecurrenceModal from 'src/features/entries/DeleteScheduledRecurrenceModal';

type Props = {
  bill?: BillPayable;
  onSuccess: () => void;
};

export default function DeleteHeaderButton({ bill, onSuccess }: Props) {
  const { companyId, organizationId } = useCompanyParams();
  const { deleteBill } = useDeleteBill();
  const { deleteBillRecurrences } = useDeleteBillRecurrences();

  const openConfirmDeletion = useOpenConfirmDeletionDialog();
  const openPortal = useOpenPortal();

  const isRecurrent = Boolean(bill?.scheduledRecurrence);

  function handleDelete() {
    if (!bill) return;

    deleteBill(
      {
        billId: bill.id,
        companyId,
        organizationId,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'Pagamento excluído com sucesso!',
          });

          onSuccess();
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Falha ao excluir pagamento!',
          });
        },
      }
    );
  }

  function handleDeleteRecurrences() {
    deleteBillRecurrences(
      {
        billId: bill?.id,
        companyId,
        organizationId,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'Pagamentos excluídos com sucesso!',
          });

          onSuccess();
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Falha ao excluir pagamentos!',
          });
        },
      }
    );
  }

  function openDeleteConfirmation() {
    if (isRecurrent) {
      openPortal(DeleteScheduledRecurrenceModal, {
        onDeletedRecurrence: handleDeleteRecurrences,
        onDelete: handleDelete,
      });

      return;
    }

    openConfirmDeletion({
      onConfirm: handleDelete,
    });
  }

  const canDeleteBill =
    useAllowedPermission({ action: 'delete', resource: 'bills' }) &&
    canPerformAction(bill!, AvailableActions.DELETE);

  if (!canDeleteBill) {
    return null;
  }

  return (
    <DrawerHeaderButton
      icon={<DeleteOutlined />}
      title="Excluir"
      onClick={openDeleteConfirmation}
    />
  );
}
