import type { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Chip, Stack, Typography } from '@mui/material';

import type { Label } from 'src/libs/finbits/Labels/types';

export default function ClassificationList({
  value,
  rowNode,
}: Partial<GridRenderCellParams>) {
  const overflowSx = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  if (rowNode?.type === 'group') {
    return <Typography>{value}</Typography>;
  }

  return (
    <Stack direction="row" gap={2} sx={overflowSx}>
      {value.map(({ name, id }: Label) => (
        <Chip key={id} label={name} size="small" sx={overflowSx} />
      ))}
    </Stack>
  );
}
