import { Box, Stack, Typography } from '@mui/material';

import emptyPreviewImg from 'src/assets/empty-preview.png';

import styles from './EmptyState.module.scss';

export default function EmptyState() {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      className={styles.container}
    >
      <Box
        component="img"
        src={emptyPreviewImg}
        alt="Sem anexos"
        className={styles.icon}
        draggable={false}
      />

      <Typography variant="subtitle1" className={styles.title}>
        Sem anexos
      </Typography>

      <Typography align="center" className={styles.description}>
        Você pode subir o seu anexo manualmente na aba informações.
      </Typography>
    </Stack>
  );
}
