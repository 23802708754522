import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import type { GridRenderCellParams } from '@mui/x-data-grid-premium';
import BankIcon from 'src/mui/_scss/BankIcon';

import { Typography } from 'src/mui';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';

import styles from './AccountInfo.module.scss';

export default function AccountInfo({
  row,
  rowNode,
  formattedValue,
}: Partial<GridRenderCellParams<{ account?: Account }>>) {
  if (rowNode?.type === 'group') {
    return <Typography>{formattedValue}</Typography>;
  }

  return (
    <ListItem disablePadding disableGutters>
      <ListItemIcon className={styles.icon}>
        <BankIcon
          routingNumber={row?.account?.routingNumber}
          width={24}
          height={24}
        />
      </ListItemIcon>

      <ListItemText secondary={row?.account?.accountNumber}>
        <Typography ellipsis>{row?.account?.name}</Typography>
      </ListItemText>
    </ListItem>
  );
}
