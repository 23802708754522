import {
  Alert,
  Button,
  Card,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import { LockIcon, PlusIcon } from 'src/mui/_icons';

import { useOpenPortal } from 'src/ui';

import { WithAllowedPermission } from 'src/libs/finbits/Permissions';
import { usePublicApiToken } from 'src/libs/finbits/PublicApiTokens';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import CreatePublicApiTokenModal from 'src/features/companies/PublicApiTokens/CreatePublicApiTokenModal';

import TokenItem from '../TokenItem/TokenItem';
import EmptyApiTokenImage from '../ApiTokenEmptyState';

import styles from './TokensCard.module.scss';

export default function TokensCard() {
  const payload = useCompanyParams();

  const { publicApiTokens } = usePublicApiToken(payload);

  const openPortal = useOpenPortal();

  function handleCreateNewToken() {
    openPortal(CreatePublicApiTokenModal);
  }

  return (
    <Card variant="elevation" className={styles.card}>
      <CardHeader
        action={
          <WithAllowedPermission
            permissions={{ resource: 'publicApiTokens', action: 'create' }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<PlusIcon />}
              onClick={handleCreateNewToken}
            >
              Criar novo token
            </Button>
          </WithAllowedPermission>
        }
        title="Listagem de tokens"
        titleTypographyProps={{ variant: 'subtitle2' }}
      />
      <Stack gap={3} className={styles.container}>
        {publicApiTokens.length > 0 ? (
          <>
            <Alert severity="info" icon={<LockIcon />}>
              <Typography variant="body1">
                Por segurança, os tokens só podem ser exibidos e copiados no
                momento da criação.
              </Typography>
            </Alert>

            {publicApiTokens.map((token, index) => (
              <TokenItem token={token} key={index} />
            ))}
          </>
        ) : (
          <EmptyApiTokenImage />
        )}
      </Stack>
    </Card>
  );
}
