import type { GridApi } from '@mui/x-data-grid-premium';
import { Badge, Chip, Divider } from '@mui/material';
import { DotsVerticalIcon } from 'src/mui/_icons';

import { Dropdown } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import { redirectPathAfterEntriesImport } from 'src/libs/finbits/Bank/Statements';
import { useConciliationSuggestions } from 'src/libs/finbits/Management/FinancialStatements/ConciliationSuggestions';
import {
  WithAllowedPermission,
  useAllowedPermission,
} from 'src/libs/finbits/Permissions';

import ImportScheduledEntryModal from 'src/features/entries/Modals/ImportScheduledEntryModal';
import ImportOfxModal from 'src/features/ofx/ImportOfxModal';
import ExportModal from 'src/features/entries/Modals/ExportModal';
import CreateFinancialEntryDrawer from 'src/features/entries/Drawers/FinancialEntry/CreateFinancialEntryDrawer';
import CreateScheduledDrawer from 'src/features/scheduled/CreateScheduledDrawer';
import { IgnoredRecordsDrawer } from 'src/features/entries/IgnoredRecordsDrawer';
import { ConciliationSuggestionsDrawer } from 'src/features/entries/ConciliationSuggestionsDrawer';
import useEntriesParams from 'src/features/entries/useEntriesParams';

import styles from './TableRootActions.module.scss';

type Props = {
  apiRef: GridApi;
};

export default function TableRootActions({ apiRef }: Props) {
  const openPortal = useOpenPortal();

  const {
    organizationId,
    companyId,
    selectedAccounts,
    selectedDateRange,
    accounts,
    isLoadingAccounts,
  } = useEntriesParams();

  const canAcceptSuggestions = useAllowedPermission({
    action: 'update',
    resource: 'entriesConciliationSuggestions',
  });

  const { suggestions } = useConciliationSuggestions(
    { organizationId, companyId },
    { enabled: canAcceptSuggestions }
  );

  const suggestionsCount = suggestions.length;

  function handleExportCsv() {
    apiRef.exportDataAsCsv();
  }

  function handleOpenExportModal() {
    openPortal(ExportModal, {
      organizationId,
      companyId,
      selectedAccounts,
      accounts,
      dateRange: selectedDateRange,
    });
  }

  function handleOpenImportScheduledEntryModal() {
    openPortal(ImportScheduledEntryModal, {
      accounts,
      isLoadingAccounts,
    });
  }

  function handleUploadSettled() {
    openPortal(ImportOfxModal, {
      organizationId,
      companyId,
      redirectPathAfterImport: redirectPathAfterEntriesImport,
    });
  }

  function handleOpenCreateFinancialEntryDrawer() {
    openPortal(CreateFinancialEntryDrawer);
  }

  function handleOpenCreateScheduledDrawer() {
    openPortal(CreateScheduledDrawer);
  }

  function handleOpenIgnoredRecordsDrawer() {
    openPortal(IgnoredRecordsDrawer);
  }

  function handleOpenConciliationSuggestionsDrawer() {
    openPortal(ConciliationSuggestionsDrawer, {
      suggestions,
    });
  }

  return (
    <Dropdown
      variant="text"
      options={[
        <WithAllowedPermission
          permissions={[
            {
              resource: 'bills',
              action: 'create',
            },
            {
              resource: 'receivables',
              action: 'create',
            },
          ]}
          key="createScheduled"
        >
          <Dropdown.Option onClick={handleOpenCreateScheduledDrawer}>
            Criar lançamento programado
          </Dropdown.Option>
        </WithAllowedPermission>,
        <WithAllowedPermission
          permissions={{
            resource: 'financialEntries',
            action: 'create',
          }}
          key="createSettled"
        >
          <Dropdown.Option onClick={handleOpenCreateFinancialEntryDrawer}>
            Criar lançamento realizado
          </Dropdown.Option>
        </WithAllowedPermission>,

        <Divider key="uploadsDivider" className={styles.divider} />,

        <WithAllowedPermission
          permissions={{
            resource: 'entries',
            action: 'import_spreadsheet',
          }}
          key="uploadScheduled"
        >
          <Dropdown.Option onClick={handleOpenImportScheduledEntryModal}>
            Upload de lançamentos programados (XLSX)
          </Dropdown.Option>
        </WithAllowedPermission>,

        <WithAllowedPermission
          permissions={{
            resource: 'bankStatement',
            action: 'create',
          }}
          key="uploadSettled"
        >
          <Dropdown.Option onClick={handleUploadSettled}>
            Upload de lançamentos realizados (OFX)
          </Dropdown.Option>
        </WithAllowedPermission>,

        <Divider key="downloadsDivider" className={styles.divider} />,

        <WithAllowedPermission
          permissions={{
            resource: 'statementExport',
            action: 'create',
          }}
          key="createExport"
        >
          <Dropdown.Option onClick={handleOpenExportModal}>
            Download de lançamentos e anexos (ZIP)
          </Dropdown.Option>
        </WithAllowedPermission>,

        <Dropdown.Option onClick={handleExportCsv} key="createCsv">
          Download de lançamentos (CSV)
        </Dropdown.Option>,

        <Divider key="drawersDivider" className={styles.divider} />,

        <Dropdown.Option
          onClick={handleOpenIgnoredRecordsDrawer}
          key="openDeletedDrawer"
        >
          Ver excluídos
        </Dropdown.Option>,

        <WithAllowedPermission
          key="openConciliationSuggestionsDrawer"
          permissions={{
            resource: 'entriesConciliationSuggestions',
            action: 'update',
          }}
        >
          <Dropdown.Option onClick={handleOpenConciliationSuggestionsDrawer}>
            Sugestões de conciliação
            <Chip
              component="span"
              label={suggestionsCount}
              size="small"
              className={styles.chip}
            />
          </Dropdown.Option>
        </WithAllowedPermission>,
      ]}
    >
      <Badge badgeContent={suggestionsCount} color="primary" showZero>
        <DotsVerticalIcon />
      </Badge>
    </Dropdown>
  );
}
