import type { SxProps, Theme } from '@mui/material';
import { alertClasses } from '@mui/material';

export const alert: SxProps<Theme> = {
  border: (theme) => `1.5px solid ${theme.palette.warning[300]}`,

  bgcolor: (theme) => theme.palette.warning[25],
  color: (theme) => theme.palette.warning[600],

  mt: 6,

  [`.${alertClasses.message}`]: {
    width: '100%',
  },
};

export const alertTitle: SxProps<Theme> = {
  color: (theme) => theme.palette.warning[700],
};
