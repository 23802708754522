import { Button, Col, Divider, Row, Space, Tooltip, Typography } from 'antd';
import {
  ApiOutlined,
  DeleteOutlined,
  EditOutlined,
  ProfileOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { BankIcon } from 'src/mui/_scss';

import { Title, useOpenPortal } from 'src/ui';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';
import {
  setAccountsQueryKey,
  updateAccountsWithConnectionInCache,
} from 'src/libs/finbits/Bank/Accounts';
import {
  setConnectionsQueryKey,
  useActivateOpenBankingConnection,
} from 'src/libs/finbits/Bank/OpenBanking';
import { format } from 'src/libs/finbits/Date';
import type { OpenBankingConnection } from 'src/libs/finbits/Bank/OpenBanking/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import {
  WithAllowedPermission,
  useAllowedPermission,
} from 'src/libs/finbits/Permissions';
import { formatCNPJ } from 'src/libs/finbits/Documents';
import { useOpenPluggyWidget } from 'src/libs/pluggy/PluggyWidget';

import DeleteAccountModal from 'src/features/DEPRECATED_accounts/DeleteAccountModal';
import ConsentmentInfo from 'src/features/open-banking/ConsentmentInfo';
import BTGScopes from 'src/features/open-banking/BTGScopes/BTGScopes';

import AccountInfoIcon from '../AccountInfoIcon';
import AccountStatusTag from '../AccountStatusTag';

import styles from './AccountCard.module.less';

type Props = {
  account: Account;
  openEdit: (account: Account) => void;
  openImportOfx: () => void;
  openAccountCreation: () => void;
};

function getImportOfxTooltipTitle(account: Account) {
  const titles = {
    automatic: 'A importação de OFX não é possível neste tipo de conta',
    manual: 'Importar OFX',
    cashAccount: 'A importação de OFX não é possível neste tipo de conta',
  };

  if (account.type === 'cash_account') {
    return titles.cashAccount;
  }

  return titles[account.synchronizeType];
}

function AccountCard({
  account,
  openEdit,
  openImportOfx,
  openAccountCreation,
}: Props) {
  const { organizationId, companyId } = useCompanyParams();

  const openPortal = useOpenPortal();

  const canImportOfx = useAllowedPermission({
    action: 'create',
    resource: 'bankStatement',
  });
  const canEditAccount = useAllowedPermission({
    action: 'update',
    resource: 'accounts',
  });
  const canDeleteAccount = useAllowedPermission({
    action: 'delete_async',
    resource: 'accounts',
  });
  const canReconnectOpenBankingConnection = useAllowedPermission({
    action: 'activate',
    resource: 'openBankingConnections',
  });

  const isCashAccount = account.type === 'cash_account';

  const isBtgAccount = account.btgConnection !== null;

  const openPluggyWidget = useOpenPluggyWidget();

  function reconnect(connection: OpenBankingConnection) {
    if (connection.provider === 'pluggy') {
      openPluggyWidget({
        companyId,
        organizationId,
        errorModalHeader: 'Reconectar conta',
        connectionId: connection.id,
        connectionProviderId: connection.providerConnectionId,
        onCancel: () => {},
        onSuccess: () => activateCompanyConnection(connection.id),
      });
    } else {
      openAccountCreation();
    }
  }

  const queryClient = useQueryClient();
  const { activateConnection } = useActivateOpenBankingConnection();

  const isFailedConnection =
    account.openBankingConnection?.status === 'invalid_credentials' ||
    account.openBankingConnection?.status === 'mfa_required';

  const isDisabledImportOfx =
    !canImportOfx || isCashAccount || account.synchronizeType !== 'manual';

  function updateAccountsCache(connection: OpenBankingConnection) {
    queryClient.setQueryData(
      setAccountsQueryKey({ companyId, organizationId }),
      (prevAccounts: Account[] | undefined) =>
        updateAccountsWithConnectionInCache(prevAccounts, connection)
    );
  }

  function updateConnectionsCache(connection: OpenBankingConnection) {
    queryClient.setQueryData(
      setConnectionsQueryKey({ companyId, organizationId }),
      (connections: OpenBankingConnection[] | undefined) => {
        return (connections || []).map((prevConnection) => {
          if (prevConnection.id === connection.id) {
            return connection;
          }

          return prevConnection;
        });
      }
    );
  }

  function activateCompanyConnection(connectionId: string) {
    activateConnection(
      {
        organizationId,
        companyId,
        connectionId,
      },
      {
        onSuccess: (connection: OpenBankingConnection) => {
          updateAccountsCache(connection);
          updateConnectionsCache(connection);
        },
      }
    );
  }

  function openDeleteAccountModal() {
    openPortal(DeleteAccountModal, {
      organizationId: organizationId,
      companyId: companyId,
      account: account,
    });
  }

  return (
    <>
      <Row gutter={16}>
        <Col sm={2} lg={1}>
          <BankIcon routingNumber={account.routingNumber} />
        </Col>
        <Col>
          <Title className={styles.title} level={4}>
            {account.name}
          </Title>
        </Col>
      </Row>
      <Row gutter={[0, 24]}>
        <Col sm={2} lg={1} />
        <Col sm={22} lg={23}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col sm={22} lg={23} xl={9}>
              {isCashAccount ? (
                <Typography.Text>Conta não bancarizada</Typography.Text>
              ) : (
                <div>
                  <Typography.Text>{account.bankName}</Typography.Text>
                  <Divider type="vertical" />
                  <Space size={16}>
                    <Typography.Text>
                      Ag. <b>{account.branchNumber}</b>
                    </Typography.Text>
                    <Typography.Text>
                      Conta <b>{account.accountNumber}</b>
                    </Typography.Text>
                  </Space>
                </div>
              )}
              {isBtgAccount && (
                <div>
                  <ConsentmentInfo
                    consentment={account.btgConnection?.consentment}
                  />
                  {Boolean(account.btgConnection?.ownerDocument) && (
                    <>
                      <Divider type="vertical" />
                      <Typography.Text>
                        CNPJ da conta{' '}
                        <b>
                          {formatCNPJ(account.btgConnection?.ownerDocument!)}
                        </b>
                      </Typography.Text>
                    </>
                  )}
                </div>
              )}
            </Col>
            {isBtgAccount && (
              <Col sm={24} xl={8}>
                <BTGScopes
                  scopeAccountsReadonly={
                    account.btgConnection?.scopeAccountsReadonly!
                  }
                  scopeDda={account.btgConnection?.scopeDda!}
                  scopePayments={account.btgConnection?.scopePayments!}
                />
              </Col>
            )}
            <Col sm={24} xl={7} className={styles.status}>
              <Row justify="end" gutter={16}>
                <Col>
                  {isFailedConnection && (
                    <Tooltip title="Reconectar">
                      <Button
                        aria-label={`Reconectar à conta ${account.name}`}
                        size="large"
                        icon={<ApiOutlined />}
                        onClick={() =>
                          reconnect(account.openBankingConnection!)
                        }
                        disabled={!canReconnectOpenBankingConnection}
                      />
                    </Tooltip>
                  )}
                </Col>
                <Col>
                  <Tooltip
                    title={getImportOfxTooltipTitle(account)}
                    overlayInnerStyle={{ textAlign: 'center' }}
                  >
                    <Button
                      aria-label={`Importar OFX para a conta ${account.name}`}
                      size="large"
                      icon={<UploadOutlined />}
                      onClick={openImportOfx}
                      disabled={isDisabledImportOfx}
                    />
                  </Tooltip>
                </Col>
                <WithAllowedPermission
                  permissions={{
                    action: 'index',
                    resource: 'financialStatements',
                  }}
                >
                  <Col>
                    <Tooltip title="Ver extrato">
                      <Link
                        aria-label={`Ver extrato da conta ${account.name}`}
                        to={`/organizations/${organizationId}/companies/${companyId}/entries?accountId=${account.id}`}
                        className="ant-btn ant-btn-lg ant-btn-icon-only"
                      >
                        <ProfileOutlined />
                      </Link>
                    </Tooltip>
                  </Col>
                </WithAllowedPermission>
                <Col>
                  <Tooltip title="Editar conta">
                    <Button
                      aria-label={`Editar conta ${account.name}`}
                      icon={<EditOutlined />}
                      size="large"
                      disabled={!canEditAccount}
                      onClick={() => openEdit(account)}
                    />
                  </Tooltip>
                </Col>
                <Col>
                  <Tooltip title="Excluir conta">
                    <Button
                      aria-label={`Excluir conta ${account.name}`}
                      icon={<DeleteOutlined />}
                      size="large"
                      disabled={!canDeleteAccount}
                      onClick={openDeleteAccountModal}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </Row>
          {!isCashAccount && (
            <Row gutter={[16, 16]} justify="space-between" align="middle">
              <Col>
                <AccountStatusTag account={account} />
              </Col>
              {account.synchronizedAt && (
                <Col className={styles.synchronizedAt}>
                  {account.synchronizeType === 'automatic' ? (
                    <>
                      <AccountInfoIcon account={account} /> Última atualização:
                    </>
                  ) : (
                    'Último OFX importado: '
                  )}
                  {format(account.synchronizedAt, "dd/MM 'às' HH:mm")}
                </Col>
              )}
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
}

export default AccountCard;
