import { useMemo, useState } from 'react';

import { CalendarOutlined, CopyOutlined } from '@ant-design/icons';

import { snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';
import { CopyButton, Drawer, DrawerHeader, Loader, Title } from 'src/ui';

import type { ApiError, ApiErrorForm } from 'src/libs/finbits/client';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type { FinancialEntry } from 'src/libs/finbits/Management/FinancialEntries/types';
import { isStructuredFormError } from 'src/libs/finbits/Form';
import {
  useGetReceivable,
  useUpdateReceivable,
} from 'src/libs/finbits/Receivables';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';

import EntryForm from 'src/features/EntryForm';
import { receivableToFormValues } from 'src/features/EntryForm/formValues';
import type {
  EntryFormHiddenActions,
  EntryFormHiddenFields,
  SubmitParams,
} from 'src/features/EntryForm/types';
import { useOpenConfirmLeaveFormDialog } from 'src/features/entries/Dialogs';
import DeleteHeaderButton from 'src/features/receivables/EditReceivableDrawer/DeleteHeaderButton';
import ConciliateHeaderButton from 'src/features/receivables/EditReceivableDrawer/ConciliateHeaderButton';

type Props = {
  id: string;
  hiddenActions?: EntryFormHiddenActions;
  onManualReconciliate?: (financialEntry: FinancialEntry) => void;
} & PortalProps;

const HIDDEN_FIELDS: EntryFormHiddenFields = [
  'type',
  'frequencyInterval',
  'quantity',
  'approvalType',
  'assigneesIds',
];

const HIDDEN_ACTIONS: EntryFormHiddenActions = [
  'createReceivableWithNotaFiscal',
];

export default function EditReceivableDrawer({
  id,
  hiddenActions = HIDDEN_ACTIONS,
  onClose,
  onManualReconciliate,
  open = true,
  onExit,
}: Props) {
  const [errors, setErros] = useState<ApiErrorForm>();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { updateReceivable, isLoading: savingScheduledEntry } =
    useUpdateReceivable();

  const openConfirmLeaveDialog = useOpenConfirmLeaveFormDialog();

  const canEdit = useAllowedPermission({
    action: 'update',
    resource: 'receivables',
  });

  const { companyId, organizationId } = useCompanyParams();

  const { receivable, isLoading, isFetchedAfterMount } = useGetReceivable({
    organizationId,
    companyId,
    receivableId: id,
  });

  function handleUpdateReceivable({ attachments, ...params }: SubmitParams) {
    updateReceivable(
      {
        ...params,
        organizationId,
        companyId,
        categoryId: params.categoryId ?? null,
        contactId: params.contactId ?? null,
        attachmentsIds: attachments.map((a) => a.id),
        receivableId: id,
      },
      {
        onSuccess: () => {
          onClose();
          snackbar({
            variant: 'success',
            message: 'O lançamento programado foi salvo com sucesso!',
          });
        },
        onError: ({ response }: ApiError) => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao salvar o lançamento programado!',
          });

          if (isStructuredFormError(response?.data)) {
            setErros(response?.data.errors as ApiErrorForm);
          }
        },
      }
    );
  }

  function handleCloseDrawer() {
    if (isFormDirty) {
      return openConfirmLeaveDialog({
        onConfirm: onClose,
      });
    }

    return onClose();
  }

  const initialValues = useMemo(
    () => receivable && receivableToFormValues(receivable),
    [receivable]
  );

  return (
    <Drawer
      title={
        <DrawerHeader
          title={
            <Title icon={<CalendarOutlined />}>Recebimento programado</Title>
          }
          extra={
            <>
              <CopyButton
                icon={<CopyOutlined />}
                url={receivable?.publicId}
                text={'Copiar ID'}
              />
              {canEdit && (
                <>
                  <ConciliateHeaderButton
                    receivable={receivable}
                    onConciliate={onManualReconciliate}
                  />
                  <DeleteHeaderButton
                    receivable={receivable}
                    onCloseDrawer={onClose}
                  />
                </>
              )}
            </>
          }
        />
      }
      footer={null}
      onClose={handleCloseDrawer}
      visible={open}
      afterVisibleChange={onExit}
    >
      {isLoading || !isFetchedAfterMount || !initialValues ? (
        <Loader size="small" forceCentered />
      ) : (
        <EntryForm
          isFormDirty={isFormDirty}
          onFieldsChange={() => setIsFormDirty(true)}
          organizationId={organizationId}
          companyId={companyId}
          initialValues={initialValues}
          onSubmit={handleUpdateReceivable}
          loading={savingScheduledEntry}
          disabled={!canEdit}
          hiddenFields={HIDDEN_FIELDS}
          hiddenActions={hiddenActions}
          editableFields={receivable?.editableFields}
          errors={errors}
        />
      )}
    </Drawer>
  );
}
