import type { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
  fontFamily:
    '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  body1: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  body2: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  button: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  caption: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
  },
  subtitle1: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '28px',
  },
  subtitle2: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
  },
  overline: {
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: '0.5px',
    lineHeight: 2.5,
    textTransform: 'uppercase',
  },
  h1: {
    fontWeight: 400,
    fontSize: '72px',
    lineHeight: '90px',
    letterSpacing: '-0.02em',
  },
  h2: {
    fontWeight: 400,
    fontSize: '60px',
    lineHeight: '72px',
    letterSpacing: '-0.02em',
  },
  h3: {
    fontWeight: 400,
    fontSize: '48px',
    lineHeight: '60px',
    letterSpacing: '-0.02em',
  },
  h4: {
    fontWeight: 400,
    fontSize: '36px',
    lineHeight: '44px',
    letterSpacing: '-0.02em',
  },
  h5: {
    fontWeight: 400,
    fontSize: '30px',
    lineHeight: '38px',
  },
  h6: {
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '32px',
  },
};
