import type { GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium';
import { dateColumn } from 'src/mui/_scss';

import { format } from 'src/libs/finbits/Date';

import type { ColumnProps } from 'src/features/entries/EntriesDataGrid/columns/types';

export function requestedAtColumn<T extends GridValidRowModel>(
  columnProps?: ColumnProps<T>
): GridColDef<T> {
  return dateColumn({
    columnProps: {
      aggregable: false,
      editable: false,
      headerName: 'Solicitado em',
      field: 'requestedAt',
      valueGetter: (params) => params.row.lastApproval?.requestedAt,
      groupingValueGetter: (params) => {
        if (!params.row.lastApproval?.requestedAt) return 'Sem data';

        return format(params.row.lastApproval?.requestedAt, 'dd/MM/yyyy');
      },
      minWidth: 100,
      ...columnProps,
    },
  });
}
