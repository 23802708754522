import { Alert } from '@mui/material';
import { ClockIcon } from 'src/mui/_icons';
import cn from 'classnames';

import { Typography } from 'src/mui';

import styles from './OverdueAlert.module.scss';

type Props = {
  hasAutomatics?: boolean;
};

export default function OverdueAlert({ hasAutomatics = false }: Props) {
  return (
    <Alert
      variant="outlined"
      severity="warning"
      icon={<ClockIcon fontSize="inherit" />}
      classes={{
        root: cn({ [styles.alertWarningOrder]: hasAutomatics }),
      }}
    >
      <Typography fontSize={14} color="warning.700">
        <strong>Pagamentos em atraso </strong>
        só podem ser incluídos no banco de forma manual.
      </Typography>
    </Alert>
  );
}
