import { TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

import { RouteKey } from 'src/router/types';
import { generateCompanyPath } from 'src/router/routes';

import { snackbar } from 'src/mui';

import { useOpenConfirmDialog } from 'src/ui';

import { pluralize } from 'src/libs/finbits/Pluralize';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useRejectInboxItems } from 'src/libs/finbits/Management/NewInboxItems';

type Props = {
  inboxItemsIds: string[];
  onSuccess?: () => void;
};

export default function useInboxItemsActions({
  inboxItemsIds,
  onSuccess,
}: Props) {
  const { companyId, organizationId } = useCompanyParams();

  const { rejectInboxItems, isLoading } = useRejectInboxItems();
  const openConfirmDialog = useOpenConfirmDialog();

  const { control, getValues, setValue } = useForm<{
    rejectionReason: string;
  }>();

  function pluralizeItems(singular: string, plural: string) {
    return pluralize(inboxItemsIds.length, singular, plural);
  }

  function handleRejection() {
    const rejectionReason = getValues('rejectionReason');

    rejectInboxItems(
      {
        companyId,
        organizationId,
        inboxItemsIds,
        rejectionReason: rejectionReason,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: pluralizeItems('Item rejeitado', 'Itens rejeitados'),
          });

          setValue('rejectionReason', '');

          onSuccess && onSuccess();
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: `Ocorreu um erro ao rejeitar ${pluralizeItems(
              'item',
              'itens'
            )}`,
          });
        },
      }
    );
  }

  function openRejectionConfirmationDialog() {
    openConfirmDialog({
      variant: 'primary',
      title: pluralizeItems('Rejeitar item', 'Rejeitar itens'),
      content: (
        <>
          <Typography color="text.secondary" sx={{ mb: 4 }}>
            Ao rejeitar, {pluralizeItems('o item irá', 'os itens irão')}{' '}
            diretamente para a{' '}
            <Link
              to={{
                pathname: generateCompanyPath(RouteKey.INBOX_ITEMS, {
                  companyId,
                  organizationId,
                }),
                search: 'status=rejected',
              }}
              target="_blank"
            >
              área de rejeitados
            </Link>
            .
          </Typography>
          <Controller
            name="rejectionReason"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                rows={3}
                sx={{ mb: 3 }}
                label={
                  <Typography
                    color="text.secondary"
                    variant="subtitle2"
                    fontSize={14}
                  >
                    Motivo da rejeição (opcional)
                  </Typography>
                }
                placeholder="Você pode adicionar o motivo da rejeição aqui"
                InputProps={{
                  slotProps: {
                    input: {
                      style: {
                        paddingInline: 0,
                      },
                    },
                  },
                }}
              />
            )}
          />
        </>
      ),
      confirmText: 'Confirmar',
      cancelText: 'Cancelar',
      onConfirm: handleRejection,
      isConfirmLoading: isLoading,
    });
  }

  return {
    onRejectionMany: openRejectionConfirmationDialog,
  };
}
