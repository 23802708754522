import type {
  GridCellParams,
  GridColDef,
  GridGroupingValueGetterParams,
  GridValidRowModel,
  GridValueFormatterParams,
  GridValueGetterParams,
  GridValueSetterParams,
} from '@mui/x-data-grid-premium';
import type { DatePickerProps } from '@mui/x-date-pickers-pro';

import { format, isSameDay, isValid, parseISO } from 'src/libs/finbits/Date';

import DateInput from './DateInput';

type Props<T extends GridValidRowModel> = {
  columnProps: GridColDef<T>;
  datePickerProps?: DatePickerProps<Date>;
};

function parseDate(value: Date | string) {
  return typeof value === 'string' ? parseISO(value) : value;
}

export function hasChangeValidator(
  newValue: Date | null,
  oldValue: Date | string | null
) {
  if (newValue === null && oldValue === null) return false;

  if (newValue === null || oldValue === null) return true;

  const oldDate = parseDate(oldValue);

  const hasChange = !isSameDay(newValue, oldDate);

  return hasChange;
}

export function dateColumn<T extends GridValidRowModel>({
  columnProps,
  datePickerProps,
}: Props<T>): GridColDef<T> {
  return {
    type: 'date',
    valueGetter: ({ value }: GridValueGetterParams<T>) =>
      value && parseDate(value),
    getApplyQuickFilterFn: (value: string) => {
      if (!value) return null;

      return (params: GridCellParams<T, string | Date>): boolean => {
        if (!params) return false;

        return value.split(' ').some((splitFilter) => {
          if (!params.value) return false;

          const formatedDate = format(params?.value, 'dd/MM/yyyy');

          return formatedDate.includes(splitFilter);
        });
      };
    },
    valueFormatter: (params: GridValueFormatterParams<Date>) => {
      if (!params.value) return null;

      if (isValid(new Date(params.value)))
        return format(params.value, 'dd/MM/yyyy');

      return params.value;
    },
    groupingValueGetter: (params: GridGroupingValueGetterParams<T>) => {
      if (isValid(new Date(params.value)))
        return format(params.value, 'dd/MM/yyyy');

      return params.value;
    },
    renderEditCell: (props) => <DateInput {...datePickerProps} {...props} />,
    valueSetter: (params: GridValueSetterParams) => {
      return {
        ...params.row,
        [columnProps.field]: params.value,
        hasChangeValidator,
        updated: { [columnProps.field]: params.value },
      };
    },
    ...columnProps,
  };
}
