import { DeleteOutlined } from '@ant-design/icons';

import { snackbar } from 'src/mui';

import { DrawerHeaderButton, useOpenPortal } from 'src/ui';

import {
  useDeleteReceivable,
  useDeleteReceivableRecurrences,
} from 'src/libs/finbits/Receivables';
import type { Receivable } from 'src/libs/finbits/Receivables/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import { canPerformAction } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { AvailableActions } from 'src/libs/finbits/Management/Entries/types';

import DeleteScheduledRecurrenceModal from 'src/features/entries/DeleteScheduledRecurrenceModal';
import { useOpenConfirmDeletionDialog } from 'src/features/entries/Dialogs';

type Props = {
  receivable?: Receivable;
  onCloseDrawer: () => void;
};

export default function DeleteHeaderButton({
  receivable,
  onCloseDrawer,
}: Props) {
  const openConfirmDeletionDialog = useOpenConfirmDeletionDialog();
  const openPortal = useOpenPortal();

  const { companyId, organizationId } = useCompanyParams();
  const { deleteReceivable } = useDeleteReceivable();
  const { deleteReceivableRecurrences } = useDeleteReceivableRecurrences();

  const isRecurrent = Boolean(receivable?.scheduledRecurrence);

  function handleDelete() {
    deleteReceivable(
      {
        organizationId,
        companyId,
        receivableId: receivable?.id,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'Lançamento excluído com sucesso!',
          });

          onCloseDrawer();
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Falha ao excluir lançamento!',
          });
        },
      }
    );
  }

  function handleDeleteRecurrences() {
    deleteReceivableRecurrences(
      {
        organizationId,
        companyId,
        receivableId: receivable?.id,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'Lançamentos excluídos com sucesso!',
          });

          onCloseDrawer();
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Falha ao excluir lançamentos!',
          });
        },
      }
    );
  }

  function openDeleteConfirmation() {
    if (isRecurrent) {
      openPortal(DeleteScheduledRecurrenceModal, {
        onDeletedRecurrence: handleDeleteRecurrences,
        onDelete: handleDelete,
      });

      return;
    }

    openConfirmDeletionDialog({
      onConfirm: handleDelete,
    });
  }

  const canDelete =
    useAllowedPermission({ action: 'delete', resource: 'receivables' }) &&
    canPerformAction(receivable, AvailableActions.DELETE);

  if (!receivable) {
    return <DrawerHeaderButton icon={<DeleteOutlined />} disabled />;
  }

  if (!canDelete) {
    return null;
  }

  return (
    <DrawerHeaderButton
      icon={<DeleteOutlined />}
      title="Excluir"
      onClick={openDeleteConfirmation}
    />
  );
}
