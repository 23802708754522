import type { CSSProperties, Ref } from 'react';
import { forwardRef, useRef } from 'react';

import type { GridApi } from '@mui/x-data-grid-premium';
import {
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';
import { Stack } from '@mui/material';
import type { QuickActions, TableRootActions } from 'src/mui/_scss/Table/types';

import styles from './ToolbarQuickActions.module.scss';

type Props = {
  apiRef: GridApi;
  setCurrentButtonRef: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  TableRootActions?: TableRootActions;
  QuickActions?: QuickActions;
  style?: CSSProperties;
};

export default forwardRef(function ToolbarQuickActions(
  { apiRef, setCurrentButtonRef, TableRootActions, QuickActions, style }: Props,
  ref: Ref<HTMLDivElement>
) {
  const filterButtonEl = useRef(null);
  const columnButtonEl = useRef(null);

  return (
    <div ref={ref} style={style}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.stack}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          flexGrow={1}
          spacing={2}
        >
          <GridToolbarFilterButton
            componentsProps={{
              button: { size: 'medium' },
            }}
            ref={filterButtonEl}
            onMouseDown={() => {
              setCurrentButtonRef(filterButtonEl.current);
            }}
          />

          {QuickActions && <QuickActions />}

          <GridToolbarColumnsButton
            variant="text"
            size="medium"
            ref={columnButtonEl}
            onMouseDown={() => {
              setCurrentButtonRef(columnButtonEl.current);
            }}
          />
        </Stack>

        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          {TableRootActions && <TableRootActions apiRef={apiRef} />}
        </Stack>
      </Stack>
    </div>
  );
});
