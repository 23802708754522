import { useState } from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  FormControlLabel,
  Stack,
  Switch,
} from '@mui/material';
import { AlertTriangleIcon } from 'src/mui/_icons';

import { Typography, snackbar } from 'src/mui';

import { useOpenConfirmDialog } from 'src/ui';

import activeImg from 'src/assets/approval-rules-active.svg';
import deactiveImg from 'src/assets/approval-rules-deactive.svg';

import { useUpdateCompanyEnabledBillsApprovals } from 'src/libs/finbits/Organization/Companies/Options';
import {
  BillsApprovalsType,
  useCompany,
  useCompanyParams,
} from 'src/libs/finbits/Organization/Companies';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';

import styles from './ActiveApprovalCard.module.scss';

export default function ActiveApprovalCard() {
  const openConfirmDialog = useOpenConfirmDialog();

  const { companyId, organizationId } = useCompanyParams();

  const [isActive, setIsActive] = useState(false);

  const isAllowedUpdateConfig = useAllowedPermission({
    scope: 'company',
    action: 'update_bills_approvals',
    resource: 'companiesOptions',
  });

  useCompany(
    {
      companyId,
      organizationId,
    },
    {
      onSuccess(data) {
        setIsActive(
          Boolean(data?.billsApprovals !== BillsApprovalsType.DISABLED)
        );
      },
    }
  );

  const { updateCompanyEnabledBillsApprovals, isLoading } =
    useUpdateCompanyEnabledBillsApprovals();

  function updateEnabledBillsApprovals() {
    const newIsActive = !isActive;

    updateCompanyEnabledBillsApprovals(
      {
        companyId,
        organizationId,
        billsApprovals: newIsActive
          ? BillsApprovalsType.MANUAL
          : BillsApprovalsType.DISABLED,
      },
      {
        onSuccess: () => {
          setIsActive(newIsActive);
          snackbar({
            variant: 'success',
            message: `O fluxo de aprovação foi ${
              newIsActive ? 'ativado' : 'desativado'
            }.`,
          });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao salvar as alterações!',
          });
        },
      }
    );
  }

  function handleClick() {
    if (isActive) {
      return openConfirmDialog({
        variant: 'error',
        icon: <AlertTriangleIcon />,
        title: 'Deseja desativar o fluxo de aprovação?',
        content: (
          <Typography color="grey.600" paddingTop={4}>
            Ao desativar, lançamentos existentes nas situações "em aprovação",
            "aprovado" ou "reprovado" voltarão para o estado "a pagar".
          </Typography>
        ),
        confirmText: 'Desativar',
        cancelText: 'Voltar',
        onConfirm: updateEnabledBillsApprovals,
        isConfirmLoading: isLoading,
      });
    }

    return updateEnabledBillsApprovals();
  }

  return (
    <Card variant="outlined" className={styles.card}>
      <CardHeader
        title="1. Ative o fluxo de aprovação"
        titleTypographyProps={{ variant: 'subtitle2' }}
        className={styles.cardHeader}
      />
      <CardMedia
        component="img"
        alt="fluxo de aprovação"
        src={isActive ? activeImg : deactiveImg}
        className={styles.media}
      />
      <CardContent className={styles.cardContent}>
        <FormControlLabel
          className={styles.switch}
          checked={isActive}
          disabled={!isAllowedUpdateConfig}
          control={
            <Switch
              color="primary"
              inputProps={{
                'aria-label': 'fluxo de aprovacao',
              }}
            />
          }
          label={
            <Stack>
              <Typography fontWeight={500}>Fluxo de aprovação</Typography>
              <Typography color="grey.600">
                {isActive ? 'Ativado' : 'Desativado'}
              </Typography>
            </Stack>
          }
          labelPlacement="start"
          onChange={handleClick}
        />

        <Typography color="grey.600" marginTop={6}>
          {isActive
            ? 'Atualmente, as despesas desta empresa precisam ser aprovadas para serem enviadas para pagamento.'
            : 'Atualmente, as despesas desta empresa são enviadas para pagamento sem passar por aprovação.'}
        </Typography>
      </CardContent>
    </Card>
  );
}
