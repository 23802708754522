import { useCallback, useEffect } from 'react';

import { Button, Space, Typography } from 'antd';
import { ArrowLeftOutlined, WarningFilled } from '@ant-design/icons';

import { Loader, Modal, Title } from 'src/ui';

import logo from 'src/assets/logo.svg';
import icon from 'src/assets/icon-black.svg';

import type { EventData, ExitData } from 'src/libs/belvo/BelvoWidget/sdk';
import { createWidget, useLoadSDK } from 'src/libs/belvo/BelvoWidget/sdk';
import { useCreateWidgetSession } from 'src/libs/finbits/Bank/OpenBanking';
import { Provider } from 'src/libs/finbits/Bank/OpenBanking/types';

import type { WidgetProps } from '../types';

import styles from './ConnectWidget.module.less';

type Props = WidgetProps & {
  onClose: () => void;
};

function ConnectWidget({
  organizationId,
  companyId,
  connectionId,
  institutionCodes,
  onSuccess,
  onCancel,
  errorModalHeader,
  onClose,
}: Props) {
  const sdkStatus = useLoadSDK();
  const {
    createWidgetSession,
    data,
    isSuccess: isTokenSuccess,
    isError: isTokenError,
  } = useCreateWidgetSession();

  const onCancelError = useCallback(() => {
    onCancel();
    onClose();
  }, [onCancel, onClose]);

  useEffect(() => {
    createWidgetSession({
      organizationId,
      companyId,
      connectionId,
      provider: Provider.BELVO,
      appName: 'Finbits',
      appIcon: window.location.origin + icon,
      appLogo: window.location.origin + logo,
      benefitHeader: 'Segurança e agilidade',
      benefitContent:
        'Em breve a conexão estará concluída e sua gestão financeira mais ágil e eficiente.',
      opportunityLoss:
        'A atualização automática da conta bancária permite que o Finbits agilize sua gestão financeira.',
    });
  }, [createWidgetSession, organizationId, companyId, connectionId]);

  const isReady = sdkStatus === 'ready' && isTokenSuccess;

  useEffect(() => {
    if (!isReady || !data?.token || !data?.externalId) return;

    function onSuccessCallback(linkId: string, institution: string) {
      if (import.meta.env.VITE_OPEN_BANKING_DEBUG) {
        console.debug('belvo::onSuccess', { linkId, institution });
      }

      onSuccess(linkId, institution);
      onClose();
    }

    function onExitCallback(data: ExitData) {
      if (import.meta.env.VITE_OPEN_BANKING_DEBUG) {
        console.debug('belvo::onExit', data);
      }

      onCancel();
      onClose();
    }

    function onEventCallback(data: EventData) {
      if (import.meta.env.VITE_OPEN_BANKING_DEBUG) {
        console.debug('belvo::onEvent', data);
      }
    }

    const widget = createWidget({
      accessToken: data.token,
      accessMode: 'recurrent',
      externalId: data.externalId,
      countryCodes: ['BR'],
      institutions: institutionCodes,
      institutionTypes: ['retail', 'business'],
      locale: 'pt',
      onSuccess: onSuccessCallback,
      onExit: onExitCallback,
      onEvent: onEventCallback,
    });

    widget.build();
  }, [
    onClose,
    onSuccess,
    onCancel,
    isReady,
    institutionCodes,
    data?.token,
    data?.externalId,
  ]);

  const isError = sdkStatus === 'error' || isTokenError;

  return (
    <>
      <Modal
        visible={!isReady}
        footer={null}
        className={styles.modal}
        onCancel={onCancelError}
        destroyOnClose
      >
        {isError ? (
          <>
            <div className={styles.modalHeader}>
              <Button
                icon={<ArrowLeftOutlined />}
                size="large"
                type="text"
                onClick={onCancelError}
              />
              <Title level={4}>{errorModalHeader}</Title>
            </div>

            <Space
              direction="vertical"
              size="large"
              className={styles.errorBody}
            >
              <WarningFilled className={styles.errorIcon} />
              <Typography.Text>
                Ocorreu um erro na comunicação com nosso servidor. Tente
                novamente em instantes.
              </Typography.Text>
              <Button onClick={onCancelError} type="primary" size="large">
                Voltar
              </Button>
            </Space>
          </>
        ) : (
          <div data-testid="belvo-loader">
            <Loader />
          </div>
        )}
      </Modal>
      <div id="belvo" data-testid="belvo-container"></div>
    </>
  );
}

export default ConnectWidget;
