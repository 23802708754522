import type { GridRowParams } from '@mui/x-data-grid-premium';
import { GridActionsCellItem } from '@mui/x-data-grid-premium';
import { RefreshIcon, ShareIcon } from 'src/mui/_icons';

import { useOpenPortal } from 'src/ui';

import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

import { useOpenConfirmRestore } from 'src/features/entries/Dialogs';

import EditBillDrawer from '../EditBillDrawer';

import { useRestoreEntry } from './useRestoreEntry';

export function RowActions({
  row,
}: Pick<GridRowParams<FinancialStatementEntry>, 'row'>) {
  const openPortal = useOpenPortal();

  const { handleRestoreSelection } = useRestoreEntry({ selecteds: [row] });
  const confirmRestoreEntry = useOpenConfirmRestore();

  function handleClick() {
    confirmRestoreEntry({
      onConfirm: handleRestoreSelection,
    });
  }

  function handleOpenDrawer() {
    openPortal(EditBillDrawer, {
      billId: row.id,
      getParams: { includingDeleted: true },
    });
  }

  return [
    <GridActionsCellItem
      name="openDrawer"
      icon={<ShareIcon />}
      label="Abrir lançamento"
      onClick={handleOpenDrawer}
      key="openDrawer"
      showInMenu
    />,
    <GridActionsCellItem
      name="restoreEntry"
      icon={<RefreshIcon />}
      label="Restaurar"
      onClick={handleClick}
      key="restoreEntry"
      showInMenu
    />,
  ];
}
