import { useCallback, useEffect } from 'react';

import isArray from 'lodash/isArray';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { AlertCircleIcon } from 'src/mui/_icons';

import { TextField, snackbar } from 'src/mui';

import { useOpenConfirmDialog } from 'src/ui';

import {
  cellphoneMask,
  e164Format,
} from 'src/libs/finbits/Organization/PhoneNumber';
import { errorsToFormField } from 'src/libs/finbits/Form';
import {
  useAcceptInvitation,
  useInvitation,
} from 'src/libs/finbits/Organization/Invitation';
import type { AcceptInvitationForm } from 'src/libs/finbits/Organization/Invitation/types';
import { AcceptInvitationFormResolver } from 'src/libs/finbits/Organization/Invitation/types';

import TermsOfUseModal from 'src/features/terms-of-use/TermsOfUseModal';
import PrivacyPolicyModal from 'src/features/terms-of-use/PrivacyPolicyModal';

type Params = { organizationId: string };

function useInviteUsedDialog() {
  const openConfirmDialog = useOpenConfirmDialog();
  const navigate = useNavigate();

  return useCallback(() => {
    openConfirmDialog({
      variant: 'error',
      icon: <AlertCircleIcon />,
      title: 'Ops! Conta existente',
      content:
        'Uma conta já foi criada para esse convite. Deseja logar na plataforma?',
      onConfirm: () => navigate('/login'),
      confirmText: 'Fazer login',
      closable: false,
    });
  }, [openConfirmDialog, navigate]);
}

function UserSignUp() {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
  } = useForm<AcceptInvitationForm>({
    defaultValues: {
      email: '',
      name: '',
      authentication: {
        password: '',
      },
    },
    resolver: zodResolver(AcceptInvitationFormResolver),
  });

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const openInviteUsedDialog = useInviteUsedDialog();

  const { acceptInvitation, isLoading } = useAcceptInvitation();

  const { organizationId } = useParams<Params>();
  const invitationCode = searchParams.get('i');

  const { isSuccess } = useInvitation(
    {
      invitationCode,
      organizationId,
    },
    {
      onError: () => {
        openInviteUsedDialog();
      },
      onSuccess: ({ email }) => {
        setValue('email', email);
      },
    }
  );

  const isDisabled = !isSuccess;

  useEffect(() => {
    if (!invitationCode) {
      openInviteUsedDialog();
    }
  }, [invitationCode, openInviteUsedDialog]);

  function handleAcceptInvitation({
    name,
    authentication,
    phone,
  }: AcceptInvitationForm) {
    acceptInvitation(
      {
        name,
        password: authentication.password,
        phone: { number: e164Format(phone.number) },
        invitationCode,
        organizationId,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message:
              'Sua conta foi criada com sucesso! Faça o login para entrar.',
          });

          navigate('/login');
        },
        onError: ({ response }) => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao criar sua conta.',
          });

          if (response?.data) {
            const errors = errorsToFormField(response.data.errors);

            errors.map(({ name, errors }) => {
              const field = isArray(name) ? name.join('.') : name;
              const message = errors.join(', ');
              return setError(field as any, { message });
            });
          }
        },
      }
    );
  }

  return (
    <Box sx={{ mt: 6, mb: 16 }}>
      <Typography
        variant="h5"
        component="h2"
        sx={{ mb: 4, textAlign: 'center', fontWeight: '600' }}
      >
        Olá!
      </Typography>
      <Typography variant="body1" sx={{ mb: 4, textAlign: 'center' }}>
        Você foi convidado para colaborar no controle financeiro da{' '}
        <b>empresa</b>. Preencha os dados abaixo:
      </Typography>

      <Box component="form" onSubmit={handleSubmit(handleAcceptInvitation)}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={isDisabled}
              error={!!errors.name}
              helperText={errors.name?.message}
              label="Seu nome"
              margin="normal"
              placeholder="Insira seu nome"
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              disabled
              error={!!errors.email}
              helperText={errors.email?.message}
              label="Endereço de e-mail"
              margin="normal"
            />
          )}
        />
        <Controller
          name="phone.number"
          control={control}
          render={({ field }) => (
            <TextField.Masked
              {...field}
              disabled={isDisabled}
              error={!!errors.phone?.number}
              helperText={errors.phone?.number?.message}
              label="Celular (opcional)"
              margin="normal"
              mask={[cellphoneMask]}
              placeholder="(11) 90000-0000"
            />
          )}
        />
        <Controller
          name="authentication.password"
          control={control}
          render={({ field }) => (
            <TextField.Password
              {...field}
              disabled={isDisabled}
              error={!!errors.authentication?.password}
              helperText={errors.authentication?.password?.message}
              label="Senha"
              margin="normal"
              placeholder="••••••••"
              type="password"
            />
          )}
        />
        <Controller
          name="terms"
          control={control}
          render={({ field }) => (
            <Box sx={{ mt: 4 }}>
              <FormControlLabel
                control={<Checkbox {...field} />}
                label={
                  <Typography variant="body1">
                    Estou ciente e concordo com os{' '}
                    <TermsOfUseModal label="Termos de Uso" /> e a{' '}
                    <PrivacyPolicyModal label="Política de Privacidade" /> da
                    Finbits
                  </Typography>
                }
              />
              <FormHelperText error>{errors.terms?.message}</FormHelperText>
            </Box>
          )}
        />

        <LoadingButton
          color="primary"
          fullWidth
          loading={isLoading}
          sx={{ mt: 6 }}
          type="submit"
          variant="contained"
          size="large"
        >
          Concluir
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default UserSignUp;
