import type { AxiosError } from 'axios';
import type { QueryClient, UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import throttle from 'lodash/throttle';

import type { ApiError } from 'src/libs/finbits/client';
import {
  authenticatedAPI,
  decodeResponse,
  withEmptyArrayDefault,
} from 'src/libs/finbits/client';
import type { OpenBankingConnection } from 'src/libs/finbits/Bank/OpenBanking/types';
import { FIVE_SECONDS_IN_MS } from 'src/libs/finbits/Time';

import { AccountDecoder, AccountsDecoder } from './types';
import type {
  Account,
  DeleteParams,
  GetAccountParams,
  GetParams,
  PatchParams,
  PostParams,
} from './types';

export enum AccountTypeLabel {
  CHECKING_ACCOUNT = 'checking_account',
  SAVINGS_ACCOUNT = 'savings_account',
}

export const ACCOUNT_TYPE_DICTIONARY = {
  [AccountTypeLabel.CHECKING_ACCOUNT]: 'Conta Corrente',
  [AccountTypeLabel.SAVINGS_ACCOUNT]: 'Conta Poupança',
};

function invalidateAccountsQueriesCall(queryClient: QueryClient) {
  queryClient.invalidateQueries(['accounts']);
  queryClient.invalidateQueries(['accountsBalances']);
}

export const invalidateAccountsQueries = throttle(
  invalidateAccountsQueriesCall,
  FIVE_SECONDS_IN_MS
);

export async function getCompanyAccounts({
  companyId,
  organizationId,
}: GetParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/accounts`
  );

  return decodeResponse<Account[]>(response, AccountsDecoder);
}

export async function getCompanyAccount({
  companyId,
  organizationId,
  accountId,
}: GetAccountParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/accounts/${accountId}`
  );

  return decodeResponse<Account>(response, AccountDecoder);
}

export async function postCompanyAccount({
  companyId,
  organizationId,
  ...params
}: PostParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/accounts`,
    params
  );
  return decodeResponse<Account>(response, AccountDecoder);
}

export async function patchCompanyAccount({
  accountId,
  companyId,
  organizationId,
  ...params
}: PatchParams) {
  const response = await authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/accounts/${accountId}`,
    params
  );

  return decodeResponse<Account>(response, AccountDecoder);
}

export async function postAccountDelete({
  organizationId,
  companyId,
  accountId,
  ...params
}: DeleteParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/accounts/${accountId}/delete_async`,
    params
  );

  return response;
}

export function setAccountsQueryKey({ companyId, organizationId }: GetParams) {
  return ['accounts', { companyId, organizationId }];
}

export function setAccountQueryKey({
  companyId,
  organizationId,
  accountId,
}: GetAccountParams) {
  return ['accounts', { companyId, organizationId, accountId }];
}

export function useAccounts(
  { companyId, organizationId }: GetParams,
  customOptions: Partial<UseQueryOptions<Account[], ApiError>> = {}
) {
  const { data, ...rest } = useQuery<Account[], ApiError>({
    enabled: !!companyId && !!organizationId,
    queryKey: setAccountsQueryKey({ companyId, organizationId }),
    queryFn: () => getCompanyAccounts({ companyId, organizationId }),
    ...customOptions,
  });

  return { accounts: withEmptyArrayDefault(data), ...rest };
}

export function useAccount(
  { companyId, organizationId, accountId }: GetAccountParams,
  customOptions: Partial<UseQueryOptions<Account, ApiError>> = {}
) {
  const { data, ...rest } = useQuery<Account, ApiError>({
    enabled: !!companyId && !!organizationId && !!accountId,
    queryKey: setAccountQueryKey({ companyId, organizationId, accountId }),
    queryFn: () => getCompanyAccount({ companyId, organizationId, accountId }),
    ...customOptions,
  });

  return { account: data, ...rest };
}

export function useAddAccount() {
  const queryClient = useQueryClient();

  const { mutate: addAccount, ...rest } = useMutation<
    Account,
    AxiosError<{
      message: string;
      errors: {
        [key: string]: string[];
      };
    }>,
    PostParams
  >(postCompanyAccount, {
    onSuccess: () => {
      invalidateAccountsQueries(queryClient);
    },
  });
  return { addAccount, ...rest };
}

export function useUpdateAccount() {
  const queryClient = useQueryClient();
  const { mutate: updateAccount, ...rest } = useMutation<
    Account,
    AxiosError<{
      message: string;
      errors: {
        [key: string]: string[];
      };
    }>,
    PatchParams
  >(patchCompanyAccount, {
    onSuccess: () => {
      invalidateAccountsQueries(queryClient);
    },
  });
  return { updateAccount, ...rest };
}

export function useAccountDelete() {
  const queryClient = useQueryClient();
  const { mutate: deleteAccount, ...rest } = useMutation<
    unknown,
    ApiError,
    DeleteParams
  >(postAccountDelete, {
    onSuccess: () => {
      invalidateAccountsQueries(queryClient);
    },
  });
  return { deleteAccount, ...rest };
}

export function updateAccountsWithConnectionInCache(
  prevAccounts: Account[] | undefined,
  updatedConnection: OpenBankingConnection
) {
  const nextAccounts = prevAccounts
    ? prevAccounts.map((prevAccount) => {
        if (prevAccount.openBankingConnection?.id === updatedConnection.id) {
          return { ...prevAccount, openBankingConnection: updatedConnection };
        }
        return prevAccount;
      })
    : [];

  return nextAccounts;
}
