import type { SxProps, Theme } from '@mui/material';
import { alertClasses } from '@mui/material';

export const alert: SxProps<Theme> = {
  [`.${alertClasses.message}`]: {
    overflow: 'visible',
  },
};

export const alertTitle: SxProps<Theme> = { mb: '12px', lineHeight: '24px' };

export const noMatchAlert: SxProps<Theme> = {
  fontSize: '14px',
  marginTop: '28px',
  marginLeft: '-32px',
};
