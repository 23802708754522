import { useScript } from 'src/libs/finbits/hooks';

export type AccessMode = 'access_mode' | 'recurrent';
export type CountryCode = 'BR' | 'CO' | 'MX';
export type InstitutionType = 'retail' | 'business' | 'gig' | 'fiscal';
export type Locale = 'en' | 'es' | 'pt';

export type ExitData = {
  last_encountered_error: null | {
    code: string;
    message: string;
  };
  meta_data: {
    step: string;
    institution_name: string;
  };
};

export type EventData = {
  eventName: string;
  meta_data: null | Record<string, string>;
};

export type WidgetConfig = {
  accessToken: string;
  accessMode: AccessMode;
  externalId: string;
  countryCodes: CountryCode[];
  institutions: string[];
  institutionTypes: InstitutionType[];
  locale: Locale;
  onSuccess: (linkId: string, institution: string) => void;
  onExit: (data: ExitData) => void;
  onEvent: (data: EventData) => void;
};

export type SDKWidgetConfig = {
  access_mode: AccessMode;
  external_id: string;
  country_codes: CountryCode[];
  institutions: string[];
  institution_types: InstitutionType[];
  locale: Locale;
  callback: (linkId: string, institution: string) => void;
  onExit: (data: ExitData) => void;
  onEvent: (data: EventData) => void;
};

type Widget = {
  build: () => void;
};

type BelvoSDK = {
  createWidget: (accessToken: string, config: SDKWidgetConfig) => Widget;
};

export type AugmentedWindow = Window &
  typeof globalThis & {
    belvoSDK: BelvoSDK;
  };

export function useLoadSDK() {
  return useScript('https://cdn.belvo.io/belvo-widget-1-stable.js');
}

export function createWidget(config: WidgetConfig) {
  return (window as AugmentedWindow).belvoSDK.createWidget(config.accessToken, {
    access_mode: config.accessMode,
    external_id: config.externalId,
    country_codes: config.countryCodes,
    institutions: config.institutions,
    institution_types: config.institutionTypes,
    locale: config.locale,
    callback: config.onSuccess,
    onExit: config.onExit,
    onEvent: config.onEvent,
  });
}
