import { useState } from 'react';

import type { TransitionProps } from '@mui/material/transitions';
import type { DialogProps } from '@mui/material';
import { DialogContent, Slide } from '@mui/material';
import { CloseIcon } from 'src/mui/_icons';

import { Dialog, IconButton, Typography } from 'src/mui';

import { useOpenConfirmDialog } from 'src/ui';
import type { PortalProps } from 'src/ui';

import AccountForm from './AccountForm';
import Introductory from './Introductory';
import styles from './AccountModal.module.scss';

type Props = DialogProps & {
  accountsExists?: boolean;
} & PortalProps;

export default function AccountModal({
  accountsExists = false,
  onClose,
  onExit,
  open,
}: Props) {
  const [initAddAccount, setInitAddAccount] = useState(accountsExists);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const openConfirmDialog = useOpenConfirmDialog();

  function handleClose() {
    if (initAddAccount && isDirty) {
      return openConfirmDialog({
        variant: 'error',
        title: 'Tem certeza que deseja sair?',
        content: (
          <Typography variant="body1" color="text.secondary">
            Ao sair, as informações preenchidas serão descartadas.
          </Typography>
        ),
        confirmText: 'Sair',
        cancelText: 'Continuar',
        onConfirm: onClose,
      });
    }

    return onClose();
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        <IconButton
          aria-label="Fechar modal de criação de conta"
          onClick={handleClose}
          className={styles.closeIcon}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
        {initAddAccount ? (
          <AccountForm onClose={onClose} onDirty={setIsDirty} />
        ) : (
          <Introductory
            onClose={onClose}
            onStart={() => setInitAddAccount(true)}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
