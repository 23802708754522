import type { Components } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiDivider({
  palette,
}: ThemeConfig): Components['MuiDivider'] {
  const dividerBorderColor =
    palette.mode === 'light' ? palette.grey?.[200] : palette.grey?.[600];

  return {
    styleOverrides: { root: { borderColor: dividerBorderColor } },
  };
}
