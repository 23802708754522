import { useCallback } from 'react';

import { AlertCircleIcon, ClockRewindIcon, TrashIcon } from 'src/mui/_icons';

import { Typography } from 'src/mui';

import { useOpenConfirmDialog } from 'src/ui';

type Props = {
  onConfirm: () => void;
};

export function useOpenConfirmLeaveFormDialog() {
  const openConfirmDialog = useOpenConfirmDialog();

  return useCallback(
    (props: Props) => {
      openConfirmDialog({
        variant: 'error',
        icon: <AlertCircleIcon />,
        title: 'Tem certeza que deseja sair?',
        content: 'Ao sair sem salvar, as alterações serão perdidas.',
        confirmText: 'Sim, quero sair',
        cancelText: 'Cancelar',
        ...props,
      });
    },
    [openConfirmDialog]
  );
}

export function useOpenConfirmDeletionDialog() {
  const openConfirmDialog = useOpenConfirmDialog();

  return useCallback(
    (props: Props) => {
      openConfirmDialog({
        variant: 'error',
        icon: <TrashIcon />,
        title: 'Tem certeza que deseja excluir?',
        content: 'As informações serão excluídas permanentemente.',
        confirmText: 'Sim, quero excluir',
        cancelText: 'Cancelar',
        ...props,
      });
    },
    [openConfirmDialog]
  );
}

export function useOpenConfirmSoftDeleteDialog() {
  const openConfirmDialog = useOpenConfirmDialog();

  return useCallback(
    (props: Props) => {
      openConfirmDialog({
        variant: 'error',
        icon: <TrashIcon />,
        title: 'Excluir',
        content: (
          <Typography variant="body1">
            Ao excluir, o item será movido para a aba de <b>excluídos</b>, onde
            o pagamento correspondente não será realizado nem exibido no seu
            fluxo de caixa.
          </Typography>
        ),
        confirmText: 'Excluir',
        cancelText: 'Cancelar',
        ...props,
      });
    },
    [openConfirmDialog]
  );
}

export function useOpenConfirmUndoConciliationDialog() {
  const openConfirmDialog = useOpenConfirmDialog();

  return useCallback(
    (props: Props) => {
      openConfirmDialog({
        variant: 'error',
        icon: <ClockRewindIcon />,
        title: 'Tem certeza que deseja desfazer?',
        content:
          'O lançamento que foi conciliado será restaurado e voltará para a lista de programados.',
        confirmText: 'Sim, quero desfazer',
        cancelText: 'Cancelar',
        ...props,
      });
    },
    [openConfirmDialog]
  );
}

export function useOpenConfirmRestore() {
  const openConfirmDialog = useOpenConfirmDialog();

  return useCallback(
    (props: Props) => {
      openConfirmDialog({
        variant: 'warning',
        icon: <ClockRewindIcon />,
        title: 'Restaurar',
        content: (
          <Typography variant="body1">
            Ao restaurar, o item volta para contas a pagar.
          </Typography>
        ),
        confirmText: 'Restaurar',
        cancelText: 'Cancelar',
        ...props,
      });
    },
    [openConfirmDialog]
  );
}
