import type { GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium';
import { enumColumn } from 'src/mui/_scss/Table/Columns/enumColumn/enumColumn';

import { PAYMENT_METHOD_DICTIONARY } from 'src/libs/finbits/PaymentMethods/translations';

import type { ColumnProps } from 'src/features/entries/EntriesDataGrid/columns/types';
import PaymentMethodSelect from 'src/features/payments/PaymentMethodSelect';

export function paymentMethodColumn<T extends GridValidRowModel>(
  columnProps?: ColumnProps<T>
): GridColDef<T> {
  return enumColumn({
    enumTranslation: PAYMENT_METHOD_DICTIONARY,
    SelectComponent: PaymentMethodSelect,
    columnProps: {
      ...columnProps,
      headerName: 'Meio de pagamento',
      field: 'paymentDetails',
      resizable: true,
      editable: false,
      valueGetter: (params) => params.value?.paymentMethod,
    },
  });
}
