import { Chip } from '@mui/material';
import {
  LayerSingleIcon,
  LayersThreeIcon,
  LayersTwoIcon,
} from 'src/mui/_icons';

import { Tooltip } from 'src/mui';

import styles from './PositionChip.module.scss';

type Props = { position: number; isFirst?: boolean; defaultRule?: boolean };

function getIcon(isFirst: boolean, defaultRule: boolean) {
  if (defaultRule) {
    return <LayerSingleIcon fontSize="xsmall" />;
  }

  if (!isFirst) {
    return <LayersTwoIcon fontSize="xsmall" />;
  }

  return <LayersThreeIcon fontSize="xsmall" />;
}

export default function PositionChip({
  position,
  isFirst = false,
  defaultRule = false,
}: Props) {
  const label = position < 10 ? `0${position}` : position;

  return (
    <Tooltip title="Posição desta regra na ordem de prioridade de aplicação.">
      <Chip
        classes={{ icon: styles.icon }}
        role="chip"
        aria-label="position"
        icon={getIcon(isFirst, defaultRule)}
        label={label}
        size="small"
        className={styles.chip}
      />
    </Tooltip>
  );
}
