import type { ReactNode } from 'react';

import type { ChipProps } from '@mui/material';
import { Chip } from '@mui/material';

type Props = {
  windows: ReactNode;
  macOS: ReactNode;
} & ChipProps;

export default function KeyboardShortcut({
  windows,
  macOS,
  size = 'small',
  sx,
  ...rest
}: Props) {
  const label = navigator.userAgent.includes('Mac') ? macOS : windows;

  return (
    <Chip
      label={label}
      size={size}
      sx={{
        padding: 0,
        borderRadius: 1,
        ...sx,
      }}
      {...rest}
    />
  );
}
