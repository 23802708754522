import { Fade, Stack, Toolbar, Tooltip, Typography } from '@mui/material';
import cn from 'classnames';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  DownloadCloudIcon,
  ExpandIcon,
  RotateLeftIcon,
  RotateRightIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from 'src/mui/_icons';

import { IconButton, snackbar } from 'src/mui';

import type { Attachment } from 'src/libs/finbits/Management/Attachments/types';
import { useDownloadAttachment } from 'src/libs/finbits/Management/Attachments';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import { useBillPreviewContext } from 'src/features/bills-to-pay/BillContent/BillPreviewProvider';

import styles from './ToolbarActions.module.scss';

type Props = {
  zoom: number;
  attachment: Attachment;
  onRotateLeft: () => void;
  onRotateRight: () => void;
  onZoomIn: () => void;
  onZoomOut: () => void;
  isPreviewError?: boolean;
};

export default function ToolbarActions({
  zoom,
  attachment,
  onRotateLeft,
  onRotateRight,
  onZoomIn,
  onZoomOut,
  isPreviewError = false,
}: Props) {
  const { companyId, organizationId } = useCompanyParams();

  const { downloadAttachment, isDownloading } = useDownloadAttachment();

  const {
    pageNumber,
    numPages = 0,
    isExpandedPreview,
    isToolbarVisible,
    toggleExpansionPreview,
    handleNextPage,
    handlePreviousPage,
  } = useBillPreviewContext();

  function handleDownload() {
    downloadAttachment(
      {
        companyId,
        organizationId,
        attachmentId: attachment.id,
      },
      {
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Falha ao fazer o download, tente novamente.',
          });
        },
      }
    );
  }

  return (
    <Fade in={isToolbarVisible}>
      <Toolbar variant="dense" className={styles.toolbar}>
        <Stack direction="row">
          {numPages > 1 && (
            <Stack direction="row" alignItems="center">
              <Typography
                className={styles.typography}
                fontSize={12}
                fontWeight={600}
                noWrap
              >
                Página {pageNumber} de {numPages}
              </Typography>
              <IconButton
                className={styles.action}
                aria-label="Página anterior"
                onClick={handlePreviousPage}
                disabled={pageNumber === 1}
              >
                <ArrowLeftIcon />
              </IconButton>
              <IconButton
                className={styles.action}
                aria-label="Próxima página"
                onClick={handleNextPage}
                disabled={pageNumber >= numPages}
              >
                <ArrowRightIcon />
              </IconButton>
            </Stack>
          )}
          <IconButton
            className={styles.action}
            onClick={onZoomIn}
            aria-label="Aplicar zoom"
            disabled={zoom >= 5 || isPreviewError}
          >
            <ZoomInIcon />
          </IconButton>
          <IconButton
            className={styles.action}
            onClick={onZoomOut}
            aria-label="Remover zoom"
            disabled={zoom <= 0.4 || isPreviewError}
          >
            <ZoomOutIcon />
          </IconButton>
          <IconButton
            className={styles.action}
            onClick={onRotateLeft}
            aria-label="Rotacionar para esquerda"
            disabled={isPreviewError}
          >
            <RotateLeftIcon />
          </IconButton>
          <IconButton
            className={styles.action}
            onClick={onRotateRight}
            aria-label="Rotacionar para direita"
            disabled={isPreviewError}
          >
            <RotateRightIcon />
          </IconButton>
          <Tooltip title={isExpandedPreview ? 'Minimizar' : 'Expandir'}>
            <IconButton
              aria-label={isExpandedPreview ? 'Minimizar' : 'Expandir'}
              onClick={toggleExpansionPreview}
              className={cn(styles.action, {
                [styles.activeAction]: isExpandedPreview,
              })}
            >
              <ExpandIcon />
            </IconButton>
          </Tooltip>
          <IconButton.Loading
            className={styles.action}
            loading={isDownloading}
            onClick={handleDownload}
            aria-label="Download"
          >
            <DownloadCloudIcon />
          </IconButton.Loading>
        </Stack>
      </Toolbar>
    </Fade>
  );
}
