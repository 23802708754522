import { Button, Stack, Typography } from '@mui/material';
import {
  FlipBackwardIcon,
  IntersectSquareIcon,
  PlusIcon,
  TrashIcon,
} from 'src/mui/_icons';

import type { NewInboxItem } from 'src/libs/finbits/Management/NewInboxItems/types';

import InboxItemMenuWithIconButton from 'src/features/inbox-items/InboxItemMenu/InboxItemMenuWithIconButton';
import { useInboxItemsParams } from 'src/features/inbox-items/useInboxItemsParams';
import useInboxItemActions from 'src/features/inbox-items/useInboxItemActions';

import { button, typography } from './Actions.sx';

type Props = { inboxItem?: NewInboxItem };

export default function Actions({ inboxItem }: Props) {
  const { closeInboxItem } = useInboxItemsParams();

  const {
    onConciliation,
    onCreateEntry,
    onRestore,
    onRejection,
    isAllowedCreateBill,
    isAllowedUpdateBill,
    isAllowedRejectManyInboxItems,
    isAllowedRestoreManyInboxItems,
  } = useInboxItemActions({ inboxItemId: inboxItem?.id });

  const isRejected = inboxItem?.status === 'rejected';

  return (
    <Stack direction="row" alignItems="center">
      {isAllowedCreateBill && (
        <Button sx={button} onClick={onCreateEntry} startIcon={<PlusIcon />}>
          <Typography sx={typography}>Criar lançamento</Typography>
        </Button>
      )}

      {isAllowedUpdateBill && (
        <Button
          sx={button}
          onClick={onConciliation}
          startIcon={<IntersectSquareIcon />}
        >
          <Typography sx={typography}>Vincular à lançamento</Typography>
        </Button>
      )}

      {isRejected && isAllowedRestoreManyInboxItems && (
        <Button
          sx={button}
          onClick={onRestore}
          startIcon={<FlipBackwardIcon />}
        >
          <Typography sx={typography}>Restaurar</Typography>
        </Button>
      )}

      {!isRejected && isAllowedRejectManyInboxItems && (
        <Button sx={button} onClick={onRejection} startIcon={<TrashIcon />}>
          <Typography sx={typography}>Rejeitar</Typography>
        </Button>
      )}

      <InboxItemMenuWithIconButton
        inboxItem={inboxItem}
        withCloseOption
        onCloseFromOption={closeInboxItem}
      />
    </Stack>
  );
}
