import type { Components } from '@mui/material';
import { inputBaseClasses, outlinedInputClasses } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiTextField({
  palette,
}: ThemeConfig): Components['MuiTextField'] {
  const borderColor =
    palette?.mode === 'light' ? palette.grey?.[300] : palette.grey?.[600];

  return {
    defaultProps: { fullWidth: true },
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.margin === 'normal' && {
          marginTop: '8px',
          marginBottom: '24px',
        }),
        ...(ownerState.margin === 'normal' &&
          ownerState.error && {
            marginBottom: '0',
          }),

        [`.${inputBaseClasses.root}`]: {
          paddingBlock: 0,
        },

        [`.${outlinedInputClasses.notchedOutline}`]: {
          borderColor,
        },
      }),
    },
  };
}
