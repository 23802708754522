import type { SxProps, Theme } from '@mui/material';

export const animator: SxProps<Theme> = {
  backgroundColor: 'background.default',
  borderRadius: '12px',
  maxWidth: '800px',
  paddingBottom: '8px',
  width: '100%',
};

export const input: SxProps<Theme> = {
  borderBottom: '1px solid',
  borderBottomColor: 'divider',
  color: (theme) => (theme.palette.mode === 'light' ? 'grey.500' : 'grey.100'),
  fontSize: '16px',
  height: '56px',
  lineHeight: '24px',
  p: '16px',
  width: '100%',
};

export const inputAdornment: SxProps<Theme> = { marginRight: '8px' };
