import { useState } from 'react';
import type { ReactNode, SyntheticEvent } from 'react';

import { Button, DialogActions, DialogContent, Tab, Tabs } from '@mui/material';
import type { GridRowSelectionModel } from '@mui/x-data-grid-premium';

import { Typography } from 'src/mui';

import type { PortalProps } from 'src/ui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useGetBills } from 'src/libs/finbits/Bills';
import { BillStatus } from 'src/libs/finbits/Bills/types';
import type { BillPayable } from 'src/libs/finbits/Bills/types';

import type { BillTabsType } from 'src/features/bills-to-pay/ReviewModal/types';
import GridToolBar from 'src/features/bills-to-pay/ReviewModal/GridToolBar';
import Label from 'src/features/bills-to-pay/BillContent/BillPreview/PreviewTabs/Label';
import BillsToPayDataGrid from 'src/features/bills-to-pay/BillsToPayDataGrid';

import styles from './ReviewModalContent.module.scss';

type Props = {
  tabs: Record<'availables' | 'notAvailables', BillTabsType>;
  modalDescription?: ReactNode | string;
  onConfirm: {
    confirmText: string;
    action: (newSelectedIds?: string[]) => void;
  };
  onClose: (confirmed?: boolean) => void;
} & PortalProps;

function a11yProps(index: number) {
  return {
    id: `review-tabs-${index}`,
    'aria-controls': `review-tabpanel-${index}`,
  };
}

export default function ReviewModalContent({
  tabs,
  onConfirm,
  modalDescription,
  onClose,
}: Props) {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [selectedIds, setSelectedIds] = useState(
    tabs.availables.bills.map((bill) => bill.id) ?? []
  );

  const tab = Boolean(selectedTab) ? tabs.notAvailables : tabs.availables;
  const isAvailable = Boolean(!selectedTab);

  const { companyId, organizationId } = useCompanyParams();
  const [{ availables, notAvailables }, setBills] = useState(tabs);

  useGetBills(
    { companyId, organizationId, status: BillStatus.WAITING_APPROVAL },
    {
      onSuccess: (data) => {
        const initialBills = [...availables.bills, ...notAvailables.bills];
        const initialSelectedIds = initialBills.map((bill) => bill.id);

        const { billsAvailable, billsNotAvailable } =
          splitAvailableAndNotAvailable(
            data.filter((bill) => initialSelectedIds.includes(bill.id))
          );

        setBills({
          availables: {
            ...availables,
            bills: billsAvailable,
          },
          notAvailables: {
            ...notAvailables,
            bills: billsNotAvailable,
          },
        });

        setSelectedIds(
          selectedIds.filter((id) =>
            billsAvailable.find((bill) => bill.id === id)
          )
        );
      },
    }
  );

  function splitAvailableAndNotAvailable(bills: BillPayable[]) {
    return bills.reduce<{
      billsAvailable: BillPayable[];
      billsNotAvailable: BillPayable[];
    }>(
      (acc, bill) => {
        if (
          availables.bills.find((availableBill) => availableBill.id === bill.id)
        ) {
          acc.billsAvailable.push(bill);
        } else {
          acc.billsNotAvailable.push(bill);
        }

        return acc;
      },
      {
        billsAvailable: [],
        billsNotAvailable: [],
      }
    );
  }

  function handleChangeTab(
    _event: SyntheticEvent<Element, Event>,
    tab: number
  ) {
    setSelectedTab(tab);
  }

  function onRowSelectionModelChange(newSelected: GridRowSelectionModel) {
    setSelectedIds(newSelected as string[]);
  }

  function handleConfirm() {
    onConfirm.action(selectedIds);
    onClose(true);
  }

  return (
    <>
      <DialogContent dividers>
        {modalDescription && (
          <Typography color="text.secondary" fontSize={12}>
            {modalDescription}
          </Typography>
        )}
        <Tabs
          className={styles.tabs}
          value={selectedTab}
          textColor="secondary"
          indicatorColor="secondary"
          onChange={handleChangeTab}
        >
          <Tab
            className={styles.tab}
            key={availables.title}
            label={<Label title={availables.title} />}
            value={0}
            {...a11yProps(0)}
          />
          <Tab
            className={styles.tab}
            key={notAvailables.title}
            label={
              <Label
                title={notAvailables.title}
                badgeValue={notAvailables.bills.length}
              />
            }
            value={1}
            {...a11yProps(1)}
          />
        </Tabs>

        <div role="tabpanel" key={tab?.title} className={styles.table}>
          {tab?.description && (
            <Typography variant="body1" margin={4}>
              {tab.description}
            </Typography>
          )}

          <BillsToPayDataGrid
            bills={isAvailable ? availables.bills : notAvailables.bills}
            checkboxSelection={isAvailable}
            RowSelection={
              isAvailable
                ? {
                    rowSelectionModel: selectedIds,
                    onRowSelectionModelChange: onRowSelectionModelChange,
                  }
                : undefined
            }
            GridToolBar={() => {
              if (isAvailable) {
                return (
                  <GridToolBar
                    bills={tab.bills}
                    description="Total a pagar"
                    selectedIds={selectedIds}
                  />
                );
              }
              return <></>;
            }}
          />
        </div>
      </DialogContent>

      {isAvailable && (
        <DialogActions className={styles.dialogActions}>
          <Button variant="outlined" onClick={() => onClose()}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            disabled={!selectedIds.length}
            color="primary"
            onClick={handleConfirm}
          >
            {onConfirm.confirmText}
          </Button>
        </DialogActions>
      )}
    </>
  );
}
