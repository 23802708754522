import t from 'zod';

import { CompanyDecoder } from 'src/libs/finbits/Organization/Companies/types';

export enum BalanceType {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export const BalanceDecoder = t.object({
  balance: t.number().nullable(),
  balanceType: t.nativeEnum(BalanceType),
  date: t.string().nullable(),
});

export type Balance = t.TypeOf<typeof BalanceDecoder>;

export const CompanyBalanceDecoder = BalanceDecoder.merge(
  t.object({
    company: CompanyDecoder,
  })
);

export type CompanyBalance = t.TypeOf<typeof CompanyBalanceDecoder>;

export const AccountBalanceDecoder = BalanceDecoder.merge(
  t.object({
    accountId: t.string(),
  })
);

export type AccountBalance = t.TypeOf<typeof AccountBalanceDecoder>;

export type GetCompaniesBalancesParams = {
  organizationId: string;
  date?: string;
};

export type GetAccountsBalancesParams = {
  organizationId: string;
  companyId: string;
};
