import { useMemo } from 'react';

import { Avatar, Box, Stack } from '@mui/material';
import md5 from 'md5';

import { Typography } from 'src/mui';

import { useProfile } from 'src/libs/finbits/Organization/Users';

import styles from './text.module.scss';

type Props = {
  userPrompt?: string | null;
};

export default function UserText({ userPrompt }: Props) {
  const { user } = useProfile();

  const avatarUrl = useMemo(() => {
    if (!user?.email) return 'wrong-url';

    const hash = md5(user.email);

    return `https://www.gravatar.com/avatar/${hash}?default=404`;
  }, [user]);

  if (!userPrompt) return null;

  return (
    <Stack
      alignSelf="end"
      flexDirection="row"
      gap={4}
      alignItems="start"
      className={styles.userWrapper}
    >
      <Box className={styles.userText}>
        <Typography>{userPrompt}</Typography>
      </Box>

      <Avatar alt={user?.name ?? ''} src={avatarUrl} />
    </Stack>
  );
}
