import { Stack, Typography } from '@mui/material';
import { InboxIcon } from 'src/mui/_icons';

import { FocusIcon } from 'src/mui';

import emptyStateResult from 'src/assets/empty-search-result.svg';

type Props = {
  isFiltering: boolean;
};

export default function EmptyState({ isFiltering }: Props) {
  if (isFiltering) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        gap={8}
        sx={{
          height: 'calc(100% - 80px)',
          m: 'auto',
          maxWidth: '326px',
        }}
      >
        <img src={emptyStateResult} alt="Nuvem com lupa" />
        <Stack direction="column" gap={1} sx={{ textAlign: 'center' }}>
          <Typography variant="subtitle2">Termo não encontrado</Typography>
          <Typography color="text.secondary">
            O termo pesquisado não foi encontrado, por favor, refaça a busca.
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      gap={2}
      sx={{ px: 11, py: 6 }}
    >
      <FocusIcon size="lg">
        <InboxIcon />
      </FocusIcon>
      <Stack direction="column" gap={1}>
        <Typography variant="subtitle2">Sem entradas</Typography>
        <Typography color="text.secondary">
          Você não tem entradas na Caixa de Entrada.
          <br />
          Siga as informações ao lado e receba suas
          <br />
          entradas.
        </Typography>
      </Stack>
    </Stack>
  );
}
