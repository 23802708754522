import { useMemo } from 'react';

import type {
  GridColDef,
  GridRowClassNameParams,
} from '@mui/x-data-grid-premium';
import { Table } from 'src/mui/_scss';
import { actionColumn, checkboxColumn } from 'src/mui/_scss/Table';
import type { ProcessRowUpdateProps, TableProps } from 'src/mui/_scss/Table';

import { useUpdateEntriesInBatch } from 'src/libs/finbits/Management/EntriesBatchUpdate';
import { useClassifications } from 'src/libs/finbits/Classifications';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { BillStatus } from 'src/libs/finbits/Bills/types';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import { useIsEnabledBillsApprovalsInCompany } from 'src/libs/finbits/Organization/Companies/Options';
import { isOverDue } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';

import { amountColumn } from 'src/features/entries/EntriesDataGrid/columns/amountColumn/amountColumn';
import { dateColumn } from 'src/features/entries/EntriesDataGrid/columns/dateColumn/dateColumn';
import { assigneesColumn } from 'src/features/entries/EntriesDataGrid/columns/assigneesColumn/assigneesColumn';
import { approvalTypeColumn } from 'src/features/entries/EntriesDataGrid/columns/approvalTypeColumn/approvalTypeColumn';
import { descriptionColumn } from 'src/features/entries/EntriesDataGrid/columns/descriptionColumn/descriptionColumn';
import { accountColumn } from 'src/features/entries/EntriesDataGrid/columns/accountColumn/accountColumn';
import { currentSituationColumn } from 'src/features/entries/EntriesDataGrid/columns/currentSituationColumn/currentSituationColumn';
import { categoryColumn } from 'src/features/entries/EntriesDataGrid/columns/categoryColumn/categoryColumn';
import { contactColumn } from 'src/features/entries/EntriesDataGrid/columns/contactColumn/contactColumn';
import { relevantdateColumn } from 'src/features/entries/EntriesDataGrid/columns/relevantDateColumn/relevantDateColumn';
import { classificationsColumn } from 'src/features/entries/EntriesDataGrid/columns/classificationColumn/classificationColumn';
import { attachmentColumn } from 'src/features/entries/EntriesDataGrid/columns/attachmentColumn/attachmentColumn';
import { paymentMethodColumn } from 'src/features/entries/EntriesDataGrid/columns/paymentMethodColumn/paymentMethodColumn';
import { requestedAtColumn } from 'src/features/entries/EntriesDataGrid/columns/requestedAtColumn/requestedAtColumn';
import { requesterColumn } from 'src/features/entries/EntriesDataGrid/columns/requesterColumn/requesterColumn';
import { commentsColumn } from 'src/features/entries/EntriesDataGrid/columns/commentsColumn/commentsColumn';
import EmptyState from 'src/features/bills-to-pay/BillsToPayDataGrid/EmptyState';
import useEditCellValidation from 'src/features/entries/EntriesDataGrid/editCellEvents/useEditCellValidation';

import { panelQuickFilters } from './panelQuickFilters';
import ContextMenuRowActions from './ContextMenuRowActions/ContextMenuRowActions';
import { RowActions } from './ContextMenuRowActions/RowActions/RowActions';
import {
  TableRootActions,
  ToolbarSelectedActions,
  TotalSum,
} from './GridToolbar';
import styles from './BillsToPayDataGrid.module.scss';

type Props = {
  bills: BillPayable[];
  isLoading?: boolean;
  billStatus?: BillStatus;
} & Pick<
  TableProps<BillPayable>,
  | 'GridToolBar'
  | 'FooterSlots'
  | 'density'
  | 'checkboxSelection'
  | 'RowSelection'
  | 'storageKey'
  | 'hideFooter'
  | 'pageSize'
>;

function getRowClassName(params: GridRowClassNameParams<BillPayable>) {
  if (isOverDue(params.row.date)) {
    return styles.highlightRow;
  }
  return '';
}

function concatApprovalColumns({
  defaultColumns,
  billStatus,
  canEdit = false,
}: {
  defaultColumns: GridColDef[];
  billStatus?: BillStatus;
  canEdit?: boolean;
}) {
  const paymentDetailsIndex = defaultColumns.findIndex(
    (column) => column.field === 'paymentDetails'
  );

  if (billStatus === BillStatus.PENDING) {
    defaultColumns.splice(
      paymentDetailsIndex,
      0,
      assigneesColumn({ editable: canEdit }),
      approvalTypeColumn({ editable: canEdit })
    );

    return defaultColumns;
  }

  defaultColumns.splice(
    paymentDetailsIndex,
    0,
    assigneesColumn({ editable: canEdit }),
    approvalTypeColumn({ editable: canEdit }),
    requestedAtColumn(),
    requesterColumn()
  );

  return defaultColumns;
}

export const STORAGE_KEY = 'bills_data_grid_state_';

export default function BillsToPayDataGrid({
  bills,
  isLoading = false,
  billStatus,
  GridToolBar,
  FooterSlots,
  density,
  checkboxSelection = true,
  RowSelection,
  storageKey,
  hideFooter,
  pageSize,
}: Props) {
  const { organizationId, companyId } = useCompanyParams();
  const { isEnabledBillsApprovals } = useIsEnabledBillsApprovalsInCompany();

  const onCellEditStart = useEditCellValidation<BillPayable>();

  const { classifications, isLoading: isLoadingClassifications } =
    useClassifications(
      { companyId, organizationId },
      { staleTime: FIVE_MINUTES_IN_MS }
    );

  const { mutateAsync: updateEntries, isLoading: isUpdating } =
    useUpdateEntriesInBatch();

  function handleProcessRowUpdate({
    fields,
    activeRows,
  }: ProcessRowUpdateProps<BillPayable>) {
    return updateEntries({
      billsIds: activeRows.map((row) => row.id),
      organizationId,
      companyId,
      changes: fields,
    });
  }

  const canEdit = useAllowedPermission({
    action: 'batch_update',
    resource: 'entries',
  });

  const columns: GridColDef[] = useMemo(() => {
    const defaultColumns = [
      checkboxColumn(),
      dateColumn({ headerName: 'Data de pagamento', editable: canEdit }),
      descriptionColumn({ editable: canEdit }),
      accountColumn({ editable: canEdit }),
      currentSituationColumn({ editable: canEdit }),
      categoryColumn({ editable: canEdit }),
      contactColumn({ editable: canEdit }),
      relevantdateColumn({ editable: canEdit }),
      paymentMethodColumn({ editable: canEdit }),
      ...classificationsColumn({
        classifications,
        columnProps: { editable: canEdit },
      }),
      amountColumn({ editable: canEdit }),
      attachmentColumn(),
      commentsColumn({ editable: canEdit }),
      actionColumn({ getActions: RowActions }),
    ];

    if (isEnabledBillsApprovals) {
      return concatApprovalColumns({ defaultColumns, billStatus, canEdit });
    }

    return defaultColumns;
  }, [classifications, canEdit, isEnabledBillsApprovals, billStatus]);

  const isTableLoading = isUpdating || isLoading || isLoadingClassifications;

  return (
    <Table
      storageKey={storageKey}
      rows={bills ?? []}
      columns={columns}
      isLoading={isTableLoading}
      checkboxSelection={checkboxSelection}
      CustomContextMenuOptions={ContextMenuRowActions}
      GridToolBar={GridToolBar}
      GridToolBarSlots={{
        SelectedActions: ToolbarSelectedActions,
        TableRootActions: TableRootActions,
        QuickActions: () => <TotalSum bills={bills} />,
        selectedText: ['lançamento selecionado', 'lançamentos selecionados'],
      }}
      onProcessRowUpdate={handleProcessRowUpdate}
      getRowClassName={getRowClassName}
      NoRowsOverlay={() => <EmptyState status={billStatus} />}
      onCellEditStart={onCellEditStart}
      quickFilterPanel={
        billStatus !== BillStatus.REPROVED ? panelQuickFilters : []
      }
      density={density}
      RowSelection={RowSelection}
      hideFooter={hideFooter}
      pageSize={pageSize}
      FooterSlots={FooterSlots}
    />
  );
}
