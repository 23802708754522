import type { SxProps, Theme } from '@mui/material';

import type { FocusIconSize } from '../FocusIcon';

import type { FocusIconSquareType } from './Square';

export const borderRadius: Record<FocusIconSize, SxProps<Theme>> = {
  xs: {
    borderRadius: '4px',
  },
  sm: {
    borderRadius: '6px',
  },
  md: {
    borderRadius: '8px',
  },
  lg: {
    borderRadius: '10px',
  },
  xl: {
    borderRadius: '12px',
  },
};

export const types: Record<FocusIconSquareType, SxProps<Theme>> = {
  outlined: {
    background: 'transparent',
    boxShadow: (theme) => theme.shadows[1],
    border: (theme) => `1px solid ${theme.palette.divider}`,
    color: (theme) => theme.palette.text.primary,
  },
  filled: {
    background: (theme) =>
      theme.palette.mode === 'light'
        ? theme.palette.grey[100]
        : theme.palette.grey[800],
  },
};
