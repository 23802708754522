import { Button, Grid, Stack, Typography } from '@mui/material';
import { AlertCircleIcon, PlusIcon } from 'src/mui/_icons';

import { FocusIcon } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import { redirectPathAfterEntriesImport } from 'src/libs/finbits/Bank/Statements';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import CreateAccount from 'src/features/DEPRECATED_accounts/CreateAccount';
import ImportOfxModal from 'src/features/ofx/ImportOfxModal';

export default function EntriesEmptyState() {
  const openPortal = useOpenPortal();
  const { organizationId, companyId } = useCompanyParams();

  function handleUploadOfx() {
    openPortal(ImportOfxModal, {
      organizationId,
      companyId,
      redirectPathAfterImport: redirectPathAfterEntriesImport,
    });
  }

  function handleCreateAccount() {
    openPortal(CreateAccount, {
      organizationId,
      companyId,
      openModal: handleCreateAccount,
      onCloseImportFinish: () => {},
    });
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      my={12}
    >
      <Stack alignItems="center" gap={4}>
        <FocusIcon size="xl">
          <AlertCircleIcon />
        </FocusIcon>
        <Stack alignItems="center" textAlign="center" gap={1} maxWidth="340px">
          <Typography variant="subtitle2" fontWeight="600">
            Sem conta bancária cadastrada
          </Typography>
          <Typography variant="body1">
            Você precisa adicionar pelo menos uma conta bancária para ver os
            lançamentos.
          </Typography>
        </Stack>
        <Stack direction="row" gap={3} mt={2}>
          <Button variant="outlined" onClick={handleUploadOfx}>
            Importar OFX
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<PlusIcon />}
            onClick={handleCreateAccount}
          >
            Adicionar Conta
          </Button>
        </Stack>
      </Stack>
    </Grid>
  );
}
