import { Box } from '@mui/material';

import { Typography } from 'src/mui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useGetBills } from 'src/libs/finbits/Bills';

import BillsToPayDataGrid from 'src/features/bills-to-pay/BillsToPayDataGrid';
import BillPageTabs from 'src/features/bills-to-pay/BillPageTabs';
import useBillStatusFromUrl from 'src/features/bills-to-pay/BillPageTabs/hooks/useBillStatusFromUrl';
import { STORAGE_KEY } from 'src/features/bills-to-pay/BillsToPayDataGrid/BillsToPayDataGrid';

import styles from './BillsToPay.module.scss';

export default function BillsToPay() {
  const { status: selectedTab } = useBillStatusFromUrl();

  const { organizationId, companyId } = useCompanyParams();

  const { bills, isLoading, isFetching } = useGetBills({
    companyId,
    organizationId,
    status: selectedTab,
  });

  return (
    <Box className={styles.container}>
      <Typography variant="subtitle1" fontWeight="bold">
        Contas a pagar
      </Typography>

      <BillPageTabs />

      <BillsToPayDataGrid
        bills={bills}
        isLoading={isLoading || isFetching}
        billStatus={selectedTab}
        storageKey={`${STORAGE_KEY}_${selectedTab}_${companyId}`}
      />
    </Box>
  );
}
