import { Stack, Typography } from '@mui/material';

import inboxItemNotFoundImg from 'src/assets/inbox-item-not-found.svg';

import { inboxItemNotFound } from './InboxItemNotFound.sx';

export default function InboxItemNotFound() {
  return (
    <Stack alignItems="center" sx={inboxItemNotFound}>
      <img
        draggable="false"
        src={inboxItemNotFoundImg}
        alt="Entrada não encontrada"
        width={150}
        height={118}
      />

      <Typography variant="subtitle1" sx={{ mt: 4, mb: 1 }}>
        Entrada não encontrada
      </Typography>

      <Typography align="center" sx={{ maxWidth: 350 }}>
        O link que você está tentando acessar está inválido ou não existe. Você
        pode tentar encontrar a entrada desejada ao lado.
      </Typography>
    </Stack>
  );
}
