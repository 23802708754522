import type { ReactNode } from 'react';

import { Space, Tooltip, Typography } from 'antd';
import { BorderOutlined, CheckSquareOutlined } from '@ant-design/icons';
import type { BaseType } from 'antd/lib/typography/Base';

type Props = {
  isAccountFeatureTrue: boolean;
  children: ReactNode;
  type?: BaseType;
};

export default function AccountFeatureBadge({
  isAccountFeatureTrue,
  children,
  type,
}: Props) {
  return (
    <Tooltip
      title={isAccountFeatureTrue ? 'Habilitado' : 'Desabilitado'}
      placement="left"
    >
      <Space>
        <Typography.Text type={type}>
          {isAccountFeatureTrue ? <CheckSquareOutlined /> : <BorderOutlined />}{' '}
          {children}
        </Typography.Text>
      </Space>
    </Tooltip>
  );
}
