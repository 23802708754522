import type { ColorSystemOptions } from '@mui/material';
import { common } from '@mui/material/colors';
import {
  error,
  grey,
  info,
  primary,
  secondary,
  success,
  warning,
} from 'src/theme/colors';

// TODO: Ver com o Pett as cores que vamos usar no dark mode
export const dark: ColorSystemOptions = {
  palette: {
    grey,
    primary,
    secondary,
    error,
    info,
    success,
    warning,
    divider: grey[600],
    common,
    action: {
      selected: grey[800],
      hover: grey[800],
    },
    toolbar: {
      action: {
        active: primary.main,
        disabled: grey[700],
      },
    },
    background: {
      default: grey[900],
      paper: grey[900],
      secondary: grey[900],
    },
    text: {
      primary: grey[100],
      secondary: grey[400],
      disabled: grey[100],
    },
    highlight: {
      primary: grey[800],
    },
  },
};
