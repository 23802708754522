import type { ReactNode } from 'react';
import { Suspense, lazy, useCallback, useEffect, useMemo } from 'react';

import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { createTheme } from 'src/theme';

import {
  getItem,
  setItem,
  setMUIThemeModeItem,
} from 'src/libs/finbits/Storage';

import type { Theme } from './types';

const STORAGE_KEY = 'theme';

function getCurrentTheme() {
  const defaultTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';

  return getItem<Theme>(STORAGE_KEY, defaultTheme);
}

export function useTheme(): [Theme, (theme: Theme) => void] {
  const currentTheme = useMemo(() => getCurrentTheme(), []);

  const setTheme = useCallback(
    (theme: Theme) => {
      if (theme !== currentTheme) {
        setItem(STORAGE_KEY, theme);
        window.location.reload();
      }
    },
    [currentTheme]
  );

  return [currentTheme, setTheme];
}

type Props = {
  children: ReactNode;
};

const DarkTheme = lazy(() => import('./themes/DarkTheme'));
const LightTheme = lazy(() => import('./themes/LightTheme'));

export function ThemeProvider({ children }: Props) {
  const [theme] = useTheme();
  const muiTheme = createTheme(theme);

  useEffect(() => {
    const favicon = document.getElementById('favicon');

    if (theme === 'light') {
      favicon?.setAttribute('href', '/favicons/favicon-white.ico');
    } else {
      favicon?.setAttribute('href', '/favicons/favicon.ico');
    }
    setMUIThemeModeItem(theme);
  }, [theme]);

  return (
    <CssVarsProvider theme={muiTheme}>
      <Suspense fallback={<span />}>
        {theme === 'dark' ? <DarkTheme /> : <LightTheme />}
      </Suspense>
      {children}
    </CssVarsProvider>
  );
}
