import type { ReactNode } from 'react';
import { useMemo } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { info } from './DetailsInfo.sx';

type Props = {
  label: string;
  icon?: ReactNode;
  value?: string | number | null;
  noContentMessage?: string;
  onClick?: () => void;
  formatter?: (value: any) => string;
};

export default function DetailsInfo({
  label,
  icon,
  value,
  noContentMessage = 'Sem informação',
  onClick,
  formatter,
}: Props) {
  const isClickable = !!onClick;

  const formattedValue = useMemo(() => {
    if (value) {
      return formatter ? formatter(value) : value;
    }

    return noContentMessage;
  }, [value, noContentMessage, formatter]);

  return (
    <Box>
      <Typography sx={{ mb: 2 }}>{label}</Typography>
      {isClickable ? (
        <Stack
          component="button"
          direction="row"
          alignItems="center"
          gap={2}
          onClick={onClick}
          sx={info}
        >
          <Typography fontWeight={600} color="text.primary">
            {formattedValue}
          </Typography>
          {!!value && icon}
        </Stack>
      ) : (
        <Typography fontWeight={600} color={value ? 'text.primary' : undefined}>
          {formattedValue}
        </Typography>
      )}
    </Box>
  );
}
