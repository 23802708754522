import type { Components } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiTooltip({
  palette,
  shadows,
}: ThemeConfig): Components['MuiTooltip'] {
  const tooltipColor =
    palette.mode === 'light' ? palette.common?.white : palette.grey?.[700];

  const tooltipBackgroundColor =
    palette.mode === 'light' ? palette.grey?.[900] : palette.common?.white;

  return {
    styleOverrides: {
      tooltip: {
        color: tooltipColor,
        backgroundColor: tooltipBackgroundColor,
        fontSize: '12px',
        fontWeight: '600',
        padding: '8px 12px',
        boxShadow: shadows[4],
      },
      arrow: {
        color: tooltipBackgroundColor,
      },
    },
  };
}
