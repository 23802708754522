import { UserOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Typography } from 'antd';

import { Loader, Title } from 'src/ui';

import { useProfile } from 'src/libs/finbits/Organization/Users';

import UserProfileForm from 'src/features/user-profile/UserProfileForm';
import { ThemeSwitcher } from 'src/features/theme-switcher';
import UserOptedInDailyReports from 'src/features/user-profile/UserOptedInDailyReports';
import UserOptedInPendingApprovalsReport from 'src/features/user-profile/UserOptedInPendingApprovalsReport';

import styles from './UserProfile.module.less';

function UserProfile() {
  const { user, isLoading } = useProfile();

  if (isLoading || !user) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <Col span={24}>
        <Row justify="space-between">
          <Title icon={<UserOutlined />}>Meu perfil</Title>
          <div className={styles.info}>
            <Typography.Text>{user.email}</Typography.Text>
          </div>
        </Row>
      </Col>

      <Row justify="space-between" gutter={[32, 32]}>
        <Col xs={24} lg={11}>
          <Title className={styles.pageTitle} level={3}>
            Informações pessoais
          </Title>
          <UserProfileForm user={user} />
        </Col>

        <Col xs={0} lg={2}>
          <div className={styles.separator}></div>
        </Col>

        <Col xs={24} lg={11}>
          <Title className={styles.pageTitle} level={3}>
            Preferências do usuário
          </Title>
          <UserOptedInDailyReports />
          <Divider />
          <UserOptedInPendingApprovalsReport />
          <Divider />
          <ThemeSwitcher />
        </Col>
      </Row>
    </div>
  );
}

export default UserProfile;
