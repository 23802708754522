import { useEffect } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Card } from '@mui/material';

import { generateCompanyPath } from 'src/router/routes';
import { RouteKey } from 'src/router/types';

import { Typography } from 'src/mui';

import { Loader } from 'src/ui';

import Img from 'src/assets/antifraude.svg';

import { useCreateBTGConsentments } from 'src/libs/finbits/Bank/BtgConsentments';
import { getCompanyParamsFromStorage } from 'src/libs/finbits/Organization/Companies';

import BTGConnectionRedirectButton from 'src/features/open-banking/BTGConnectionRedirectButton';

import styles from './ConsentmentRedirect.module.scss';

export default function ConsentmentRedirect() {
  const navigate = useNavigate();

  const { companyId, organizationId } = getCompanyParamsFromStorage();

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const { createBTGConsentments, isError } = useCreateBTGConsentments();

  useEffect(() => {
    if (code && organizationId && companyId) {
      createBTGConsentments(
        {
          organizationId,
          companyId,
          code,
        },
        {
          onSuccess: () => {
            navigate(
              generateCompanyPath(RouteKey.ACCOUNTS, {
                companyId,
                organizationId,
              })
            );
          },
        }
      );
    }
  }, [companyId, organizationId, code, createBTGConsentments, navigate]);

  return (
    <Box className={styles.container}>
      <Card className={styles.card}>
        <img srcSet={Img} src={Img} alt="start" className={styles.img} />

        <Typography variant="subtitle1" fontWeight="bold" marginTop={8}>
          {isError
            ? 'Houve um erro ao finalizar sua conexão.'
            : 'Estamos finalizando sua conexão.'}
        </Typography>
        <Typography variant="body1" color="grey.500" marginTop={1}>
          {isError
            ? 'Reinicie o fluxo clicando abaixo.'
            : 'Você será redirecionado quando isso for concluído.'}
        </Typography>
        <div>
          {isError ? (
            <BTGConnectionRedirectButton
              color="error"
              variant="contained"
              className={styles.button}
              companyId={companyId}
            >
              Refazer conexão
            </BTGConnectionRedirectButton>
          ) : (
            <Loader size="small" />
          )}
        </div>
      </Card>
    </Box>
  );
}
