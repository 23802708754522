import type { Components } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiBadge({
  palette,
}: ThemeConfig): Components['MuiBadge'] {
  const backgroundColor =
    palette.mode === 'light' ? palette?.grey?.[100] : palette?.grey?.[800];

  const color =
    palette.mode === 'light' ? palette?.grey?.[700] : palette?.grey?.[200];

  return {
    styleOverrides: {
      colorSecondary: {
        backgroundColor,
        color,
      },
    },
  };
}
