import type { CSSProperties, Ref } from 'react';
import { forwardRef } from 'react';

import { Button, Stack, Typography } from '@mui/material';
import type { GridApi, GridValidRowModel } from '@mui/x-data-grid-premium';
import { CloseIcon } from 'src/mui/_icons';
import type {
  GridToolBarSlots,
  SelectedActions,
} from 'src/mui/_scss/Table/types';

import { pluralize } from 'src/libs/finbits/Pluralize';

import styles from './ToolbarSelectedActions.module.scss';

type Props = {
  selecteds?: GridValidRowModel[];
  apiRef: GridApi;
  SelectedActions?: SelectedActions<any>;
  selectedText?: GridToolBarSlots<any>['selectedText'];
  style?: CSSProperties;
};

export default forwardRef(function ToolbarSelectedActions(
  {
    selecteds = [],
    apiRef,
    SelectedActions,
    selectedText = ['linha selecionada', 'linhas selecionadas'],
    style,
  }: Props,
  ref: Ref<HTMLDivElement>
) {
  function handleOnClearSelection() {
    apiRef.setRowSelectionModel([]);
  }

  return (
    <div ref={ref} style={style}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        spacing={2}
        alignItems="center"
        className={styles.stack}
      >
        <Typography className={styles.selectedText}>{`${
          selecteds?.length
        } ${pluralize(selecteds.length, ...selectedText)}`}</Typography>

        <Button
          variant="text"
          startIcon={<CloseIcon />}
          onClick={handleOnClearSelection}
        >
          Limpar seleção
        </Button>

        {SelectedActions && (
          <SelectedActions selecteds={selecteds} apiRef={apiRef} />
        )}
      </Stack>
    </div>
  );
});
