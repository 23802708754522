import { Chip } from '@mui/material';
import type { ChipProps } from '@mui/material';
import { CheckCircleIcon, ClockIcon, XIcon } from 'src/mui/_icons';

import { translateCurrentSituation } from 'src/libs/finbits/Management/Entries/currentSituationTranslations';
import { EntrySituation } from 'src/libs/finbits/Management/Entries/types';

import styles from './CurrentSituationChip.module.scss';

const situationsToUseErrorColor = [
  EntrySituation.UNAUTHORIZED,
  EntrySituation.FAILED,
  EntrySituation.REVERTED,
  EntrySituation.CANCELED,
  EntrySituation.DELETED,
];

const situationsToUseDefaultColor = [
  EntrySituation.PAYMENT_SCHEDULED,
  EntrySituation.PROCESSING,
  EntrySituation.PENDING_AUTHORIZATION,
];

function buildChipParams(
  currentSituation?: EntrySituation | null
): Pick<ChipProps, 'color' | 'icon'> {
  if (!currentSituation) {
    return { color: 'warning' };
  }

  if (situationsToUseDefaultColor.includes(currentSituation)) {
    return {
      color: 'default',
      icon: <ClockIcon fontSize="xsmall" />,
    };
  }

  if (situationsToUseErrorColor.includes(currentSituation)) {
    return { color: 'error', icon: <XIcon fontSize="xsmall" /> };
  }

  if (currentSituation === EntrySituation.PAID) {
    return {
      color: 'success',
      icon: <CheckCircleIcon fontSize="xsmall" />,
    };
  }

  if (currentSituation === EntrySituation.WAITING_PAYMENT) {
    return { color: 'warning', icon: <ClockIcon fontSize="xsmall" /> };
  }

  return { color: 'default' };
}

type Props = {
  currentSituation?: EntrySituation | null;
};

export default function CurrentSituationChip({ currentSituation }: Props) {
  if (!currentSituation) return null;

  const { color, icon } = buildChipParams(currentSituation);

  return (
    <Chip
      className={styles.chip}
      label={translateCurrentSituation(currentSituation)}
      size="small"
      color={color}
      icon={icon}
    />
  );
}
