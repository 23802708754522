import { Typography } from '@mui/material';

import type { NewInboxItem } from 'src/libs/finbits/Management/NewInboxItems/types';

type Props = {
  inboxItem: NewInboxItem;
};

export default function UploadDetails({ inboxItem }: Props) {
  return (
    <Typography sx={{ color: 'text.secondary' }}>
      {inboxItem.description}
    </Typography>
  );
}
