import { Chip, Stack } from '@mui/material';

import { Typography } from 'src/mui';

import type { ApprovalRuleCondition } from 'src/libs/finbits/ApprovalRules/types';
import { APPROVAL_RULE_CONDITION_TYPE_DICTIONARY } from 'src/libs/finbits/ApprovalRules/translations';
import { toCurrency } from 'src/libs/finbits/Money';

type Props = {
  condition: ApprovalRuleCondition;
};

export default function AmountConditions({ condition }: Props) {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Chip
        role="chip"
        aria-label="Valor"
        label="Valor"
        variant="outlined"
        size="xsmall"
      />

      <Typography marginBottom="3px">
        {APPROVAL_RULE_CONDITION_TYPE_DICTIONARY[condition.type]}
      </Typography>

      <Chip
        role="chip"
        aria-label="condition"
        label={toCurrency(condition?.amountValue?.amount!)}
        variant="outlined"
        size="xsmall"
        color="success"
      />
    </Stack>
  );
}
