import { useState } from 'react';

import { CheckOutlined } from '@ant-design/icons';

import { DrawerHeaderButton } from 'src/ui';

import type { Account } from 'src/libs/finbits/Bank/Accounts/types';

import type { ManualConciliateFormProps } from './ManualConciliateModal';
import ManualConciliateModal from './ManualConciliateModal';

type Props = {
  account?: Account;
  onConciliate: (params: ManualConciliateFormProps) => void;
};

export default function ManualConciliateHeaderButton({
  account,
  onConciliate,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <DrawerHeaderButton
        icon={<CheckOutlined />}
        title="Marcar como realizado"
        onClick={() => setIsModalOpen(true)}
        aria-label="Marcar como realizado"
      />

      {isModalOpen && (
        <ManualConciliateModal
          handleOnSubmit={onConciliate}
          onClose={() => setIsModalOpen(false)}
          initialBalanceDate={account?.initialBalance?.date}
        />
      )}
    </>
  );
}
