import { Skeleton, Stack } from '@mui/material';
import ReactMarkdown from 'react-markdown';

import { Typography } from 'src/mui';

import BotAvatar from 'src/features/finbot/BotAvatar';

import styles from './text.module.scss';

type Props = {
  insight?: string | null;
  isFetching: boolean;
  isError?: boolean;
};

export default function BotText({ insight, isFetching, isError }: Props) {
  if (!insight && !isFetching && !isError) return null;

  return (
    <Stack
      alignSelf="start"
      flexDirection="row"
      gap={4}
      alignItems="start"
      className={styles.botWrapper}
    >
      <BotAvatar />

      {isError && (
        <Typography>
          Houve um erro ao tentar obter o insight. Tente novamente em instantes.
        </Typography>
      )}

      {isFetching ? (
        <div>
          <Skeleton width="40vw" />
          <Skeleton width="30vw" />
          <Skeleton width="40vw" />
        </div>
      ) : (
        <Stack>
          <ReactMarkdown>{insight}</ReactMarkdown>
        </Stack>
      )}
    </Stack>
  );
}
