import { Stack } from '@mui/material';

import { Typography } from 'src/mui';

import type { BillPayable } from 'src/libs/finbits/Bills/types';

import { TotalSum } from 'src/features/bills-to-pay/BillsToPayDataGrid/GridToolbar';
import BillsToPayDataGrid from 'src/features/bills-to-pay/BillsToPayDataGrid';

type Props = {
  renderAsList: boolean;
  manualPayments: BillPayable[];
};

export default function ManualPayment({ renderAsList, manualPayments }: Props) {
  return (
    <Stack gap={2} height="330px">
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Typography fontWeight={700}>
          {renderAsList && '2.'} Itens que devem ser incluídos no respectivo
          banco de forma manual:
        </Typography>

        <TotalSum
          bills={manualPayments}
          description="Total a incluir manualmente"
        />
      </Stack>

      <BillsToPayDataGrid
        bills={manualPayments}
        GridToolBar={() => <></>}
        density="compact"
        checkboxSelection={false}
        pageSize={5}
        hideFooter={false}
      />
    </Stack>
  );
}
