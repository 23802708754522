import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { Logo } from 'src/ui';

function SideContentLayout() {
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            marginTop: 24,
          }}
        >
          <Box component={Logo} sx={{ height: '32px', width: '118px' }} />
        </Box>
        <Outlet />
      </Box>
    </Container>
  );
}

export default SideContentLayout;
