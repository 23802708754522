import type { ReactNode } from 'react';

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { card } from './InformationCard.sx';

type Props = {
  title: string;
  icon: ReactNode;
  content: ReactNode;
  actions: ReactNode;
};

export default function InformationCard({
  title,
  icon,
  content,
  actions,
}: Props) {
  return (
    <Grid xs={12} md={12} lg={6} xl={4} xxl={3} uxl={2}>
      <Card variant="outlined" sx={card}>
        <Box>
          <CardHeader
            sx={{ pt: 6, pb: 4, px: 6 }}
            avatar={icon}
            title={
              <Typography
                variant="subtitle1"
                component="h2"
                fontSize={16}
                sx={{
                  color: 'text.primary',
                }}
              >
                {title}
              </Typography>
            }
          />
          <CardContent sx={{ pt: 0, pb: 4, px: 6 }}>{content}</CardContent>
        </Box>

        <Box>
          {actions ? <Divider /> : null}
          <CardActions
            sx={{
              justifyContent: 'space-between',
              px: 3,
              height: '54px',
            }}
          >
            {actions}
          </CardActions>
        </Box>
      </Card>
    </Grid>
  );
}
