import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function PaintPourIcon({
  viewBox = '0 0 24 24',
  fontSize = 'small',
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      aria-label="paint-pour"
      viewBox={viewBox}
      fontSize={fontSize}
      {...rest}
    >
      <path
        d="M15.9997 10L1.9997 10M9.9997 3L7.9997 1M13.9997 21L1.9997 21M21.9997 15C21.9997 16.1046 21.1043 17 19.9997 17C18.8951 17 17.9997 16.1046 17.9997 15C17.9997 13.8954 19.9997 12 19.9997 12C19.9997 12 21.9997 13.8954 21.9997 15ZM8.9997 2L15.8683 8.86863C16.2643 9.26465 16.4624 9.46265 16.5365 9.69098C16.6018 9.89183 16.6018 10.1082 16.5365 10.309C16.4624 10.5373 16.2643 10.7354 15.8683 11.1314L11.2624 15.7373C10.4704 16.5293 10.0744 16.9253 9.61773 17.0737C9.21605 17.2042 8.78335 17.2042 8.38166 17.0737C7.92501 16.9253 7.52899 16.5293 6.73696 15.7373L3.26244 12.2627C2.4704 11.4707 2.07439 11.0747 1.92601 10.618C1.7955 10.2163 1.7955 9.78365 1.92601 9.38197C2.07439 8.92531 2.47041 8.52929 3.26244 7.73726L8.9997 2Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
