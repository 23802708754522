import { Alert, AlertTitle, Typography } from '@mui/material';

import type { BillPayable } from 'src/libs/finbits/Bills/types';

type Props = {
  bill?: BillPayable;
};

export default function PaymentFailedAlert({ bill }: Props) {
  const customError = bill?.paymentRequestErrorDetail;

  return (
    <Alert severity="warning">
      <AlertTitle>
        A transação falhou. Antes de re-enviar para pagamento, verifique:
      </AlertTitle>

      {Boolean(customError) ? (
        <Typography variant="body1">• {customError}</Typography>
      ) : (
        <>
          <Typography variant="body1">
            • Se os dados de pagamentos estão válidos;
          </Typography>
          <Typography variant="body1">
            • Se há saldo disponível na conta;
          </Typography>
          <Typography variant="body1">
            • Se seu banco bloqueou esse pagamento por motivos de segurança.
          </Typography>
        </>
      )}
    </Alert>
  );
}
