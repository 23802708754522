import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import {
  authenticatedAPI,
  decodeResponse,
  withEmptyArrayDefault,
} from 'src/libs/finbits/client';
import { invalidateAccountsQueries } from 'src/libs/finbits/Bank/Accounts';

import {
  OpenBankingAuthenticationURLDecoder,
  OpenBankingConnectionDecoder,
  OpenBankingConnectionsDecoder,
  WidgetSessionDecoder,
} from './types';
import type {
  ActivateConnectionParams,
  CreateConnectionParams,
  GetAuthURL,
  GetBTGAuthURL,
  GetParams,
  OpenBankingAuthenticationURL,
  OpenBankingConnection,
  WidgetSession,
  WidgetSessionParams,
} from './types';

export async function postWidgetSession({
  organizationId,
  companyId,
  connectionId,
  ...rest
}: WidgetSessionParams) {
  const url = connectionId
    ? `/organizations/${organizationId}/companies/${companyId}/open_banking/connections/${connectionId}/widget_sessions`
    : `/organizations/${organizationId}/companies/${companyId}/open_banking/widget_sessions`;

  const response = await authenticatedAPI.post(url, rest);

  return decodeResponse<WidgetSession>(response, WidgetSessionDecoder);
}

export function useCreateWidgetSession() {
  const { mutate: createWidgetSession, ...rest } = useMutation<
    WidgetSession,
    ApiError,
    WidgetSessionParams
  >(postWidgetSession);

  return { createWidgetSession, ...rest };
}

export function setConnectionsQueryKey({
  companyId,
  organizationId,
}: GetParams) {
  return ['connections', { companyId, organizationId }];
}

async function getOpenBankingConnections({
  companyId,
  organizationId,
}: GetParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/open_banking/connections`
  );

  return decodeResponse<OpenBankingConnection[]>(
    response,
    OpenBankingConnectionsDecoder
  );
}

async function getOpenBankingAuthenticationURL({
  companyId,
  organizationId,
  provider,
}: GetAuthURL) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/open_banking/authentication_url/${provider}`
  );

  return decodeResponse<OpenBankingAuthenticationURL>(
    response,
    OpenBankingAuthenticationURLDecoder
  );
}

export function useOpenBankingAuthenticationURL({
  companyId,
  organizationId,
  provider,
}: GetAuthURL) {
  const { data, ...rest } = useQuery<{ url: string }, ApiError>({
    queryKey: ['openBankingUrl', { companyId, organizationId, provider }],
    enabled: !!companyId && !!organizationId && !!provider,
    queryFn: () =>
      getOpenBankingAuthenticationURL({ companyId, organizationId, provider }),
  });

  return { data, ...rest };
}

function useOpenBankingConnections(
  { companyId, organizationId }: GetParams,
  queryConfigCustom = {}
) {
  const config = {
    ...queryConfigCustom,
  };

  const { data, ...rest } = useQuery<OpenBankingConnection[], ApiError>({
    queryKey: setConnectionsQueryKey({ companyId, organizationId }),
    queryFn: () => getOpenBankingConnections({ companyId, organizationId }),
    ...config,
  });

  return { connections: withEmptyArrayDefault(data), ...rest };
}

export function usePendingOpenBankingConnections({
  companyId,
  organizationId,
}: GetParams) {
  const { connections, ...rest } = useOpenBankingConnections({
    companyId,
    organizationId,
  });

  const pendingConnections = connections.filter(
    (connection: OpenBankingConnection) => connection.status === 'activating'
  );

  return { pendingConnections, ...rest };
}

export function useFailedOpenBankingConnections(
  { companyId, organizationId }: GetParams,
  queryConfigCustom = {}
) {
  const { connections, ...rest } = useOpenBankingConnections(
    {
      companyId,
      organizationId,
    },
    { enabled: !!companyId && !!organizationId, ...queryConfigCustom }
  );

  const failedConnections = connections.filter(
    (connection: OpenBankingConnection) =>
      ['mfa_required', 'invalid_credentials'].includes(connection.status)
  );

  return { failedConnections, ...rest };
}

export async function activateOpenBankingConnection({
  organizationId,
  companyId,
  connectionId,
}: ActivateConnectionParams) {
  const response = await authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/open_banking/connections/${connectionId}/activate`
  );

  return decodeResponse<OpenBankingConnection>(
    response,
    OpenBankingConnectionDecoder
  );
}

export function useActivateOpenBankingConnection() {
  const { mutate: activateConnection, ...rest } = useMutation<
    OpenBankingConnection,
    ApiError,
    ActivateConnectionParams
  >(activateOpenBankingConnection);
  return { activateConnection, ...rest };
}

export async function createOpenBankingConnection({
  companyId,
  organizationId,
  ...params
}: CreateConnectionParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/open_banking/connections`,
    params
  );
  return decodeResponse<OpenBankingConnection>(
    response,
    OpenBankingConnectionDecoder
  );
}

export function useCreateOpenBankingConnection() {
  const queryClient = useQueryClient();
  const { mutate: createConnection, ...rest } = useMutation<
    OpenBankingConnection,
    ApiError,
    CreateConnectionParams
  >(createOpenBankingConnection, {
    onSuccess: () => {
      invalidateAccountsQueries(queryClient);
      queryClient.invalidateQueries(['connections']);
    },
  });
  return { createConnection, ...rest };
}

async function getBTGAuthenticationURL({ organizationId }: GetBTGAuthURL) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/btg_authentication_url`
  );

  return decodeResponse<OpenBankingAuthenticationURL>(
    response,
    OpenBankingAuthenticationURLDecoder
  );
}

export function useBTGAuthenticationURL({ organizationId }: GetBTGAuthURL) {
  const { data, ...rest } = useQuery<{ url: string }, ApiError>({
    queryKey: ['BTGUrl', { organizationId }],
    enabled: !!organizationId,
    queryFn: () => getBTGAuthenticationURL({ organizationId }),
  });

  return { data, ...rest };
}
