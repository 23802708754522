import { useQuery } from 'react-query';

import type { ApiError } from 'src/libs/finbits/client';
import { authenticatedAPI, decodeResponse } from 'src/libs/finbits/client';

import { AiInsightDecoder } from './type';
import type { AiInsight, GetInsightParams } from './type';

const aiKeys = {
  all: ['finbot'],
  get: (params: unknown) => [...aiKeys.all, params],
};

async function getInsight({
  organizationId,
  companyId,
  ...params
}: GetInsightParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/ai`,
    { params }
  );

  return decodeResponse<AiInsight>(response, AiInsightDecoder);
}

export function useGetInsight(params: GetInsightParams) {
  const hasAllParams =
    !!params.companyId &&
    !!params.organizationId &&
    !!params.userPrompt &&
    !!params.endDate &&
    !!params.startDate &&
    !!params.accountsIds.length;

  const { data, ...rest } = useQuery<AiInsight, ApiError>({
    enabled: hasAllParams,
    queryKey: aiKeys.get(params),
    queryFn: () => getInsight(params),
  });

  return { data, ...rest };
}
