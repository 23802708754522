import { useEffect } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import type { UseFormReturn } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import type { BillFormParams } from 'src/libs/finbits/Bills/types';
import { BillFormResolver } from 'src/libs/finbits/Bills/validations';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useInboxItem } from 'src/libs/finbits/Management/NewInboxItems';

import { useInboxItemsAttachments } from 'src/features/inbox-items/useInboxItemsAttachments';

export default function useBillForm(
  inboxItemId?: string
): UseFormReturn<BillFormParams> {
  const { companyId, organizationId } = useCompanyParams();
  const { inboxItem } = useInboxItem({
    companyId,
    organizationId,
    inboxItemId,
  });

  const { attachments } = useInboxItemsAttachments(inboxItem);

  const form = useForm<BillFormParams>({
    resolver: zodResolver(BillFormResolver),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      attachments,
      classifications: {},
      isRecurrenceEnabled: false,
      contactId: null,
    },
  });

  useEffect(() => {
    form.setValue('attachments', attachments);
  }, [form, attachments]);

  return form;
}
