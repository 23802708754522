import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import create from 'zustand';
import type { ButtonProps } from '@mui/material';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { CloseIcon } from 'src/mui/_icons';

import { FocusIcon } from 'src/mui';

import { action, actions, title } from './ConfirmDialog.sx';

type Variant = 'success' | 'error' | 'primary' | 'warning';

type DialogProps = {
  icon?: ReactNode;
  title: string;
  content: ReactNode;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  variant?: Variant;
  confirmButtonProps?: ButtonProps;
  isConfirmLoading?: boolean;
  closable?: boolean;
  checkConfirm?: {
    text?: string;
  };
};

type StoreState = {
  dialogProps: undefined | DialogProps;
  openConfirmDialog: (dialogProps: DialogProps) => void;
  closeConfirmDialog: () => void;
};

const useStore = create<StoreState>((set) => ({
  dialogProps: undefined,
  openConfirmDialog: (dialogProps: DialogProps) => set({ dialogProps }),
  closeConfirmDialog: () => set({ dialogProps: undefined }),
}));

function dialogPropsSelector(state: StoreState) {
  return state.dialogProps;
}

function openConfirmDialogSelector(state: StoreState) {
  return state.openConfirmDialog;
}

function closeConfirmDialogSelector(state: StoreState) {
  return state.closeConfirmDialog;
}

export function useOpenConfirmDialog() {
  return useStore(openConfirmDialogSelector);
}

export function ConfirmDialogProvider() {
  const dialogProps = useStore(dialogPropsSelector);
  const closeConfirmDialog = useStore(closeConfirmDialogSelector);

  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    return () => {
      closeConfirmDialog();
    };
  }, [closeConfirmDialog]);

  useEffect(() => {
    if (dialogProps) {
      setConfirm(false);
    }
  }, [dialogProps]);

  if (!dialogProps) return null;

  const {
    content,
    cancelText,
    confirmText,
    variant,
    icon,
    checkConfirm,
    confirmButtonProps,
    isConfirmLoading = false,
    closable = true,
    onConfirm,
    ...rest
  } = dialogProps;

  function handleClose() {
    if (closable) {
      closeConfirmDialog();
    }
  }

  function handleConfirmChange(event: React.ChangeEvent<HTMLInputElement>) {
    setConfirm(event.target.checked);
  }

  function handleConfirm() {
    onConfirm && onConfirm();
    closeConfirmDialog();
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      fullWidth
      PaperProps={{
        sx: { maxWidth: '430px' },
      }}
      sx={{ zIndex: 1001 }}
    >
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        sx={{ px: 6, pt: 5 }}
      >
        {icon ? (
          <FocusIcon size="xl" color={variant}>
            {icon}
          </FocusIcon>
        ) : (
          <DialogTitle sx={{ ...title, mt: '2px', pl: 0 }}>
            {rest.title}
          </DialogTitle>
        )}

        {closable && (
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Stack>

      {icon && <DialogTitle sx={title}>{rest.title}</DialogTitle>}

      <DialogContent sx={{ pt: 4 }}>{content}</DialogContent>

      {checkConfirm && (
        <Box sx={actions}>
          <FormControlLabel
            control={
              <Checkbox checked={confirm} onChange={handleConfirmChange} />
            }
            label={<Typography variant="body1">{checkConfirm.text}</Typography>}
          />
        </Box>
      )}

      <DialogActions sx={actions}>
        {cancelText && (
          <Button
            variant="outlined"
            size="large"
            onClick={handleClose}
            sx={action}
            fullWidth
          >
            {cancelText}
          </Button>
        )}
        {confirmText && (
          <LoadingButton
            variant="contained"
            color={variant}
            size="large"
            onClick={handleConfirm}
            fullWidth
            loading={isConfirmLoading}
            sx={action}
            {...confirmButtonProps}
            disabled={checkConfirm && !confirm}
          >
            {confirmText}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
