import type { Components } from '@mui/material';
import { switchClasses } from '@mui/material';
import type { ThemeConfig } from 'src/theme';

export function createMuiSwitch({
  palette,
}: ThemeConfig): Components['MuiSwitch'] {
  const thumbColor =
    palette.mode === 'light' ? palette.common?.white : palette.common?.black;

  return {
    styleOverrides: {
      root: ({ ownerState }) => ({
        height: '20px',
        width: '40px',
        padding: '0',
        borderRadius: '12px',
        [`& .${switchClasses.colorPrimary}.${switchClasses.checked}`]: {
          color: palette.background?.default,
        },
        ...(ownerState.size === 'small' && {
          width: '36px',
        }),
      }),
      thumb: {
        color: thumbColor,
        height: '16px',
        width: '16px',
      },
      switchBase: {
        top: '2px',
        left: '2px',
        padding: '0 !important',
        [`&.${switchClasses.colorPrimary}.${switchClasses.checked}:not(.${switchClasses.disabled}) + .${switchClasses.track}`]:
          {
            opacity: 1,
          },
      },
    },
  };
}
