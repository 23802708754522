import { Space, Typography } from 'antd';
import type { BaseType } from 'antd/lib/typography/Base';

import type { BtgScopes } from 'src/libs/finbits/Bank/Accounts/types';

import AccountFeatureBadge from 'src/features/DEPRECATED_accounts/AccountsList/AccountCard/AccountFeatureBadge';

import styles from './BTGScopes.module.less';

type Props = BtgScopes & {
  type?: BaseType;
  title?: string;
};

export default function BTGScopes({
  type,
  scopeAccountsReadonly,
  scopeDda,
  scopePayments,
  title = 'Funcionalidades',
}: Props) {
  return (
    <Space direction="vertical" size={2}>
      <Typography.Text className={styles.btgBadgesTitle} type={type}>
        {title}
      </Typography.Text>
      <AccountFeatureBadge
        type={type}
        isAccountFeatureTrue={scopeAccountsReadonly}
      >
        Leitura automática de extrato
      </AccountFeatureBadge>
      <AccountFeatureBadge type={type} isAccountFeatureTrue={scopePayments}>
        Pagamento automático via PIX, Boleto e TED
      </AccountFeatureBadge>
      <AccountFeatureBadge type={type} isAccountFeatureTrue={scopeDda}>
        Débito direto autorizado
      </AccountFeatureBadge>
    </Space>
  );
}
