export function handleStyleAndClassChanges(mutations: any) {
  mutations.forEach(
    ({
      attributeName,
      target,
    }: {
      attributeName: 'class' | 'style';
      target: Element | null;
    }) => {
      const isBelvoWidgetOpen = !!document.getElementById('belvo');
      const hasOverflow = !!target
        ?.getAttribute('style')
        ?.includes('overflow: hidden');
      const hasAntScrollingEffect = target?.classList.contains(
        'ant-scrolling-effect'
      );

      if (
        attributeName === 'style' &&
        hasAntScrollingEffect &&
        !hasOverflow &&
        !isBelvoWidgetOpen
      ) {
        document.body.style.overflow = 'hidden';
      }

      if (
        attributeName === 'class' &&
        !hasAntScrollingEffect &&
        hasOverflow &&
        !isBelvoWidgetOpen
      ) {
        document.body.style.removeProperty('overflow');
      }
    }
  );
}
