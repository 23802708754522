import { Stack } from '@mui/material';
import { EditFiveIcon } from 'src/mui/_icons';

import { useOpenPortal } from 'src/ui';

import type { ApprovalRule } from 'src/libs/finbits/ApprovalRules/types';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import ActionButton from 'src/features/approval/approval-rules/ApprovalRuleCard/ActionButton';
import DeleteApprovalRuleButton from 'src/features/approval/approval-rules/DeleteApprovalRuleButton';
import UpdateApprovalRuleModal from 'src/features/approval/approval-rules/UpdateApprovalRuleModal';

type Props = {
  approvalRule: ApprovalRule;
};

export default function Actions({ approvalRule }: Props) {
  const openPortal = useOpenPortal();

  const { isEnabled: isUpdateEnabled } = useExternalFeatureFlag(
    Feature.APPROVAL_RULES_PAGE_V2
  );

  function handleOpenUpdateModal() {
    openPortal(UpdateApprovalRuleModal, {
      approvalRule,
    });
  }

  return (
    <Stack role="actions" direction="row" alignItems="center" gap={1}>
      {isUpdateEnabled && (
        <ActionButton
          permissions={{ resource: 'approvalsRules', action: 'update' }}
          icon={<EditFiveIcon />}
          label="Editar regra"
          onClick={handleOpenUpdateModal}
        />
      )}
      {!approvalRule.defaultRule && (
        <DeleteApprovalRuleButton approvalRule={approvalRule} />
      )}
    </Stack>
  );
}
