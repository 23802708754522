import type { QueryClient, UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import t from 'zod';
import throttle from 'lodash/throttle';

import type { ApiError, ApiErrorForm } from 'src/libs/finbits/client';
import {
  authenticatedAPI,
  decodeResponse,
  withEmptyArrayDefault,
} from 'src/libs/finbits/client';
import { invalidateAttachmentsQueries } from 'src/libs/finbits/Management/Attachments';
import { invalidateScheduledEntriesQueries } from 'src/libs/finbits/Management/ScheduledEntries';
import {
  invalidateEntriesQueries,
  invalidateFinancialStatementEntriesQueries,
} from 'src/libs/finbits/Management/FinancialStatements/Entries';
import type { FinancialEntry } from 'src/libs/finbits/Management/FinancialEntries/types';
import { FinancialEntryDecoder } from 'src/libs/finbits/Management/FinancialEntries/types';
import { invalidateConciliationSuggestionsQueries } from 'src/libs/finbits/Management/FinancialStatements/ConciliationSuggestions';
import { invalidateInboxItemsQueries } from 'src/libs/finbits/Management/NewInboxItems';
import { FIVE_SECONDS_IN_MS } from 'src/libs/finbits/Time';

import type { SubmitParams } from 'src/features/EntryForm/types';

import type {
  BillGetParams,
  BillPatchParams,
  BillPayable,
  BillPayableAssignment,
  BillPostParams,
  BillRecurrencePostParam,
  BillRecurrencesDeleteParams,
  BillsDeleteManyParams,
  BillsGetParams,
  BillsPendingMyApprovalGetParams,
  CreateFinancialFromBillParams,
  RecurringBill,
  RecurringBillPostParams,
} from './types';
import {
  BillApprovalStatus,
  BillPayableDecoder,
  BillStatus,
  RecurringBillDecoder,
} from './types';

type QueryOptions = UseQueryOptions<BillPayable[], ApiError>;

async function showBill({
  organizationId,
  companyId,
  billId,
  ...params
}: BillGetParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/bills/${billId}`,
    { params }
  );

  return decodeResponse<BillPayable>(response, BillPayableDecoder);
}

async function indexBills({
  organizationId,
  companyId,
  ...params
}: BillsGetParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/bills`,
    { params }
  );

  return decodeResponse<BillPayable[]>(response, t.array(BillPayableDecoder));
}

async function indexBillsPendingMyApproval({
  organizationId,
  companyId,
  ...params
}: BillsPendingMyApprovalGetParams) {
  const response = await authenticatedAPI.get(
    `/organizations/${organizationId}/companies/${companyId}/bills_pending_my_approval`,
    { params }
  );

  return decodeResponse<BillPayable[]>(response, t.array(BillPayableDecoder));
}

async function postBill({
  organizationId,
  companyId,
  ...params
}: BillPostParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/bills`,
    params
  );

  return decodeResponse<BillPayable>(response, BillPayableDecoder);
}

async function patchBill({
  billId,
  organizationId,
  companyId,
  ...params
}: BillPatchParams) {
  const response = await authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/bills/${billId}`,
    params
  );

  return decodeResponse<BillPayable>(response, BillPayableDecoder);
}

async function patchBillPendingApproval({
  billId,
  organizationId,
  companyId,
  ...params
}: BillPatchParams) {
  const response = await authenticatedAPI.patch(
    `/organizations/${organizationId}/companies/${companyId}/bills/${billId}/update_pending_approval`,
    params
  );

  return decodeResponse<BillPayable>(response, BillPayableDecoder);
}

async function deleteBill({
  organizationId,
  companyId,
  billId,
}: BillGetParams) {
  const response = await authenticatedAPI.delete(
    `/organizations/${organizationId}/companies/${companyId}/bills/${billId}`
  );

  return response.data;
}

async function postBillsDeleteMany({
  billIds,
  companyId,
  organizationId,
}: BillsDeleteManyParams) {
  return authenticatedAPI.post(
    `organizations/${organizationId}/companies/${companyId}/bills/delete_many`,
    {
      ids: billIds,
    }
  );
}

async function DEPRECATED_postRecurringBill({
  organizationId,
  companyId,
  ...params
}: RecurringBillPostParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/recurring_bills`,
    { ...params }
  );

  return decodeResponse<RecurringBill>(response, RecurringBillDecoder);
}

async function postRecurringBill({
  organizationId,
  companyId,
  ...params
}: BillRecurrencePostParam) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/recurring_bills/new`,
    params
  );

  return decodeResponse<RecurringBill>(response, RecurringBillDecoder);
}

async function postCreateFinancialFromBill({
  companyId,
  organizationId,
  billId,
  ...params
}: CreateFinancialFromBillParams) {
  const response = await authenticatedAPI.post(
    `organizations/${organizationId}/companies/${companyId}/bills/${billId}/financial_entries`,
    params
  );

  return decodeResponse<FinancialEntry>(response, FinancialEntryDecoder);
}

async function deleteBillRecurrences({
  organizationId,
  companyId,
  billId,
}: BillRecurrencesDeleteParams) {
  const response = await authenticatedAPI.delete(
    `/organizations/${organizationId}/companies/${companyId}/recurring_bills/${billId}`
  );

  return response.data;
}

export function billCacheKey({
  organizationId,
  companyId,
  billId,
}: BillGetParams) {
  return ['bills', { organizationId, companyId, billId }];
}

export function billsCacheKey(
  params: BillsGetParams | BillsPendingMyApprovalGetParams
) {
  return ['bills', params];
}

export function billsTotalAmount(bills: BillPayable[]) {
  const amount = bills.reduce(
    (accumulator, bill) => accumulator + bill.amount,
    0
  );
  return amount;
}

function invalidateBillsQueriesCall(queryClient: QueryClient) {
  queryClient.invalidateQueries(['bills']);
}

export const invalidateBillsQueries = throttle(
  invalidateBillsQueriesCall,
  FIVE_SECONDS_IN_MS
);

export function useGetBills(
  params: BillsGetParams,
  { enabled = true, ...queryConfig }: QueryOptions = {}
) {
  const { data, ...rest } = useQuery<BillPayable[], ApiError>({
    enabled: !!params.organizationId && !!params.companyId && enabled,
    queryKey: billsCacheKey({
      ...params,
      order: 'asc',
    }),
    queryFn: () => indexBills({ ...params, order: 'asc' }),
    ...queryConfig,
  });

  return { bills: withEmptyArrayDefault(data), ...rest };
}

export function useGetBill(params: BillGetParams) {
  const { data, ...rest } = useQuery<BillPayable, ApiError>({
    enabled: !!params.companyId && !!params.organizationId,
    queryKey: billCacheKey(params),
    queryFn: () => showBill(params),
  });

  return { bill: data, ...rest };
}

export function useCreateBill() {
  const queryClient = useQueryClient();
  const { mutate: createBill, ...rest } = useMutation<
    BillPayable,
    ApiError<ApiErrorForm>,
    BillPostParams
  >(postBill, {
    onSuccess: () => {
      invalidateBillsQueries(queryClient);
      invalidateFinancialStatementEntriesQueries(queryClient);
      invalidateConciliationSuggestionsQueries(queryClient);
      invalidateScheduledEntriesQueries(queryClient);
      invalidateAttachmentsQueries(queryClient);
      invalidateInboxItemsQueries(queryClient);
    },
  });
  return { createBill, ...rest };
}

export function DEPRECATED_useCreateRecurringBill() {
  const queryClient = useQueryClient();

  const { mutate: createRecurringBill, ...rest } = useMutation<
    RecurringBill,
    ApiError<ApiErrorForm>,
    RecurringBillPostParams
  >(DEPRECATED_postRecurringBill, {
    onSuccess: () => {
      invalidateBillsQueries(queryClient);
      invalidateFinancialStatementEntriesQueries(queryClient);
      invalidateConciliationSuggestionsQueries(queryClient);
      invalidateScheduledEntriesQueries(queryClient);
      invalidateAttachmentsQueries(queryClient);
      invalidateInboxItemsQueries(queryClient);
    },
  });

  return { createRecurringBill, ...rest };
}

export function useCreateRecurringBill() {
  const queryClient = useQueryClient();

  const { mutate: createRecurringBill, ...rest } = useMutation<
    RecurringBill,
    ApiError<ApiErrorForm>,
    BillRecurrencePostParam
  >(postRecurringBill, {
    onSuccess: () => {
      invalidateBillsQueries(queryClient);
      invalidateFinancialStatementEntriesQueries(queryClient);
      invalidateConciliationSuggestionsQueries(queryClient);
      invalidateScheduledEntriesQueries(queryClient);
      invalidateAttachmentsQueries(queryClient);
      invalidateInboxItemsQueries(queryClient);
    },
  });

  return { createRecurringBill, ...rest };
}

export function useUpdateBill() {
  const queryClient = useQueryClient();
  const { mutate: updateBill, ...rest } = useMutation<
    BillPayable,
    ApiError,
    BillPatchParams
  >(patchBill, {
    onSuccess: () => {
      invalidateBillsQueries(queryClient);
      invalidateFinancialStatementEntriesQueries(queryClient);
      invalidateConciliationSuggestionsQueries(queryClient);
      invalidateScheduledEntriesQueries(queryClient);
      invalidateAttachmentsQueries(queryClient);
      invalidateInboxItemsQueries(queryClient);
      invalidateEntriesQueries(queryClient);
    },
  });

  return { updateBill, ...rest };
}

export function useDeleteBill() {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation<string, ApiError, BillGetParams>(
    deleteBill,
    {
      onSuccess: () => {
        invalidateBillsQueries(queryClient);
        invalidateFinancialStatementEntriesQueries(queryClient);
        invalidateConciliationSuggestionsQueries(queryClient);
        invalidateScheduledEntriesQueries(queryClient);
        invalidateAttachmentsQueries(queryClient);
        invalidateEntriesQueries(queryClient);
      },
    }
  );

  return { deleteBill: mutate, ...rest };
}

export function useDeleteManyBills() {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation<
    unknown,
    ApiError,
    BillsDeleteManyParams
  >(postBillsDeleteMany, {
    onSuccess: () => {
      invalidateBillsQueries(queryClient);
      invalidateFinancialStatementEntriesQueries(queryClient);
      invalidateConciliationSuggestionsQueries(queryClient);
      invalidateScheduledEntriesQueries(queryClient);
      invalidateAttachmentsQueries(queryClient);
      invalidateEntriesQueries(queryClient);
    },
  });

  return { deleteManyBills: mutate, ...rest };
}

export function useBillsPendingMyApproval(
  params: BillsPendingMyApprovalGetParams,
  { enabled = true, ...queryConfig }: QueryOptions = {}
) {
  const { data, ...rest } = useQuery<BillPayable[], ApiError>({
    enabled: !!params.organizationId && !!params.companyId,
    queryKey: ['bills', 'my_approvals', params],
    queryFn: () => indexBillsPendingMyApproval(params),
    ...queryConfig,
  });

  return { bills: withEmptyArrayDefault(data), ...rest };
}

export function useCreateFinancialFromBill() {
  const queryClient = useQueryClient();

  const { mutate: createFinancialFromBill, ...rest } = useMutation<
    FinancialEntry,
    ApiError,
    CreateFinancialFromBillParams
  >(postCreateFinancialFromBill, {
    onSuccess: () => {
      invalidateBillsQueriesCall(queryClient);
      invalidateFinancialStatementEntriesQueries(queryClient);
      invalidateConciliationSuggestionsQueries(queryClient);
      invalidateEntriesQueries(queryClient);
      invalidateScheduledEntriesQueries(queryClient);
    },
  });

  return { createFinancialFromBill, ...rest };
}

export function getAssigneeStatus(
  assigneeId: string,
  assignments: BillPayableAssignment[],
  billStatus?: BillStatus
) {
  const assignment = assignments.find(
    (assignment) => assignment.assignee?.id === assigneeId
  );

  const isApproved = Boolean(assignment?.approvedAt);

  if (isApproved) {
    return BillApprovalStatus.APPROVED;
  }

  const isReproved = Boolean(assignment?.reprovedAt);

  if (isReproved) {
    return BillApprovalStatus.REPROVED;
  }

  const isPending = !isReproved && !isApproved;

  if (isPending && billStatus === BillStatus.WAITING_APPROVAL) {
    return BillApprovalStatus.PENDING;
  }
}

export function useDeleteBillRecurrences() {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation<
    string,
    ApiError,
    BillRecurrencesDeleteParams
  >(deleteBillRecurrences, {
    onSuccess: () => {
      invalidateBillsQueries(queryClient);
      invalidateFinancialStatementEntriesQueries(queryClient);
      invalidateConciliationSuggestionsQueries(queryClient);
      invalidateScheduledEntriesQueries(queryClient);
      invalidateAttachmentsQueries(queryClient);
    },
  });

  return { deleteBillRecurrences: mutate, ...rest };
}

export function buildSubmitParams({
  attachments,
  categoryId,
  contactId,
  paymentDetails,
  ...params
}: SubmitParams): BillPatchParams | BillPostParams | RecurringBillPostParams {
  return {
    ...params,
    contactId: contactId ?? null,
    categoryId: categoryId ?? null,
    attachmentsIds: attachments?.map((attachment) => attachment.id),
    paymentDetails: {
      ...paymentDetails,
      boleto: undefined,
      boletoId: paymentDetails?.boleto?.id,
      accountType: paymentDetails?.accountType ?? null,
      routingNumber: paymentDetails?.routingNumber ?? null,
      paymentMethod: paymentDetails?.paymentMethod ?? null,
    },
  };
}

export function useUpdateBillPendingApproval() {
  const queryClient = useQueryClient();
  const { mutate: updateBillPendingApproval, ...rest } = useMutation<
    BillPayable,
    ApiError,
    BillPatchParams
  >(patchBillPendingApproval, {
    onSuccess: () => {
      invalidateBillsQueries(queryClient);
      invalidateFinancialStatementEntriesQueries(queryClient);
      invalidateConciliationSuggestionsQueries(queryClient);
      invalidateScheduledEntriesQueries(queryClient);
      invalidateAttachmentsQueries(queryClient);
      invalidateInboxItemsQueries(queryClient);
    },
  });

  return { updateBillPendingApproval, ...rest };
}
