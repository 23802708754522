import { useRef, useState } from 'react';

import {
  Button,
  Divider,
  ListItemText,
  Menu,
  MenuItem,
  createFilterOptions,
} from '@mui/material';
import {
  CheckIcon,
  ChevronSelectorVerticalIcon,
  PlusIcon,
  SearchIcon,
} from 'src/mui/_icons';

import { useSwitchCompany } from 'src/router/useSwitchCompany';

import { Autocomplete, Typography } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import type { Company } from 'src/libs/finbits/Organization/Companies';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { formatCNPJ } from 'src/libs/finbits/Documents';
import { useUserCompanies } from 'src/libs/finbits/Organization/Users';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import CreateCompanyModal from 'src/features/companies/CreateCompanyModal';

import {
  companyText,
  menu,
  menuPaper,
  textField,
} from './SwitchCompanyAutocomplete.sx';

const filterOptions = createFilterOptions({
  ignoreCase: true,
  ignoreAccents: true,
  matchFrom: 'any',
  stringify: (company: Company) =>
    company.tradeName + formatCNPJ(company.document),
});

export default function SwitchCompanyAutocomplete() {
  const { companyId } = useCompanyParams();

  const { companies } = useUserCompanies();
  const switchCompany = useSwitchCompany();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openPortal = useOpenPortal();

  const inputRef = useRef<HTMLInputElement>(null);

  const company = companies.find((company) => company.id === companyId);

  const isVisibility = !!anchorEl;

  function handleSwitchCompany(companyId: string) {
    switchCompany(companyId);
    setAnchorEl(null);
  }

  function handleCreateCompany() {
    openPortal(CreateCompanyModal);
    setAnchorEl(null);
  }

  return (
    <>
      <MenuItem
        onClick={(event) => setAnchorEl(event.currentTarget)}
        tabIndex={0}
      >
        <Typography ellipsis sx={companyText}>
          {company?.tradeName}
        </Typography>
        <ChevronSelectorVerticalIcon />
      </MenuItem>

      <Menu
        open={isVisibility}
        anchorEl={anchorEl}
        disablePortal
        PaperProps={{
          sx: menuPaper,
        }}
        sx={menu}
        onClose={() => setAnchorEl(null)}
        onAnimationEnd={() => inputRef.current?.focus()}
      >
        <Autocomplete.Search
          open
          openOnFocus
          disableClearable
          disablePortal
          value={company}
          options={companies}
          filterOptions={filterOptions}
          forcePopupIcon={false}
          getOptionLabel={(company) => company.tradeName}
          onChange={(_, value) => handleSwitchCompany(value.id)}
          TextFieldProps={{
            inputRef,
            placeholder: 'Buscar',
            InputProps: {
              startAdornment: <SearchIcon />,
            },
          }}
          sx={textField}
          renderOption={(_, company, state) => (
            <>
              <ListItemText
                primary={company.tradeName}
                secondary={formatCNPJ(company.document)}
                aria-label={company.tradeName}
              />

              {state.selected && <CheckIcon />}
            </>
          )}
        />
        <WithAllowedPermission
          permissions={{
            action: 'create',
            resource: 'companies',
            scope: 'organization',
          }}
        >
          <>
            <Divider />
            <Button
              startIcon={<PlusIcon />}
              size="medium"
              sx={{ m: 2 }}
              onClick={handleCreateCompany}
            >
              Criar empresa
            </Button>
          </>
        </WithAllowedPermission>
      </Menu>
    </>
  );
}
