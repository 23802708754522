import cn from 'classnames';
import type { ToggleButtonProps } from '@mui/material';
import { Stack, ToggleButton, Tooltip } from '@mui/material';
import { CheckIcon } from 'src/mui/_icons';

import { Typography } from 'src/mui';

import styles from './ModeButton.module.scss';

type Props = {
  title: string;
  description: string;
  value: string;
  disabled?: boolean;
  tip?: string;
} & ToggleButtonProps;

export default function ModeButton({
  title,
  description,
  value,
  disabled = false,
  tip,
  ...rest
}: Props) {
  const buttonClass = cn(styles.button, { [styles.buttonDisabled]: disabled });

  const checkedIconClass = cn(styles.icon, {
    [styles.selectedIcon]: rest.selected,
  });

  return (
    <Tooltip title={disabled ? tip : undefined}>
      <span>
        <ToggleButton
          {...rest}
          disabled={disabled}
          value={value}
          className={buttonClass}
        >
          <Stack className={styles.wrapper}>
            <span>
              <Typography className={styles.buttonTitle}>{title}</Typography>
              <Typography className={styles.buttonDescription}>
                {description}
              </Typography>
            </span>

            <CheckIcon className={checkedIconClass} />
          </Stack>
        </ToggleButton>
      </span>
    </Tooltip>
  );
}
