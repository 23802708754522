import { useState } from 'react';

import {
  Box,
  Button,
  InputBase,
  Link,
  MenuItem,
  OutlinedInput,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker } from '@mui/x-date-pickers';

import { TextField } from 'src/mui';

export default function Components() {
  const [value, setValue] = useState('');
  function onChange(e: any) {
    setValue(e.target.value);
  }

  return (
    <>
      <Box sx={{ width: '100%', maxWidth: 500 }} p={4}>
        <Typography variant="body1">Body 1</Typography>
        <br />
        <Typography variant="body2">Body 2</Typography>
        <br />
        <Typography variant="subtitle1">Subtitle 1</Typography>
        <br />
        <Typography variant="subtitle2">Subtitle 2</Typography>
        <br />
        <Typography variant="overline">Overline</Typography>
        <br />
        <Typography variant="caption">Caption</Typography>
        <br />
        <Typography variant="h6">H6</Typography>
        <br />
        <Typography variant="h5">H5</Typography>
        <br />
        <Typography variant="h4">H4</Typography>
        <br />
        <Typography variant="h3">H3</Typography>
        <br />
        <Typography variant="h2">H2</Typography>
        <br />
        <Typography variant="h1">H1</Typography>
        <br />
        <Typography variant="button">Button</Typography>
      </Box>

      <Grid p={4} container spacing={2}>
        <Grid>
          <Link>Meu Link</Link>
        </Grid>
      </Grid>

      <Grid p={4} container spacing={2}>
        <Grid>
          <Button fullWidth variant="contained" size="small">
            Small
          </Button>
        </Grid>
        <Grid>
          <Button fullWidth variant="contained" size="medium">
            Medium
          </Button>
        </Grid>
        <Grid>
          <Button fullWidth variant="contained" size="large">
            Large
          </Button>
        </Grid>
        <Grid>
          <Button fullWidth variant="contained" disabled={true}>
            Disabled
          </Button>
        </Grid>
      </Grid>

      <Grid p={4} container spacing={2}>
        <Grid>
          <TextField
            label="E-mail"
            placeholder="olivia@untitledui.com"
            helperText="This is a hint text to help user."
          />
        </Grid>
        <Grid>
          <TextField
            label="E-mail"
            placeholder="olivia@untitledui.com"
            helperText="This is a hint text to help user."
            error={true}
          />
        </Grid>
      </Grid>

      <Grid p={4} container spacing={2}>
        <Grid>
          <TextField.Masked
            label="Celular (opcional)"
            placeholder="(11) 90000-0000"
            mask={['(99) 99999-9999']}
            onChange={onChange}
            value={value}
          />
        </Grid>
        <Grid>
          <TextField.Password
            label="Senha"
            placeholder="Senha"
            type="password"
          />
        </Grid>
      </Grid>

      <Grid p={4} container spacing={2}>
        <Grid>
          <TextField id="select" label="Age" value="20" select>
            <MenuItem value="10">Ten</MenuItem>
            <MenuItem value="20">Twenty</MenuItem>
          </TextField>
        </Grid>
        <Grid>
          <InputBase id="select" value="20" />
        </Grid>
        <Grid>
          <OutlinedInput />
        </Grid>
        <Grid>
          <DatePicker label="Date" />
        </Grid>
      </Grid>
    </>
  );
}
