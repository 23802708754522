import type { ReactNode } from 'react';
import { useState } from 'react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from 'src/mui/Typography';
import { ChevronDownIcon, ChevronUpIcon } from 'src/mui/_icons';
import { Chip } from '@mui/material';

import styles from './CollapseSection.module.scss';

type CollapseSectionProps = {
  open?: boolean;
  children: ReactNode;
  title: string;
  quantity?: string;
};

export default function CollapseSection({
  open = true,
  children,
  title,
  quantity,
}: CollapseSectionProps) {
  const [isOpen, setIsOpen] = useState(open);

  function handleChange() {
    setIsOpen((prev) => !prev);
  }

  return (
    <Box className={styles.colapseContainer}>
      <Box className={styles.switchRoot} onClick={handleChange}>
        {isOpen ? (
          <ChevronUpIcon className={styles.switchIcon} />
        ) : (
          <ChevronDownIcon className={styles.switchIcon} />
        )}
        {quantity && (
          <Chip
            variant="outlined"
            data-testid="quantity"
            component="span"
            label={quantity}
            size="small"
            className={styles.collapseQuantity}
          />
        )}
        <Typography className={styles.collapseTitle}>{title}</Typography>
        <span className={styles.collapseDivider} />
      </Box>
      <Box>
        <Collapse in={isOpen}>{children}</Collapse>
      </Box>
    </Box>
  );
}
