import { Box } from '@mui/material';

import { Typography } from 'src/mui';

import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import { useTheme } from 'src/features/theme-switcher';

import AmountBox from './AmountBox';

type Props = {
  text: string;
  amount?: number;
  amountType?: BalanceType;
  Icon?: JSX.Element;
  children?: JSX.Element;
};

export default function MoneyBox({
  text,
  amount = 0,
  amountType = BalanceType.CREDIT,
  Icon,
  children,
}: Props) {
  const [theme] = useTheme();

  return (
    <Box>
      <Typography
        fontSize={'12px'}
        lineHeight={'18px'}
        fontWeight={400}
        color={theme === 'dark' ? 'grey.600' : 'grey.500'}
      >
        {text}
      </Typography>
      {Boolean(children) ? (
        children
      ) : (
        <AmountBox amount={amount} amountType={amountType} Icon={Icon} />
      )}
    </Box>
  );
}
