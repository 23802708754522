import type {
  GridCellParams,
  GridColDef,
  GridFilterItem,
  GridFilterOperator,
  GridValidRowModel,
  GridValueSetterParams,
} from '@mui/x-data-grid-premium';
import {
  GridFilterInputValue,
  getGridStringOperators,
} from '@mui/x-data-grid-premium';

type Props<T extends GridValidRowModel> = {
  columnProps: GridColDef<T>;
};

const notEqualfilterOperator: GridFilterOperator = {
  label: 'diferente de',
  value: 'notEquals',
  getApplyFilterFn: (filterItem: GridFilterItem, _column: GridColDef) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return null;
    }

    return (params: GridCellParams<any, string>): boolean => {
      return params.value !== filterItem.value;
    };
  },
  InputComponent: GridFilterInputValue,
};

export function textColumn<T extends GridValidRowModel>({
  columnProps,
}: Props<T>): GridColDef<T> {
  return {
    filterOperators: [...getGridStringOperators(), notEqualfilterOperator],
    type: 'string',
    valueSetter: (params: GridValueSetterParams) => {
      return {
        ...params.row,
        [columnProps.field]: params.value,
        updated: { [columnProps.field]: params.value },
      };
    },
    ...columnProps,
  };
}
