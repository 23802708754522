import { common } from '@mui/material/colors';
import type { PaletteMode, PaletteOptions } from '@mui/material';

import {
  error,
  grey,
  info,
  primary,
  secondary,
  success,
  warning,
} from './colors';

// TODO: Remover esse arquivo quando tivermos as cores corretas em colorSchemes
export function createPalette(mode: PaletteMode): PaletteOptions {
  const backgroundColor = mode === 'light' ? common.white : grey[900];
  const secondaryBackgroundColor = mode === 'light' ? grey[100] : grey[900];
  const dividerColor = mode === 'light' ? grey[200] : grey[600];
  const selectedColor = mode === 'light' ? grey[100] : grey[800];
  const hoverColor = mode === 'light' ? grey[100] : grey[800];

  return {
    mode,
    primary,
    secondary,
    error,
    info,
    success,
    warning,
    divider: dividerColor,
    grey,
    action: {
      selected: selectedColor,
      hover: hoverColor,
    },
    common: { white: '#FFFFFF', black: '#000000' },
    background: {
      default: backgroundColor,
      paper: backgroundColor,
      secondary: secondaryBackgroundColor,
    },
    text: {
      primary: mode === 'light' ? grey[900] : grey[100],
      secondary: mode === 'light' ? grey[600] : grey[400],
      disabled: mode === 'light' ? grey[900] : grey[100],
    },
    highlight: {
      primary: mode === 'light' ? warning[100] : grey[800],
    },
  };
}
