import { useState } from 'react';

import { Box, Grid, Tab, Typography } from '@mui/material';
import { Form } from 'antd';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import { snackbar } from 'src/mui';

import { Title } from 'src/ui';

import {
  useCompany,
  useUpdateCompany,
} from 'src/libs/finbits/Organization/Companies/Companies';
import type { ErrorsObject } from 'src/libs/finbits/Form';
import { errorsToFormField } from 'src/libs/finbits/Form';
import type { CompanyUpdateParams } from 'src/libs/finbits/Organization/Companies/types';
import { useAllowedPermission } from 'src/libs/finbits/Permissions';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import CompanyForm from 'src/features/companies/CompanyForm';

import PublicApiTokens from './PublicApiTokens';
import styles from './Company.module.scss';

export enum CompanyTab {
  INFORMATIONS_AND_PREFERENCES = 'informations_and_preferences',
  API_TOKENS = 'api_tokens',
}

export default function CompanyWithApiTokenPage() {
  const [form] = Form.useForm();

  const { companyId, organizationId } = useCompanyParams();

  const { company } = useCompany({
    companyId,
    organizationId,
  });

  const { updateCompany, isLoading: isUpdating } = useUpdateCompany();

  const [selectedTab, setSelectedTab] = useState(
    CompanyTab.INFORMATIONS_AND_PREFERENCES
  );

  const canEditCompany = useAllowedPermission({
    action: 'update',
    resource: 'companies',
  });

  function handleOnFinish(params: CompanyUpdateParams) {
    updateCompany(
      { companyId, organizationId, params },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'Informações salvas com sucesso.',
          });
        },
        onError: (error) => {
          if (error.response?.status === 422) {
            const errors = error.response?.data?.errors as ErrorsObject;

            return form.setFields(errorsToFormField(errors));
          }

          snackbar({
            variant: 'error',
            message: 'Ops! Falha ao salvar as informações.',
          });
        },
      }
    );
  }

  return (
    <Box className={styles.container}>
      <Typography variant="subtitle1" fontWeight="bold">
        Empresa
      </Typography>

      <TabContext value={selectedTab}>
        <TabList
          textColor="secondary"
          indicatorColor="secondary"
          onChange={(_, value) => setSelectedTab(value)}
          className={styles.tabList}
        >
          <Tab
            label="Informações e Preferências"
            value={CompanyTab.INFORMATIONS_AND_PREFERENCES}
            className={styles.tabText}
          />
          <Tab
            label="Tokens da API Pública"
            value={CompanyTab.API_TOKENS}
            className={styles.tabText}
          />
        </TabList>

        <TabPanel
          value={CompanyTab.INFORMATIONS_AND_PREFERENCES}
          aria-label="Informações"
          className={styles.apiTokenTabPanel}
        >
          <Grid container justifyContent="space-between">
            <Grid item xs={12} lg={5.5}>
              <Title className={styles.subTitle} level={3}>
                Informações
              </Title>
              <CompanyForm
                form={form}
                isDisabled={!canEditCompany}
                isLoading={isUpdating}
                initialValues={company}
                onFinish={handleOnFinish}
              />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel
          value={CompanyTab.API_TOKENS}
          aria-label="Tokens da API Pública"
          className={styles.apiTokenTabPanel}
        >
          <PublicApiTokens />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
