import { Alert, AlertTitle } from '@mui/material';
import { AlertHexagonIcon, BuildingIcon } from 'src/mui/_icons';

import { Typography } from 'src/mui/';

import { parseQrCode } from 'src/libs/finbits/Bank/Pix';
import { toCurrency } from 'src/libs/finbits/Money';

import styles from './PixQrCodeAlert.module.scss';

type Props = {
  qrCode?: string | null;
  amountToMatch?: number;
};

export default function PixQrCodeAlert({ qrCode, amountToMatch }: Props) {
  if (!qrCode) return null;

  const { error, parsedQrCode } = parseQrCode(qrCode);

  const matchesAmount =
    Boolean(amountToMatch) && parsedQrCode?.transactionAmount === amountToMatch;

  if (error) {
    return (
      <Alert
        severity="warning"
        variant="filled"
        icon={<AlertHexagonIcon fontSize="small" />}
      >
        <AlertTitle>Codigo invalido</AlertTitle>
      </Alert>
    );
  }

  return (
    <Alert
      severity={matchesAmount ? 'info' : 'warning'}
      variant="filled"
      iconMapping={{
        info: <BuildingIcon fontSize="inherit" />,
        warning: <AlertHexagonIcon fontSize="inherit" />,
      }}
      classes={{ message: styles.message }}
    >
      <AlertTitle>Para {parsedQrCode?.merchantName}</AlertTitle>

      <Typography variant="body1">
        Cidade: <b>{parsedQrCode?.merchantCity}</b>
      </Typography>

      <Typography variant="body1">
        Chave Pix: <b>{parsedQrCode?.pixKey}</b>
      </Typography>

      <Typography variant="body1">
        Valor: <b>{toCurrency(parsedQrCode?.transactionAmount ?? 0)}</b>
      </Typography>

      {!matchesAmount && (
        <Alert icon={false} color="warning" className={styles.noMatchAlert}>
          <Typography variant="body2">
            O valor do código não corresponde ao valor preenchido. Se
            prosseguir, a inclusão do pagamento será feito com valor do
            formulário.
          </Typography>
        </Alert>
      )}
    </Alert>
  );
}
