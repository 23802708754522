import create from 'zustand';

import { useMutationObserver } from 'src/libs/finbits/hooks';
import { handleStyleAndClassChanges } from 'src/libs/belvo/BelvoWidget/observers';

import ConnectWidget from './ConnectWidget';
import type { WidgetProps } from './types';

type StoreState = {
  visible: boolean;
  props?: WidgetProps;
  openConnectWidget: (props: WidgetProps) => void;
  closeConnectWidget: () => void;
};

const useStore = create<StoreState>((set) => ({
  visible: false,
  props: undefined,
  openConnectWidget: (props) => set({ visible: true, props }),
  closeConnectWidget: () => set({ visible: false, props: undefined }),
}));

function visibleSelector(state: StoreState) {
  return state.visible;
}

function propsSelector(state: StoreState) {
  return state.props;
}

function closeConnectWidgetSelector(state: StoreState) {
  return state.closeConnectWidget;
}

function openConnectWidgetSelector(state: StoreState) {
  return state.openConnectWidget;
}

export function useOpenBelvoWidget() {
  return useStore(openConnectWidgetSelector);
}

export function BelvoWidget() {
  const visible = useStore(visibleSelector);
  const props = useStore(propsSelector);
  const closeConnectWidget = useStore(closeConnectWidgetSelector);

  useMutationObserver({
    target: document.body,
    options: { attributes: true, attributeFilter: ['style', 'class'] },
    callback: handleStyleAndClassChanges,
  });

  return (
    <>
      {visible && props && (
        <ConnectWidget {...props} onClose={closeConnectWidget} />
      )}
    </>
  );
}
