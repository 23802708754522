import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import type { NewInboxItem } from 'src/libs/finbits/Management/NewInboxItems/types';

type Props = {
  inboxItem: NewInboxItem;
};

export default function WhatsAppDetails({ inboxItem }: Props) {
  return (
    <Grid sx={{ color: 'text.secondary' }}>
      <Typography sx={{ mb: 4 }}>{inboxItem.description}</Typography>
      <Typography>
        Caso a sua empresa opere com múltiplos CNPJs, recomendamos verificar as
        informações do anexo e redirecionar para a empresa responsável, caso
        necessário.
      </Typography>
    </Grid>
  );
}
