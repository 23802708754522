import { TrashIcon } from 'src/mui/_icons';

import { Typography, snackbar } from 'src/mui';

import { useOpenConfirmDialog } from 'src/ui';

import { useDeleteApprovalRule } from 'src/libs/finbits/ApprovalRules/ApprovalRules';
import type { ApprovalRule } from 'src/libs/finbits/ApprovalRules/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import ActionButton from 'src/features/approval/approval-rules/ApprovalRuleCard/ActionButton';

type Props = {
  approvalRule: ApprovalRule;
};

export default function DeleteApprovalRuleButton({ approvalRule }: Props) {
  const openConfirmDialog = useOpenConfirmDialog();
  const { companyId, organizationId } = useCompanyParams();
  const { deleteApprovalRule } = useDeleteApprovalRule();

  function handleConfirmDialog() {
    openConfirmDialog({
      variant: 'error',
      icon: <TrashIcon />,
      title: 'Confirmar exclusão da regra?',
      content: (
        <>
          <Typography color="error.500" fontWeight={600}>
            Muito cuidado! Esta ação não pode ser desfeita.
          </Typography>
          <Typography color="grey.600" paddingTop={4} textAlign="justify">
            Ao confirmar, lançamentos existentes em situação "a pagar" e
            "reprovados" cujos aprovadores foram definidos pela regra{' '}
            <strong>{approvalRule.description}</strong> também serão afetados.
          </Typography>
        </>
      ),
      checkConfirm: { text: 'Estou ciente dos riscos e quero confirmar.' },
      confirmText: 'Excluir',
      cancelText: 'Voltar',
      onConfirm: handleDeleteApprovalRule,
    });

    return;
  }

  function handleDeleteApprovalRule() {
    deleteApprovalRule(
      {
        organizationId,
        companyId,
        approvalRuleId: approvalRule.id,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            title: 'Regra excluída com sucesso',
            message:
              'Lembre-se de verificar os aprovadores de lançamentos existentes em situação ”a pagar” e "reprovados” em que a regra excluída havia sido aplicada.',
          });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao excluir a regra de aprovação.',
          });
        },
      }
    );
  }

  return (
    <ActionButton
      permissions={{ resource: 'approvalsRules', action: 'delete' }}
      icon={<TrashIcon />}
      label="Excluir regra"
      onClick={handleConfirmDialog}
    />
  );
}
