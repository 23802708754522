import { useEffect } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type { TransitionProps } from '@mui/material/transitions';
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Slide,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { CloseIcon, TrashIcon } from 'src/mui/_icons';
import type { PortalProps } from 'src/ui/PortalProvider';

import { FocusIcon, snackbar } from 'src/mui';

import type {
  Account,
  DeleteAccountForm,
  SubmitParamsType,
} from 'src/libs/finbits/Bank/Accounts/types';
import { useAccountDelete } from 'src/libs/finbits/Bank/Accounts';
import { useCompanyUsers } from 'src/libs/finbits/Organization/Users';
import { UserStatus } from 'src/libs/finbits/Organization/Users/types';
import { DeleteAccountResolver } from 'src/libs/finbits/Bank/Accounts/types';
import { INVALID_EMAIL_MESSAGE } from 'src/libs/finbits/resolverValidations';

import {
  cancel,
  chip,
  dialog,
  dialogTitle,
  iconButton,
} from './DeleteAccountModal.sx';

type Props = {
  organizationId: string;
  companyId: string;
  account: Account;
} & PortalProps;

export default function DeleteAccountModal({
  organizationId,
  companyId,
  onClose,
  account,
  open = true,
  onExit,
}: Props) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setValue,
    trigger,
  } = useForm<DeleteAccountForm>({
    defaultValues: {
      accountName: null,
      accountNumber: null,
      isCashAccount: false,
      confirmation: '',
      backupEnabled: false,
      sendTo: [],
    },
    resolver: zodResolver(DeleteAccountResolver),
  });

  const isCashAccount = account?.type === 'cash_account';

  const backupEnabled = watch('backupEnabled');

  useEffect(() => {
    if (!account) return;

    setValue('isCashAccount', isCashAccount);
    setValue('accountName', account.name);
    setValue('accountNumber', account.accountNumber);
  }, [account, isCashAccount, setValue]);

  const { users, isLoading: isUsersLoading } = useCompanyUsers({
    companyId,
    organizationId,
    status: UserStatus.ACTIVE,
  });

  const { deleteAccount, isLoading } = useAccountDelete();

  function onSubmit({ backupEnabled, sendTo }: SubmitParamsType) {
    deleteAccount(
      {
        organizationId,
        companyId,
        accountId: account.id,
        backupEnabled,
        sendTo,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'O processo de deleção da conta foi iniciado!',
          });

          onClose();
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao deletar conta!',
          });
        },
      }
    );
  }

  function handleChange(value: string[]) {
    setValue('sendTo', value);
    trigger('sendTo');
  }

  function handleBlur(e: React.FocusEvent<HTMLInputElement, Element>) {
    const value = e.target.value;

    if (!value) return;

    handleChange([...getValues('sendTo'), value]);
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
      onClose={onClose}
      aria-labelledby="delete-account-dialog-title"
      aria-describedby="delete-account-dialog-description"
      PaperProps={{
        sx: dialog,
      }}
    >
      <Stack sx={{ p: 0, pt: 6, pl: 6 }}>
        <FocusIcon color="error" size="lg">
          <TrashIcon />
        </FocusIcon>

        <DialogTitle
          id="delete-account-dialog-title"
          variant="subtitle1"
          sx={dialogTitle}
        >
          Excluir conta
        </DialogTitle>
        <IconButton
          aria-label="Fechar modal de deleção de conta"
          onClick={onClose}
          sx={iconButton}
        >
          <CloseIcon />
        </IconButton>
      </Stack>

      <DialogContent sx={{ p: 6, pt: 1 }}>
        <Typography
          id="delete-account-dialog-description"
          data-testid="delete-account-dialog-description"
          color="text.secondary"
        >
          A exclusão de <strong>{account?.name}</strong> será permanente.
          Recomendamos a realização de uma cópia de segurança. Isso pode levar
          alguns minutos.
        </Typography>

        <Alert variant="filled" severity="error" sx={{ mt: 4, mb: 8 }}>
          <AlertTitle sx={{ mt: 0 }}>
            A exclusão da conta também deleta os seguintes itens relacionados:
          </AlertTitle>
          <List dense sx={{ my: 0, p: 0 }}>
            {[
              'Lançamentos programados',
              'Lançamentos realizados',
              'Anexos',
            ].map((item: string, index: number) => (
              <ListItem key={index} sx={{ py: 0, listStyleType: 'disc' }}>
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: 14,
                  }}
                  primary={item}
                  sx={{ display: 'list-item' }}
                />
              </ListItem>
            ))}
          </List>
        </Alert>

        <Typography variant="h6" component="h3" fontSize={16} fontWeight={600}>
          Deseja fazer uma cópia de segurança?
        </Typography>

        <Typography color="text.secondary" sx={{ mb: 1 }}>
          Um link para o download de segurança será enviado para o email dos
          gestores da conta e aos emails abaixo com o conteúdo relacionado à
          conta desde a sua criação.
        </Typography>

        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ mb: 5 }}>
            <Controller
              name="backupEnabled"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  id="has-backup-enabled"
                  control={<Checkbox size="small" />}
                  label="Fazer uma cópia de segurança"
                />
              )}
            />

            <Collapse in={backupEnabled} sx={{ mb: 9 }}>
              <Controller
                name="sendTo"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_event, value) => handleChange(value)}
                    value={getValues('sendTo')}
                    multiple
                    loading={isUsersLoading}
                    id="tags-outlined"
                    filterSelectedOptions
                    freeSolo
                    clearOnBlur
                    options={users.map((user) => user.email)}
                    renderTags={(options: readonly string[], getTagProps) =>
                      options.map((option: string, index: number) => (
                        <Chip
                          data-testid="email-tag-chip"
                          color={errors.sendTo?.[index] ? 'error' : 'default'}
                          variant="outlined"
                          label={option}
                          size="small"
                          sx={{ borderRadius: '6px' }}
                          deleteIcon={<CloseIcon />}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        data-testid="send-to-text-field"
                        error={!!errors.sendTo}
                        helperText={!!errors.sendTo && INVALID_EMAIL_MESSAGE}
                        onBlur={handleBlur}
                      />
                    )}
                  />
                )}
              />

              <Typography color="text.secondary" sx={{ mt: 1 }}>
                Selecione os contatos ou digite os emails que deseja enviar a
                cópia de segurança.
              </Typography>
            </Collapse>
          </Box>

          <Controller
            name="confirmation"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="confirmation"
                error={!!errors.confirmation}
                helperText={errors.confirmation?.message}
                label={
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Typography fontWeight={600}>
                      Para confirmar a exclusão da conta, digite:
                    </Typography>
                    <Chip
                      sx={chip}
                      label={
                        isCashAccount ? account?.name : account?.accountNumber
                      }
                    />
                  </Stack>
                }
                variant="outlined"
              />
            )}
          />

          <DialogActions sx={{ p: 0, pt: 8 }}>
            <Button variant="outlined" onClick={onClose} sx={cancel}>
              Cancelar
            </Button>
            <LoadingButton
              color="error"
              loading={isLoading}
              sx={{ px: 4, color: 'common.white' }}
              variant="contained"
              type="submit"
            >
              Excluir Conta
            </LoadingButton>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
