import { useMemo } from 'react';

import { Stack, Typography } from '@mui/material';
import CountUp from 'react-countup';
import type { GridRowModel } from '@mui/x-data-grid-premium';
import { ArrowCircleDownIcon, ArrowCircleUpIcon } from 'src/mui/_icons';

import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';

type Props = {
  selecteds: GridRowModel<FinancialStatementEntry>[];
};

export default function AmountTotal({ selecteds }: Props) {
  const selectionInfo = useMemo(() => {
    const { debit, credit } = selecteds.reduce<{
      debit: number;
      credit: number;
    }>(
      (acc, row) => {
        if (row.amountType === BalanceType.DEBIT) {
          acc.debit = acc.debit + row.amount;
        } else {
          acc.credit = acc.credit + row.amount;
        }

        return acc;
      },
      { debit: 0, credit: 0 }
    );

    return { count: selecteds.length, credit, debit };
  }, [selecteds]);

  return (
    <Stack direction="row" gap="16px" justifyContent="flex-end" flexGrow="1">
      <Stack direction="row" alignItems="center" gap="6px">
        <Typography>
          Entradas:{' '}
          <b>
            <CountUp
              prefix="+ "
              end={selectionInfo.credit / 100}
              decimals={2}
              decimal=","
              separator="."
              duration={0.5}
              preserveValue
              useEasing={false}
              delay={0}
            />
          </b>
        </Typography>
        <ArrowCircleUpIcon />
      </Stack>
      <Stack direction="row" alignItems="center" gap="6px">
        <Typography>
          Saídas:{' '}
          <b>
            <CountUp
              prefix="- "
              end={selectionInfo.debit / 100}
              decimals={2}
              decimal=","
              separator="."
              duration={0.5}
              preserveValue
              useEasing={false}
              delay={0}
            />
          </b>
        </Typography>
        <ArrowCircleDownIcon />
      </Stack>
    </Stack>
  );
}
