import { Divider, Typography } from '@mui/material';

import QuickFilterChip from './QuickFilterChip';
import styles from './QuickFilters.module.scss';
import type { QuickFilter } from './types';

type QuickFilterProps = {
  filters: QuickFilter[];
};

export default function QuickFilters({ filters }: QuickFilterProps) {
  if (filters.length === 0) return null;

  return (
    <>
      <div className={styles.quickFiltersRoot}>
        <Typography color="text.secondary" fontWeight={500}>
          Filtros rápidos:
        </Typography>

        <div className={styles.chipsContainer}>
          {filters.map((filter, index) => (
            <QuickFilterChip key={index} filter={filter} />
          ))}
        </div>
      </div>

      <Divider variant="fullWidth" />
    </>
  );
}
