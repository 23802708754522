import { Divider, Menu, MenuList } from '@mui/material';
import type { GridApi, GridValidRowModel } from '@mui/x-data-grid-premium';
import { dataGridGetLocaleText } from 'src/theme/locale';
import { ArrowNarrowDownIcon, ArrowUpIcon, EyeOffIcon } from 'src/mui/_icons';
import type {
  ContextMenuInfo,
  CustomContextMenuOptions,
} from 'src/mui/_scss/Table/types';

import type { PortalProps } from 'src/ui';

import { ContextItem } from './ContextItem';
import { FilterCellItems } from './FilterCellItems/FilterCellItems';
import styles from './GridContextMenu.module.scss';

type ContextMenuProps<T extends GridValidRowModel> = {
  info: ContextMenuInfo<T>;
  api: GridApi;
  CustomOptions?: CustomContextMenuOptions<T>;
} & PortalProps;

export function GridContextMenu<T extends GridValidRowModel>({
  api,
  info,
  onClose,
  open = true,
  onExit,
  CustomOptions,
}: ContextMenuProps<T>) {
  function sort(direction: 'asc' | 'desc') {
    return function () {
      api.sortColumn(info.colDef, direction, true);
    };
  }

  function hideColumn() {
    api.setColumnVisibility(info.colDef.field, false);
  }

  function closeMenu() {
    onClose();
  }

  return (
    <Menu
      transitionDuration={500}
      TransitionProps={{ onExited: onExit }}
      anchorReference="anchorPosition"
      open={open}
      onClose={closeMenu}
      anchorPosition={info.mousePosition}
      onContextMenu={(e) => {
        e.preventDefault();
        closeMenu();
      }}
      className={styles.menu}
    >
      {CustomOptions && (
        <CustomOptions onClose={onClose} info={info} api={api} />
      )}

      <Divider variant="fullWidth" />

      <FilterCellItems onClose={onClose} info={info} api={api} />

      <MenuList>
        <ContextItem
          key="hide"
          onClose={onClose}
          label={`Ocultar ${info.colDef?.headerName}`}
          icon={<EyeOffIcon />}
          onClick={hideColumn}
        />
      </MenuList>

      <Divider variant="fullWidth" />

      <MenuList>
        <ContextItem
          onClose={onClose}
          label={dataGridGetLocaleText('columnMenuSortAsc')}
          icon={<ArrowUpIcon />}
          onClick={sort('asc')}
        />

        <ContextItem
          onClose={onClose}
          label={dataGridGetLocaleText('columnMenuSortDesc')}
          icon={<ArrowNarrowDownIcon />}
          onClick={sort('desc')}
        />
      </MenuList>
    </Menu>
  );
}
